// import HttpService from "../services/common-services/HttpService";
import * as HttpService from "../services/common-services/HttpService";

export function getNearestDoctors(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Doctors/Nearest",
    QueryData,
    "doctorApi.getNearestDoctors"
  );
}

export function getNearestDoctorsCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Doctors/Nearest/Count",
    QueryData,
    "doctorApi.getNearestDoctorsCount"
  );
}

export function getDoctor(doctorId) {
  return HttpService.get("api/v1/Doctors/" + doctorId);
}

export function updateDoctor(request) {
  return HttpService.put("api/v1/Doctors/" + request.id, request);
}

export function updateDoctorApproval(request) {
  return HttpService.put(
    "api/v1/Doctors/" + request.id + "/Approvals",
    request
  );
}

export function updateDoctorActive(request) {
  return HttpService.put("api/v1/Doctors/" + request.id + "/Active", request);
}
export function deleteDoctor(id) {
  return HttpService.del("api/v1/Doctors/" + id);
}

export function RegisterDoctor(request) {
  return HttpService.post("api/v1/Register/Doctor", request);
}

export function createDoctor(request) {
  return HttpService.post("api/v1/Doctors", request);
}

export function searchDoctors(docSearchParams) {
  return HttpService.getByQueryWithoutAuth(
    "api/v1/Doctors/search",
    docSearchParams,
    "Default"
  );
}
