<template>
  <div>
    <label>{{ label }}</label>
    <md-checkbox
      v-model="localValue"
      :required="required"
      :name="name"
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      @change="$emit('onChange', option)"
      :disabled="isDisabled"
      >{{ option.text }}</md-checkbox
    >
    <div align="center" class="md-error" style="color: #ff1744">
      {{ error }}
    </div>
  </div>
</template>
<script>
export default {
  name: "CheckBoxInput",
  props: {
    label: String,
    name: String,
    options: Array,
    value: [Array, Number, String],
    error: String,
    required: Boolean,
    autofocus: Boolean,
    onChange: Function,
    isDisabled: Boolean,
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
