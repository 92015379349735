import * as api from "../../../api/deviceApi";
import * as types from "./device.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  searchDevicesWithQueryData({ commit }, request) {
    return api.searchDevices(request).then((res) => {
      commit(types.SEARCH_DEVICES, res);
      return res;
    });
  },
  getDeviceById({ commit }, request) {
    return api.getDeviceById(request).then((res) => {
      commit(types.GET_DEVICE_BYID, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  registerDevice({ commit }, request) {
    return api.RegisterDevice(request);
  },
  updateDevice({ commit }, request) {
    return api.UpdateDevice(request.id, request).then((res) => {
      commit(types.UPDATE_DEVICE, res);
      return res;
    });
  },
  deleteDevice({ commit }, request) {
    return api.DeleteDevice(request).then((res) => {
      commit(types.DELETE_DEVICE, res);
      return res;
    });
  },
};
