import * as types from "./screeningeval.action.types";

export default {
  [types.GET_LOOKUP_LIST]: (state, res) => {
    state.spherePowerList = res[0].value;
    state.cylindricalPowerList = res[1].value;
    state.axisPowerList = res[2].value;
    state.additionalPowerList = res[3].value;
    state.distancesList = res[4].value;
  },
  [types.GET_REFERRAL_DOC]: (state, res) => {
    state.ReferralDoctors = res;
  },
};
