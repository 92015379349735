export default {
  SuperAdmin: "superadmin:fullaccess",
  ScreeningRead: "Screenings:read",
  ScreeningWrite: "Screenings:write",
  ScreeningsonholdRead: "Screeningsonhold:read	",
  ScreeningsonholdWrite: "Screeningsonhold:write	",
  ReportsRead: "Reports:read",
  ReportsWrite: "Reports:write",
  EyeExamsRead: "EyeExams:read",
  ReferralsRead: "Referrals:read",
  ReferralsWrite: "Referrals:write	",
  GlobeactivityRead: "Globeactivity:read",
  GlobeactivityWrite: "Globeactivity:write",
  IsAdmin: "Individuals:admin",
  IsDoctor: "Individuals:doctor",
  IsPatient: "Individuals:patient",
  IsSuperAdmin: "Individuals:SuperAdmin",
  IsTechnician: "Individuals:technician",
  IsReader: "Individuals:reader",
  PackagesRead: "packages:read",
  PackagesWrite: "packages:write",
  UserRead: "user:read",
  MessagesRead: "Messages:read",
  ClientRead: "client:read",
  DoctorRead: "doctor:read",
  DeviceRead: "device:read",
  DeviceWrite: "device:write",
  HistoryRead: "history:read",
  ReferralDoctorsRead: "referraldoctor:read",
  ScreeningDelete: "screening:delete",
  Support: "support:write",
  ScreeningevaluationSettings: "screeningevalsetting:write",
  ReaderRequestRelease: "requestrelease:read",
};
