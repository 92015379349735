import * as api from "../../../api/reportsApi";
import * as types from "./reports.action.types";

export default {
  getReports({ commit }, QueryData) {
    return api.getReports(QueryData).then((res) => {
      commit(types.GET_REPORTS, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getReportsCount({ commit }, QueryData) {
    return api.getReportsCount(QueryData).then((res) => {
      return res;
    });
  },
  getSortList({ commit }) {
    return api.getReportsSortList().then((res) => {
      commit(types.GET_REPORTS_SORT_LIST, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  patientmsgnotification({ commit }, postdata) {
    return api.patientmsgnotification(postdata);
  },
  // eslint-disable-next-line no-unused-vars
  UpdateReportStatusPut({ commit }, screeningId) {
    return api.UpdateReportStatusPut(screeningId);
  },
  // eslint-disable-next-line no-unused-vars
  patientemailnotification({ commit }, postData) {
    return api.patientemailnotification(postData);
  },
  // eslint-disable-next-line no-unused-vars
  reportgeneration({ commit }, postData) {
    return api.reportgeneration(postData);
  },
  // eslint-disable-next-line no-unused-vars
  adminreportmsgnotification({ commit }, screeningId) {
    return api.adminreportmsgnotification(screeningId);
  },
  // eslint-disable-next-line no-unused-vars
  adminreportstatusnotification({ commit }, screeningId) {
    return api.adminreportstatusnotification(screeningId);
  },
  // eslint-disable-next-line no-unused-vars
  updatereport({ commit }, postData) {
    return api.updatereport(postData);
  },
  // eslint-disable-next-line no-unused-vars
  reportpdjgeneration({ commit }, postdata) {
    return api.reportpdjgeneration(postdata);
  },
};
