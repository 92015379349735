import actions from "./messages.actions";
import mutations from "./messages.mutations";
import state from "./messages.state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
