import LoggerService from "../../services/common-services/LoggerService";
import ConfirmationService from "../../services/control-services/ConfirmationService";
import SnackbarService from "../../services/control-services/SnackbarService";
//import SnackbarSuccessService from "../../services/control-services/SnackbarSuccessService";
//import SnackbarWarningService from "../../services/control-services/SnackbarWarningService";
import AppService from "../../services/module-services/AppService";
import FileUploadHandler from "../../handlers/common-handlers/FileUploadHandler";

const AppHandler = {
  mixins: [
    AppService,
    SnackbarService,
    ConfirmationService,
    // SnackbarSuccessService,
    // SnackbarWarningService,
    LoggerService,
  ],
  components: {
    FileUploadHandler,
  },
  methods: {
    handleShowImagePreview(image) {
      this.previewImage.showImagePreview = true;
      this.previewImage.image = image;
    },
    handleCloseImagePreview() {
      this.previewImage.showImagePreview = false;
      this.previewImage.image = undefined;
    },
    handleImageError(e) {
      e.target.src = require("@/assets/img/imageNA.jpg");
    },
  },
  provide() {
    return {
      checkPermission: this.checkPermission,
      isSuperAdmin: this.isSuperAdmin,
      getSelectedOrgId: this.getSelectedOrgId,
      handleCloseImagePreview: this.handleCloseImagePreview,
      handleShowImagePreview: this.handleShowImagePreview,
      handleImageError: this.handleImageError,
      msgnocount: this.msgnocount,
    };
  },
  template: `<FileUploadHandler><slot></slot></FileUploadHandler>`,
};

export default AppHandler;

export const withAppHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<AppHandler><WrappedComponent v-bind="$props"></WrappedComponent></AppHandler>`,
    components: { AppHandler, WrappedComponent },
  };
};
