import * as HttpService from "../services/common-services/HttpService";

export function getRoleList() {
  return HttpService.getWithoutAuth("api/Public/Roles", "IDP");
}

export function getStateList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/states");
}

export function getAddressTypeList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Address");
}

export function getCountryCodeList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/CountryCodes");
}

export function getMonthList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Months");
}

export function getSpecializationList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Specializations");
}

export function getSubspecializationList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Subspecializations");
}

export function getSpecialitiesList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Specialities");
}

export function getEyeOrientationList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/EyeOrientation");
}

export function getScreeningTypeList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/ScreeningType");
}

export function getLanguagesList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Languages");
}

export function getInsuranceList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Insurance");
}

export function getQualificationList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Qualification");
}

export function getRegionList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Regions");
}

export function getDeviceStatus() {
  return HttpService.getWithoutAuth("api/v1/Lookup/DeviceStatus");
}

export function getBusinessType() {
  return HttpService.getWithoutAuth("api/v1/Lookup/BusinessType");
}

export function getOpenLookupList(lookupMasterName) {
  return HttpService.get("api/v1/OpenLookup/" + lookupMasterName);
}

export function getOrganisationList() {
  return HttpService.get("api/Organisations/list", "IDP");
}
export function getSpherePowerList() {
  return HttpService.get("api/v1/Lookup/SpherePower");
}

export function getCylindricalPowerList() {
  return HttpService.get("api/v1/Lookup/CylinderPower");
}

export function getAxisPowerList() {
  return HttpService.get("api/v1/Lookup/Axis");
}

export function getAdditionalPowerList() {
  return HttpService.get("api/v1/Lookup/AdditionalPower");
}

export function getDistancesPowerList() {
  return HttpService.get("api/v1/Lookup/Distance");
}

export function getMessageType() {
  return HttpService.get("api/v1/Lookup/MessageType");
}

export function GetLookupValuesByType(type) {
  return HttpService.get("api/v1/Lookup/" + type);
}

export function GetLookupValueByID(id) {
  return HttpService.get("api/v1/Lookup/GetLookupById/" + id);
}

export function GetAllMasterType() {
  return HttpService.get("api/v1/Lookup/LookupMasters");
}

export function DeleteLookupValue(lookupId) {
  return HttpService.del("api/v1/Lookup/" + lookupId);
}

export function UpdateLookupValue(id, request) {
  return HttpService.put("api/v1/Lookup/" + id, request);
}

export function AddLookupValue(request) {
  return HttpService.post("api/v1/Lookup", request);
}
