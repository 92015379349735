<template>
  <div>
    <md-checkbox :name="name" v-model="localValue">{{ label }}</md-checkbox>
    <span class="md-error" style="margin-bottom: 10px; color: red">{{
      error
    }}</span>
  </div>
</template>
<script>
export default {
  name: "CheckBoxInput",
  props: {
    name: String,
    label: String,
    value: Boolean,
    error: String,
    autofocus: Boolean,
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
