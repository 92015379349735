import MasterService from "../../../services/common-services/MasterService";
import ManagePatientService from "../../../services/module-services/patient/ManagePatientService";
import NavigationBlocker from "../../../components/NavigationBlocker";

const ManagePatientHandler = {
  mixins: [ManagePatientService, MasterService],
  inject: ["navigationBlocker"],
  created() {
    //this.loadCorrectiveLenseOptions();
    let masterList = [
      "stateList",
      "countryCodeList",
      "roleList",
      "surgeryList",
      "diabetesList",
      "cancerList",
    ];
    this.navigationBlocker.val = true;
    if (!location.pathname.includes("/register/"))
      masterList = masterList.concat(["organisationList"]);
    this.loadMasterList(masterList);
    if (location.pathname.includes("/register/")) {
      this.managePatient.page = "Register";
      this.managePatient.showConfidentialConfirmation = true;
    } else this.managePatient.page = "manage";
    if (this.$route.params.patientId) {
      this.patientForm.postData.id = this.$route.params.patientId;
      this.managePatient.id = this.$route.params.patientId;
      this.loadPatient();
      // console.log("patient info call");
    }
    this.loadSelectedRole();
  },
  watch: {
    "master.roleList": function () {
      this.loadSelectedRole();
    },
  },
  methods: {
    loadSelectedRole() {
      let userType;
      if (
        this.$router.currentRoute.meta &&
        this.$router.currentRoute.meta.userType
      )
        userType = this.$router.currentRoute.meta.userType;
      else if (this.$route.params.userType)
        userType = this.$route.params.userType;
      if (userType && this.master.roleList.length > 0) {
        let selecetedRole = this.master.roleList.find(
          (x) => String(x.name).toLowerCase() == String(userType).toLowerCase()
        );
        if (!selecetedRole) this.$router.back();
        else {
          this.managePatient.role = selecetedRole;
          this.managePatient.userType = userType;
        }
      }
    },
    async checkFormValid(formStep) {
      if (formStep == "first" && (await this.patientForm.isPersonalDataValid()))
        return true;
      else if (
        formStep == "third" &&
        (await this.patientForm.isAdminEntryValid())
      )
        return true;
    },
    handlePatientSave(onSuccess) {
      this.navigationBlocker.val = true;
      this.savePatient(onSuccess);
    },
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      checkFormValid: this.checkFormValid,
      handlePatientSave: this.handlePatientSave,
      handlePatientload: this.loadPatient,
    };
  },
};

export default ManagePatientHandler;

export const withManagePatientHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    mixins: [NavigationBlocker],
    template: `<ManagePatientHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManagePatientHandler>`,
    components: { ManagePatientHandler, WrappedComponent },
  };
};
