export function handleResponse(response) {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  ) {
    return response.data;
  }
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = response.statusText;
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  let errormsg;
  if (error && error.response && error.response.data) {
    if (error.response.data.message) errormsg = error.response.data.message;
    else if (error.response.data.title) errormsg = error.response.data.title;
    else if (error.response.data.error_detail) {
      errormsg = error.response.data.error_detail;
    } else errormsg = error.response.data.error_description;
  }
  if (errormsg == "Internal Server Error from the custom middleware.")
    errormsg = undefined;
  throw errormsg || "Something went wrong. Try again.";
}
