import PackageService from "../../../services/module-services/package/PackageService";
import ListLayoutHandler from "../../common-handlers/ListLayoutHandler";
import ListHandler from "../../common-handlers/ListHandler";

const PackageHandler = {
  mixins: [PackageService],
  data() {
    return {
      listFilters: {},
    };
  },
  provide() {
    return {
      getList: this.getPackages,
      getListCount: this.getPackagesCount,
      listFilters: this.listFilters,
    };
  },
  template: `<ListHandler><ListLayoutHandler><slot></slot></ListLayoutHandler></ListHandler>`,
  components: {
    ListHandler,
    ListLayoutHandler,
  },
};

export default PackageHandler;

export const withPackageHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<PackageHandler><WrappedComponent v-bind="$props"></WrappedComponent></PackageHandler>`,
    components: { PackageHandler, WrappedComponent },
  };
};
