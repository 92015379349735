import { withManageUserHandler } from "../../handlers/module-handlers/user/ManageUserHandler";
import { withUserListHandler } from "../../handlers/module-handlers/user/UserListHandler";
import { withUserModuleHandler } from "../../handlers/module-handlers/user/UserModuleHandler";
import DoctorRoute from "../doctor/DoctorRoute";
import TechnicianRoute from "../Technician/TechnicianRoute";
import AdminRoute from "../Admin/AdminRoute";
import PatientRoute from "../patient/PatientRoute";

export default [
  {
    path: "users",
    component: withUserModuleHandler(() => import("./UserRoutePage.vue")),
    children: [
      {
        path: "",
        component: withUserListHandler(() =>
          import("./user-list/UserListPage.vue")
        ),
      },
      {
        path: "add",
        component: withManageUserHandler(() =>
          import("./manage-user/ManageUserPage.vue")
        ),
      },
      {
        path: "edit/:slug",
        component: withManageUserHandler(() =>
          import("./manage-user/ManageUserPage.vue")
        ),
      },
      ...DoctorRoute,
      ...AdminRoute,
      ...TechnicianRoute,
      ...PatientRoute,
    ],
  },
];
