import ListHandler from "../../common-handlers/ListHandler";
import DeviceModuleService from "../../../services/module-services/device/DeviceModuleService";
import Features from "../../../constants/Features";
const DeviceModuleHandler = {
  mixins: [DeviceModuleService],
  data() {
    return {
      refreshList: () => {},
      feature: Features.Devices,
    };
  },
  created() {},
  watch: {},
  methods: {},
  provide() {
    return {
      feature: Features.Devices,
      listFilters: this.listFilters,
      getList: this.getListFunc,
      getListCount: this.getListCountFunc,
    };
  },
  template: `<ListHandler :preventDefaultRefresh="false" @getRefreshList="(fun) => (refreshList = fun)"><div><slot></slot></div></ListHandler>`,
  components: {
    ListHandler,
  },
};

export default DeviceModuleHandler;

export const withDeviceModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<DeviceModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></DeviceModuleHandler>`,
    components: { DeviceModuleHandler, WrappedComponent },
  };
};
