import * as types from "./messages.action.types";

export default {
  [types.GET_MESSAGES]: (state, res) => {
    return res;
  },
  [types.GET_SENT_MESSAGES]: (state, res) => {
    return res;
  },
  [types.GET_THREAD]: (state, res) => {
    return res;
  },
  [types.GET_SELECTEDMSG]: (state, res) => {
    return res;
  },
  [types.GET_ATTACHMENTS]: (state, res) => {
    return res;
  },
  [types.GET_TO_USERS]: (state, res) => {
    state.searchUserResults = [];
    //let highfent = "-";
    state.searchUserResults = res.map((re) => {
      return {
        id: re.id,
        name: re.email,
        role: re.role,
      };
    });
  },
};
//.concat(highfent, re.roles),
