//import "./axios";
import Vue from "vue";

// A plugin file where you could register global components used across the app
import GlobalComponents from "../components/GlobalComponents";

// A plugin file where you could register global directives
import GlobalDirectives from "../directives/GlobalDirectives";

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "./side-bar";
// Tabs plugin. Used on Panels page.

import VueMaterial from "vue-material";
import DateDropdown from "vue-date-dropdown";
// asset imports
import "vue-material/dist/vue-material.min.css";
import "../assets/scss/material-dashboard.scss";
import "../assets/css/demo.css";

// library auto imports
import "es6-promise/auto";

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(VueMaterial);
    Vue.use(DateDropdown);
  },
};

Vue.config.errorHandler = (err) => {
  if (process.env.NODE_ENV !== "production") {
    // Show any error but this one
    if (err.message !== "Cannot read property 'badInput' of undefined") {
      console.error(err);
    }
  }
};
