<template>
  <md-dialog :md-active.sync="snackbar.showInformation" class="alert-dialog">
    <div class="alert-message information">
      <div class="alert-body">
        <h1>
          <i class="fas fa-info-circle"></i>
          {{ snackbar.title }}
        </h1>
        <p>{{ snackbar.description }}</p>
      </div>
      <div>
        <md-button
          @click="snackbar.showInformation = false"
          class="md-Info-outline"
          >OK</md-button
        >
      </div>
    </div>
  </md-dialog>
</template>
<script>
export default {
  inject: ["snackbar"],
};
</script>
