import * as HttpService from "../services/common-services/HttpService";

export function getPatient(patientId) {
  return HttpService.get("api/v1/Patient/" + patientId);
}

export function registerPatient(request) {
  return HttpService.post("api/v1/Register/Patient", request);
}

export function RegisterPatient(request) {
  return HttpService.post("api/v1/Register/Patient", request);
}

export function getCorrectiveLenseOptions() {
  return HttpService.get("api/v1/Patient/getCorrectiveLenseOptions");
}

export function getPatientUsers(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Patients",
    QueryData,
    "userApi.getPatientUsers"
  );
}

export function getPatientUsersCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Patients/Count",
    QueryData,
    "userApi.getPatientUsersCount"
  );
}

export function updatePatient(request) {
  return HttpService.put("api/v1/Patient/" + request.id, request);
}

export function updatePatientApproval(request) {
  return HttpService.put(
    "api/v1/Patient/" + request.id + "/Approvals",
    request
  );
}

export function createPatient(request) {
  return HttpService.post("api/v1/Patient", request);
}

export function deletePatient(patientId) {
  return HttpService.del("api/v1/Patient/" + patientId);
}

export function Patientdeactive(request) {
  return HttpService.put("api/v1/Patient/" + request.id + "/Active", request);
}
