import * as api from "../../../api/patientApi";
import * as types from "./patient.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  addPatient({ commit }, request) {
    return api.registerPatient(request);
  },
  // eslint-disable-next-line no-unused-vars
  getPatient({ commit }, id) {
    return api.getPatient(id);
  },
  // eslint-disable-next-line no-unused-vars
  getCorrectiveLenseOptions({ commit }) {
    return api.getCorrectiveLenseOptions();
  },

  // eslint-disable-next-line no-unused-vars
  updatePatient({ commit }, request) {
    return api.updatePatient(request);
  },
  // eslint-disable-next-line no-unused-vars
  updatePatientApproval({ commit }, request) {
    return api.updatePatientApproval(request);
  },
  // eslint-disable-next-line no-unused-vars
  RegisterPatient({ commit }, request) {
    return api.RegisterPatient(request);
  },
  // eslint-disable-next-line no-unused-vars
  createPatient({ commit }, request) {
    return api.createPatient(request);
  },
  // eslint-disable-next-line no-unused-vars
  deletePatient({ commit }, request) {
    return api.deletePatient(request);
  },

  // eslint-disable-next-line no-unused-vars
  Patientdeactive({ commit }, request) {
    return api.Patientdeactive(request);
  },
  // eslint-disable-next-line no-unused-vars
  getPatientUsersCount({ commit }, QueryData) {
    return api.getPatientUsersCount(QueryData);
  },
  // eslint-disable-next-line no-unused-vars
  getPatientUsers({ commit }, QueryData) {
    return api.getPatientUsers(QueryData).then((res) => {
      commit(types.GET_PatientUsers, res);
      return res;
    });
  },
};
