import { Subject } from "rxjs";

const submit = new Subject();
const cancel = new Subject();

const ConfirmationService = {
  data() {
    return {
      state: {
        show: false,
        title: "",
      },
    };
  },
  provide() {
    return {
      confirmation: this.state,
    };
  },
};

export default ConfirmationService;

export const getSubmit = () => submit.asObservable();
export const onSubmit = () => submit.next();

export const getCancel = () => cancel.asObservable();
export const onCancel = () => cancel.next();
