import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth/index";
import profile from "./modules/profile";
import user from "./modules/user";
import master from "./modules/master";
import messages from "./modules/messages";
import register from "./modules/register";
import doctor from "./modules/doctor";
import packages from "./modules/packages";
import dashboard from "./modules/dashboard";
import screeningeval from "./modules/screeningeval";
import screenings from "./modules/screenings";
import findings from "./modules/findings";
import organisations from "./modules/organisations";
import files from "./modules/files";
import Client from "./modules/Client";
import device from "./modules/device";
import patient from "./modules/patient";
import admin from "./modules/admin";
import technician from "./modules/technician";
import reports from "./modules/reports";
import referraldoctors from "./modules/referralDoctors";

Vue.use(Vuex);

export default new Vuex.Store({
  name: "store",
  modules: {
    auth,
    profile,
    user,
    messages,
    master,
    register,
    dashboard,
    packages,
    doctor,
    screeningeval,
    screenings,
    findings,
    organisations,
    files,
    Client,
    device,
    patient,
    admin,
    technician,
    reports,
    referraldoctors,
  },
  getters: {
    isPhone() {
      if (window.screen.width < 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  permissions: (state) => {
    return state.profile.permissions;
  },
});
