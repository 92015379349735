import { mapActions } from "vuex";
import {
  ClientLocationRequest,
  OrganisationData,
} from "../../../models/Client";

import { Success } from "../../../models/SwalMessages";

export default {
  inject: [
    "showSuccess",
    "showError",
    "confirmation",
    "refreshList",
    "navigationBlocker",
  ],
  data() {
    return {
      orgForm: {
        postData: new OrganisationData(),
      },
      DeviceLocationForm: {
        postData: [],
      },
      DeviceLocation: {
        postData: new ClientLocationRequest(),
      },
      manageClient: {
        showManageClientLocation: false,
        cardHeader: "Add Client",
      },
      locationDetails: {
        postData: [],
      },
      progress: {
        isDetailsLoading: false,
        isSaving: false,
        isActionLoading: false,
      },
      clients: [],
    };
  },

  created() {
    this.getDeviceList();
    this.getAllClientsList().then((res) => {
      this.clients = res;
    });
    if (this.$route.params.slug != null) {
      this.progress.isDetailsLoading = true;
      this.getDeviceLocation(this.$route.params.slug).then((res) => {
        this.locationDetails.postData = res.device;
      });
      this.getOrganisation(this.$route.params.slug).then((res) => {
        this.orgForm.postData = res;
        this.progress.isDetailsLoading = false;
      });

      this.manageClient.cardHeader = "Edit Client";
    }
  },
  methods: {
    ...mapActions("Client", [
      "OrganisationPost",
      "updateOrganisation",
      "getOrganisation",
      "DeviceLocationPost",
      "updateDeviceLocation",
      "DeleteDeviceLocation",
      "getDeviceLocation",
      "getDeviceList",
      "getAllClientsList",
    ]),

    deleteLocation(item) {
      this.progress.isSaving = true;
      this.DeleteDeviceLocation(item.id)
        .then((id) => {
          if (id) {
            var success = new Success();
            success.text = "Deleted successfully";
            this.showSuccess(success);

            this.getDeviceLocation(this.orgForm.postData.clientID).then(
              (res) => {
                this.locationDetails.postData = res.device;
              }
            );
          } else {
            var error = new Error();
            error.text = "Can not delete this Location";
            this.showError(error);
          }
        })
        .finally(() => (this.progress.isSaving = true));
    },

    getClientDeviceLocation(item) {
      this.DeviceLocation.postData.location.name = item.location.name;
      this.DeviceLocation.postData.DeviceLocation.device.name =
        item.device.name;
      this.DeviceLocation.postData.DeviceLocation.device.id = item.device.id;
      this.DeviceLocation.postData.location.id = item.location.id;
      this.DeviceLocation.postData.DeviceLocation.deviceStatus =
        item.deviceStatus;
      this.DeviceLocation.postData.DeviceLocation.id = item.id;
      this.DeviceLocation.postData.DeviceLocation.deviceId = item.device.id;
      this.DeviceLocation.postData.DeviceLocation.locationId = item.location.id;
      this.DeviceLocation.postData.location.address.id = item.address.id;
      if (item.activationDate != null) {
        let date = new Date(item.activationDate);
        let dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        this.DeviceLocation.postData.DeviceLocation.activationDate = dateString;
      }
      if (item.lastActiveDate != null) {
        let date = new Date(item.lastActiveDate);
        let dateString = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
        this.DeviceLocation.postData.DeviceLocation.lastActiveDate = dateString;
      }
      this.DeviceLocation.postData.location.regionID = item.region.id;
      this.DeviceLocation.postData.location.address.address1 =
        item.address.address1;
      this.DeviceLocation.postData.location.address.country =
        item.address.country;
      this.DeviceLocation.postData.location.address.city = item.address.city;
      this.DeviceLocation.postData.location.address.state = item.address.state;
      this.DeviceLocation.postData.location.address.zipCode =
        item.address.zipCode;
      this.manageClient.showManageClientLocation = true;
    },

    checkName(item) {
      return (
        item.clientName.toLowerCase() ===
        this.orgForm.postData.clientName.toLowerCase()
      );
    },
    checkDomain(item) {
      return (
        item.domain.toLowerCase() ===
        this.orgForm.postData.domain.toLowerCase() + ".globechek.com"
      );
    },
    saveOrganisation() {
      let postData = { ...this.orgForm.postData };
      if (this.orgForm.postData.clientID) {
        let domain = postData.domain + ".globechek.com";
        postData.domain = domain;
        this.progress.isSaving = true;
        this.updateOrganisation(postData)
          .then(() => {
            var success = new Success();
            success.text = "Updated successfully";
            this.showSuccess(success);
            this.refreshList();
          })
          .catch((err) => {
            var error = new Error();
            error.text = err;
            this.showError(error);
          })
          .finally(() => {
            this.progress.isSaving = false;
          });
      } else {
        let domain = postData.domain + ".globechek.com";
        postData.domain = domain;
        if (this.clients.find(this.checkName)) {
          var error = new Error();
          error.text = "Client name already exists";
          this.showError(error);
        } else if (this.clients.find(this.checkDomain)) {
          var errors = new Error();
          errors.text = "Domain name already exists";
          this.showError(errors);
        } else {
          this.progress.isSaving = true;
          this.OrganisationPost(postData)
            .then((id) => {
              if (id) {
                this.orgForm.postData.clientID = id;

                var success = new Success();
                success.text = "Saved successfully";
                this.showSuccess(success);
                this.refreshList();
              } else {
                var error = new Error();
                error.text = "Can not create this Client";
                this.showError(error);
              }
            })
            .catch((err) => {
              var error = new Error();
              error.text = err;
              this.showError(error);
            })
            .finally(() => {
              this.progress.isSaving = false;
            });
        }
      }
    },
    async saveLocation() {
      this.navigationBlocker.val = true;
      if (await this.DeviceLocation.isDataValid()) {
        if (this.DeviceLocation.postData.DeviceLocation.id) {
          this.progress.isActionLoading = true;
          let postData = this.DeviceLocation.postData;
          let id = this.orgForm.postData.clientID;
          this.updateDeviceLocation({ id, postData })
            .then((res) => {
              if (res) {
                this.getDeviceLocation(id).then((res) => {
                  this.locationDetails.postData = res.device;
                  var success = new Success();
                  success.text = "Updated successfully";
                  this.showSuccess(success);
                });
              }
            })
            .finally((this.progress.isActionLoading = false));
          this.DeviceLocation.postData = new ClientLocationRequest();
          this.manageClient.showManageClientLocation = false;
        } else {
          let id = this.orgForm.postData.clientID;
          let postData = this.DeviceLocation.postData;
          postData.DeviceLocation.orgId = id;
          postData.location.orgId = id;
          this.DeviceLocationPost({ id, postData })
            .then((res) => {
              if (res) {
                var success = new Success();
                success.text = "Saved successfully";
                this.showSuccess(success);
                this.getDeviceLocation(res.deviceLocation.orgID).then((res) => {
                  this.locationDetails.postData = res.device;
                });
              } else {
                var error = new Error();
                error.text = "Can not add this location";
                this.showError(error);
                this.progress.isSaving = true;
              }
            })
            .catch((err) => {
              var error = new Error();
              error.text = err;
              this.showError(error);
            });

          this.DeviceLocation.postData = new ClientLocationRequest();
          this.manageClient.showManageClientLocation = false;
        }
      }
    },
    loadOrganisation() {
      this.progress.isDetailsLoading = true;
      this.getOrganisation(this.orgForm.postData).then((res) => {
        this.orgForm.postData.clientId = res.clientId;
        this.orgForm.postData.clientName = res.clientName;
        this.orgForm.postData.businessName = res.businessName;
        this.orgForm.postData.businessType = res.businessType;
        this.orgForm.postData.contactName = res.contactName;
        this.orgForm.postData.contactEmail = res.contactEmail;
        this.orgForm.postData.contactPhone = res.contactPhone;
        this.orgForm.postData.domain = res.domain;
      });
      this.getDeviceLocation(this.DeviceLocation).then((res) => {
        this.DeviceLocation.locationName = res.location.name;
        this.DeviceLocation.device.name = res.device.name;
        this.DeviceLocation.deviceStatus = res.deviceStatus;
        this.DeviceLocation.startDate = res.activationDate;
        this.DeviceLocation.endDate = res.lastActiveDate;
        this.DeviceLocation.region = res.region.name;
        this.DeviceLocation.businessAddress.address1 = res.address;
        this.DeviceLocation.businessAddress.city = res.city;
        this.DeviceLocation.businessAddress.country = res.country;
        this.DeviceLocation.businessAddress.state = res.state;
        this.DeviceLocation.businessAddress.zipCode = res.zipCode;
        this.progress.isDetailsLoading = false;
      });
    },
  },
  provide() {
    return {
      orgForm: this.orgForm,
      DeviceLocationForm: this.DeviceLocationForm,
      DeviceLocation: this.DeviceLocation,
      getClientDeviceLocation: this.getClientDeviceLocation,
      manageClient: this.manageClient,
      locationDetails: this.locationDetails,
      progress: this.progress,
      getDeviceList: this.getDeviceList,
    };
  },
};
