import * as HttpService from "../services/common-services/HttpService";

export function OrganisationPost(PostData) {
  return HttpService.post("api/Organisations", PostData, "IDP");
}

export function getOrganisation(id) {
  return HttpService.get("api/Organisations/" + id, "IDP");
}

export function updateOrganisation(postData) {
  return HttpService.put(
    "api/Organisations/" + postData.clientID,
    postData,
    "IDP"
  );
}

export function DeleteOrganisation(id) {
  return HttpService.del("api/Organisations/" + id, "IDP");
}

export function DeviceLocationPost(id, PostData) {
  return HttpService.post("api/v1/ClientLocation/" + id, PostData);
}

export function getDeviceLocation(id) {
  return HttpService.get("api/v1/ClientLocation/" + id);
}

export function updateDeviceLocation(id, postData) {
  return HttpService.put("api/v1/ClientLocation/" + id, postData);
}

export function DeleteDeviceLocation(id) {
  return HttpService.del("api/v1/ClientLocation/" + id);
}

export function getClientList(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/Organisations",
    QueryData,
    "clientApi.getClientList",
    "IDP"
  );
}

export function getAllClients() {
  return HttpService.get("api/Organisations/list", "IDP");
}

export function getDeviceList() {
  return HttpService.get("api/v1/Device/List");
}

export function getOrganisationByDomain() {
  return HttpService.get(
    "api/Public/Organisations/" + location.hostname,
    "IDP"
  );
  //return HttpService.get("api/Public/Organisations/@eyecareclinic.com", "IDP");
}

export function getRegionClients() {
  return HttpService.get("api/v1/ClientLocation/Regions");
}
