import { mapActions, mapState } from "vuex";
import {
  PatientFamilyHistoryDetails,
  PatientHistoryDetails,
  PatientRequest,
} from "../../../models/Patient";
import { UserType } from "../../../constants/UserType";

export default {
  inject: [
    "showSuccessMsg",
    "showErrorMsg",
    "refreshList",
    "checkPermission",
    "Permissions",
  ],
  data() {
    return {
      patientForm: {
        postData: new PatientRequest(),
        historyChecks: [],
        familyHistoryChecks: [],
      },
      progress: {
        isDetailsLoading: false,
        isSaving: false,
        isApprovalProcessing: false,
      },
      managePatient: { showConfidentialConfirmation: false },
      historyList: [
        { value: "stroke", text: "Stroke" },
        { value: "migranes", text: "Migraines" },
        { value: "heart", text: "Heart Disease" },
        { value: "liver", text: "Liver Disease" },
        { value: "cholestrol", text: "High Cholesterol" },
        { value: "pressure", text: "High Blood Pressure" },
      ],
      familyHistoryList: [
        { value: "glaucoma", text: "Glaucoma" },
        { value: "migraines", text: "Migraines" },
        { value: "diabetes", text: "Diabetes" },
        { value: "pressure", text: "High Blood Pressure" },
        { value: "macDegen", text: "Macular Degeneration" },
        { value: "liver", text: "Liver Disease" },
        { value: "stroke", text: "Stroke" },
        { value: "cholesterol", text: "High Cholesterol" },
        { value: "heart", text: "Heart Disease" },
        { value: "cancer", text: "Cancer" },
        { value: "alzheimers", text: "Alzheimer's" },
      ],
      correctiveLense: [
        {
          value: "None",
          text: "No",
        },
        {
          value: "Wears Glasses",
          text: "I wear Glasses",
        },
        {
          value: "Wears Contacts",
          text: "I wear Contact Lenses",
        },
        {
          value: "Wears Glasses and Contacts",
          text: "I wear Glasses and Contact Lenses",
        },
      ],
      heightFeets: [
        { text: "3 Feet", value: "3" },
        { text: "4 Feet", value: "4" },
        { text: "5 Feet", value: "5" },
        { text: "6 Feet", value: "6" },
        { text: "7 Feet", value: "7" },
      ],
      heightInches: [
        { text: "0 Inch", value: "0" },
        { text: "1 Inch", value: "1" },
        { text: "2 Inches", value: "2" },
        { text: "3 Inches", value: "3" },
        { text: "4 Inches", value: "4" },
        { text: "5 Inches", value: "5" },
        { text: "6 Inches", value: "6" },
        { text: "7 Inches", value: "7" },
        { text: "8 Inches", value: "8" },
        { text: "9 Inches", value: "9" },
        { text: "10 Inches", value: "10" },
        { text: "11 Inches", value: "11" },
      ],
    };
  },
  computed: {
    ...mapState({
      currentOrg: (state) => state.Client.currentOrg,
    }),
  },
  methods: {
    ...mapActions("patient", [
      "addPatient",
      "RegisterPatient",
      "createPatient",
      "updatePatient",
      "updatePatientApproval",
      "getPatient",
      "getCorrectiveLenseOptions",
      "deletePatient",
      "Patientdeactive",
    ]),
    ...mapActions("user", [
      "addUser",
      "updateUserAccountCreated",
      "updateUser",
    ]),
    savePatient() {
      this.progress.isSaving = true;
      if (
        this.managePatient.page == "Register" ||
        !this.checkPermission(this.Permissions.SuperAdmin)
      )
        this.patientForm.postData.organisations = [
          sessionStorage.getItem("Org_Id") != null
            ? sessionStorage.getItem("Org_Id")
            : this.currentOrg.clientID,
        ];
      let postData = JSON.parse(JSON.stringify(this.patientForm.postData));
      postData.userType = UserType.Patient;
      postData.person.languages = null;
      postData.height = postData.height1 + "'" + postData.height2;
      var dateParts =
        postData.person.dob != null ? postData.person.dob.split(".") : null;
      if (dateParts && dateParts.length == 3)
        postData.person.dob =
          dateParts[2] + "-" + dateParts[1] + "-" + +dateParts[0];
      for (let val of this.patientForm.historyChecks)
        postData.history[val] = "true";
      for (let val of this.patientForm.familyHistoryChecks)
        postData.familyHistory[val] = "true";

      if (postData.id) {
        this.updatePatient(postData)

          .then(() => {
            console.log(postData);
            if (this.patientForm.postData.isAccountCreated) {
              this.updateUserDetails()
                .then(() => {
                  this.refreshList();
                  this.showSuccessMsg("Patient Details Updated successfully");
                  this.$router.back();
                })
                .catch((err) => {
                  this.showErrorMsg(err);
                })
                .finally(() => (this.progress.isSaving = false));
            } else {
              this.progress.isSaving = false;
              this.refreshList();
              this.showSuccessMsg("Patient Details Updated successfully");
              this.$router.back();
            }
          })
          .catch((err) => {
            console.log(err);
            this.showErrorMsg(err);
            this.progress.isSaving = false;
          });
      } else {
        if (this.managePatient.page == "Register")
          this.RegisterPatient(postData)
            .then((res) => {
              if (res.userID) {
                this.patientForm.fileUploadRef.handleSendNewFiles(res.userID);
                if (!res.restrictAccountCreation) {
                  this.patientForm.postData.userID = res.userID;
                  this.createUser(
                    "Patient details Registered Successfully",
                    "/register/success/" + this.managePatient.role.name
                  );
                } else {
                  this.showSuccessMsg(
                    "Patient details Registered Successfully"
                  );
                  this.$router.push(
                    "/register/success/" + this.managePatient.role.name
                  );
                }
              } else {
                this.showErrorMsg("Something went wrong");
                this.progress.isSaving = false;
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
        else {
          this.createPatient(postData)
            .then((res) => {
              if (res.userID) {
                this.patientForm.fileUploadRef.handleSendNewFiles(res.userID);
                if (!res.restrictAccountCreation) {
                  this.patientForm.postData.userID = res.userID;
                  this.createUser("Patient Created Successfully");
                } else {
                  this.showSuccessMsg("Patient Created Successfully");
                  this.$router.back();
                }
              } else {
                this.showErrorMsg("Something went wrong");
                this.progress.isSaving = false;
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
        }
      }
    },
    createUser(successMsg, nextRoutePath) {
      let userPostData = {
        id: this.patientForm.postData.userID,
        firstName: this.patientForm.postData.person.firstName,
        lastName: this.patientForm.postData.person.lastName,
        email: this.patientForm.postData.person.email,
        phone: this.patientForm.postData.person.cellNumber,
        role: this.managePatient.role.id,
        organisations: this.patientForm.postData.organisations,
      };
      this.addUser(userPostData)
        .then(() => {
          this.updateUserAccountCreated(userPostData.id)
            .then(() => {
              this.refreshList();
              this.showSuccessMsg(successMsg);
              if (nextRoutePath) this.$router.push(nextRoutePath);
              else this.$router.back();
            })
            .catch((err) => {
              this.showErrorMsg(err);
            })
            .finally(() => (this.progress.isSaving = false));
        })
        .catch((err) => {
          this.showErrorMsg(err);
          this.progress.isSaving = false;
        });
    },
    updateUserDetails() {
      let userPostData = {
        id: this.patientForm.postData.userID,
        firstName: this.patientForm.postData.person.firstName,
        lastName: this.patientForm.postData.person.lastName,
        email: this.patientForm.postData.person.email,
        phone: this.patientForm.postData.cellNumber,
        role: this.managePatient.role.id,
        organisations: this.patientForm.postData.organisations,
      };
      return this.updateUser(userPostData);
    },
    loadPatient() {
      this.progress.isDetailsLoading = true;
      this.getPatient(this.patientForm.postData.id)
        .then((res) => {
          if (!res.history) res.history = new PatientHistoryDetails();
          if (!res.familyHistory)
            res.familyHistory = new PatientFamilyHistoryDetails();
          this.patientForm.postData = JSON.parse(JSON.stringify(res));
          let heightArr = String(this.patientForm.postData.height).split("'");
          if (heightArr.length > 1) {
            this.patientForm.postData.height1 = heightArr[0];
            this.patientForm.postData.height2 = heightArr[1];
          }
          if (this.patientForm.postData.person.dob) {
            let date = new Date(this.patientForm.postData.person.dob);
            let dateArr = [
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate(),
            ];
            this.patientForm.canSetDOB = true;
            this.patientForm.dobObj = {
              day: dateArr[2],
              month: dateArr[1] - 1,
              year: dateArr[0],
            };
            this.patientForm.postData.person.dob =
              dateArr[2] + "." + dateArr[1] + "." + dateArr[0];
          }
          for (let obj of this.historyList) {
            if (this.patientForm.postData.history[obj.value] == "true")
              this.patientForm.historyChecks.push(obj.value);
            this.patientForm.postData.history[obj.value] = "false";
          }
          for (let obj of this.familyHistoryList) {
            if (this.patientForm.postData.familyHistory[obj.value] == "true")
              this.patientForm.familyHistoryChecks.push(obj.value);
            this.patientForm.postData.familyHistory[obj.value] = "false";
          }
        })
        .finally(() => (this.progress.isDetailsLoading = false));
    },
    loadCorrectiveLenseOptions() {
      this.progress.isDetailsLoading = true;
      this.getCorrectiveLenseOptions()
        .then((res) => {
          this.correctiveLense.options = res.options;
        })
        .finally(() => (this.progress.isDetailsLoading = false));
    },
  },
  provide() {
    return {
      patientForm: this.patientForm,
      managePatient: this.managePatient,
      correctiveLense: this.correctiveLense,
      heightFeets: this.heightFeets,
      heightInches: this.heightInches,
      progress: this.progress,
      historyList: this.historyList,
      familyHistoryList: this.familyHistoryList,
    };
  },
};
