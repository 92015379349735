import { withMasterDataModuleHandler } from "../../../handlers/module-handlers/MasterData/MasterDataModuleHandler";
import { withMasterDataListHandler } from "../../../handlers/module-handlers/MasterData/MasterDataListHandler";
export default [
  {
    path: "MasterData",
    name: "MasterData",
    component: withMasterDataModuleHandler(() =>
      import("./MasterDataLanding.vue")
    ),
    redirect: "/globe-chek/MasterData",
    children: [
      {
        path: "",
        name: "MasterData-list",
        component: withMasterDataListHandler(() =>
          import("./MasterData-list/MasterDataListPage.vue")
        ),
      },
    ],
  },
];
