export class Prescription {
  id = "";
  orgID = "";
  screeningID = "";
  sphereOD = null;
  sphereOS = null;
  cylinderOD = null;
  cylinderOS = null;
  axisOD = null;
  axisOS = null;
  powerOD = null;
  powerOS = null;
  distanceOD = null;
  distanceOS = null;
  approved = "";
  approvedBy = "";
  approvedOn = "12-03-2021";
  createdBy = "";
  createdAt = "12-03-2021";
  updatedBy = "";
  updatedAt = "12-03-2021";
}
