import reportsModuleService from "../../../services/module-services/reports/reportsModuleService";
import ListLayoutHandler from "../../common-handlers/ListLayoutHandler";
import ListHandler from "../../common-handlers/ListHandler";

const ReportsModuleHandler = {
  mixins: [reportsModuleService],
  methods: {},

  provide() {
    return {
      getList: this.getReports,
      getListCount: this.getReportsCount,
      SortList: this.getSortList,
    };
  },
  template: `<ListHandler><ListLayoutHandler><slot></slot></ListLayoutHandler></ListHandler>`,
  components: {
    ListHandler,
    ListLayoutHandler,
  },
};

export default ReportsModuleHandler;

export const withReportsModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ReportsModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></ReportsModuleHandler>`,
    components: { ReportsModuleHandler, WrappedComponent },
  };
};
