import state from "./doctor.state";
import actions from "./doctor.actions";
import mutations from "./doctors.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
