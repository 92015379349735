export default {
  inject: ["queryableListFunc"],
  methods: {
    getListFunc(props) {
      let postData = { ...props };
      postData.PageNumber = props.page;
      postData.PageSize = props.size;
      return this.queryableListFunc(postData);
    },
  },
  provide() {
    return {
      getList: this.getListFunc,
      getListCount: () => {},
    };
  },
  template: `<div><slot></slot></div>`,
};
