import { withManageTechnicianHandler } from "../../handlers/module-handlers/technician/ManageTechnicianHandler";

export default [
  {
    path: "Technician",
    component: () => import("./TechnicianRoutePage"),
    meta: { isPublic: true },
    children: [
      {
        path: ":technicianId/approvals",
        component: withManageTechnicianHandler(() =>
          import("./manage-technician/ManagementTechnicianPage.vue")
        ),
        meta: { isApproval: true },
      },
      {
        path: "add",
        component: withManageTechnicianHandler(() =>
          import("./manage-technician/ManagementTechnicianPage.vue")
        ),
        meta: { userType: "Technician" },
      },
      {
        path: "edit/:technicianId",
        component: withManageTechnicianHandler(() =>
          import("./manage-technician/ManagementTechnicianPage.vue")
        ),
      },
    ],
  },
];
