import state from "./files.state";
import actions from "./files.actions";
import mutations from "./files.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
