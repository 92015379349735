import { mapActions, mapState } from "vuex";
import Features from "../../../constants/Features";
import { getLatLngByZipcode } from "../../common-services/SharedFunctionService";

export default {
  data() {
    return {
      listFilters: { doctorType: "Doctor" },
    };
  },
  computed: {
    ...mapState({
      orgPreferences: (state) => state.profile.orgPreferences,
    }),
  },
  methods: {
    ...mapActions("doctor", ["getNearestDoctors", "getNearestDoctorsCount"]),
    ...mapActions("user", ["getDoctorUsers", "getDoctorUsersCount"]),
    getListFunc(props) {
      if (props.filter == "zip" && String(props.searchText).length > 0) {
        return getLatLngByZipcode(props.searchText).then((latlang) => {
          if (latlang && latlang.length == 2) {
            props.latitude = latlang[0];
            props.longitute = latlang[1];
          } else {
            props.latitude = 0;
            props.longitute = 0;
          }
          props.kilometers = this.orgPreferences.zipCodeKms;
          props.searchText = "";
          return this.getNearestDoctors(props);
        });
      } else return this.getDoctorUsers(props);
    },
    getListCountFunc(props) {
      if (props.filter == "zip" && String(props.searchText).length > 0) {
        return getLatLngByZipcode(props.searchText).then((latlang) => {
          if (latlang && latlang.length == 2) {
            props.latitude = latlang[0];
            props.longitute = latlang[1];
          } else {
            props.latitude = 0;
            props.longitute = 0;
          }
          props.kilometers = this.orgPreferences.zipCodeKms;
          props.searchText = "";
          return this.getNearestDoctorsCount(props);
        });
      } else return this.getDoctorUsersCount(props);
    },
  },
  provide() {
    return {
      feature: Features.Providers,
      listFilters: this.listFilters,
    };
  },
};
