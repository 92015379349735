import { getToken } from "../common-services/AuthService";
import { mapState, mapActions } from "vuex";
import Permissions from "../../constants/Permissions";
import { authorizePermission } from "../common-services/SharedFunctionService";
import axios from "axios";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);
import { computed } from "@vue/composition-api";

export default {
  data() {
    return {
      app: {
        isLoggedIn: getToken() != null,
        authValidated: false,
        showLoader: computed(() => {
          return this.isLoading;
        }),
        isLoading: false,
      },
      previewImage: { showImagePreview: false, image: "" },
      isLoading: false,
      axiosInterceptor: null,
      msgnocount: { value: 0 },
      navigationBlocker: { val: false },
    };
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.profile.userProfile,
      userClaims: (state) => state.profile.userClaims,
      currentOrg: (state) => state.Client.currentOrg,
      userNotificationperference: (state) =>
        state.profile.userNotificationperference,
    }),
  },
  methods: {
    ...mapActions("profile", [
      "getProfile",
      "getProfileClaims",
      "getDoctorByUserId",
      "setUserAttributesSuccess",
      "getNotificationpreferences",
    ]),
    ...mapActions("Client", ["getOrganisationByDomain"]),
    ...mapActions("dashboard", ["getSidebarDate"]),
    checkPermission(permission) {
      if (
        this.userClaims.includes(permission) ||
        this.userClaims.includes(Permissions.SuperAdmin)
      )
        return true;
    },
    isSuperAdmin() {
      return this.checkPermission(Permissions.SuperAdmin);
    },
    getSelectedOrgId() {
      if (!this.isSuperAdmin()) return this.currentOrg.clientID;
      else if (localStorage.getItem("Org_Id") != this.currentOrg.clientID)
        return localStorage.getItem("Org_Id");
    },
    loadAttributes() {
      if (this.userProfile.roles && this.userProfile.roles.length > 0) {
        let selectedRole = this.userProfile.roles[0].name;
        if (selectedRole == "Reader" || selectedRole == "Doctor")
          this.getDoctorByUserId(this.userProfile.id);
        else this.setUserAttributesSuccess();
      }
      if (this.userClaims.includes(Permissions.IsPatient)) {
        this.getSidebarDate(this.userProfile.id).then((response) => {
          return response;
        });
      }
      this.getNotificationpreferences(this.userProfile.id).then((response) => {
        response.allowEmailNotification =
          this.userNotificationperference.allowEmailNotification;
        response.allowPopUpNotification =
          this.userNotificationperference.allowPopUpNotification;
      });
    },
    enableInterceptor() {
      this.axiosInterceptor = axios.interceptors.request.use(
        (config) => {
          this.isLoading = true;
          return config;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.isLoading = false;
          return response;
        },
        (error) => {
          this.isLoading = false;
          return Promise.reject(error);
        }
      );
    },

    disableInterceptor() {
      axios.interceptors.request.eject(this.axiosInterceptor);
    },
    convertUTCDateToLocalDate(date) {
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var tmp = new Date(
        new Date(
          date.getTime() - new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleString("en-us", { timeZone })
      );
      var date_time = new Date(tmp),
        mnth = ("0" + (date_time.getMonth() + 1)).slice(-2),
        day = ("0" + date_time.getDate()).slice(-2);
      return [mnth, day, date_time.getFullYear()].join("/");
    },
  },
  created() {
    this.app.isLoading = true;
    this.getOrganisationByDomain().finally(() => {
      this.app.isLoading = false;
    });
    if (this.app.isLoggedIn) {
      this.getProfile().then((res) => {
        let profileDetails = { ...res };
        if (
          profileDetails.organisations &&
          profileDetails.organisations.length > 0 &&
          sessionStorage.getItem("Org_Id") == null
        ) {
          let orgId;
          if (
            profileDetails.preferences &&
            profileDetails.preferences.defaultOrg &&
            profileDetails.preferences.defaultOrg.id
          )
            orgId = profileDetails.preferences.defaultOrg.id;
          else
            orgId =
              profileDetails.organisations.length == 1
                ? profileDetails.organisations[0].id
                : "";
          sessionStorage.setItem("Org_Id", orgId);
          localStorage.setItem("Org_Id", orgId);
        }
        sessionStorage.setItem("UserId", profileDetails.id);
        this.getProfileClaims().then(() => {
          const { permission } = this.$router.currentRoute.meta;
          if (permission && !authorizePermission(permission))
            this.$router.push(
              "unauthorize",
              () => (this.app.authValidated = true)
            );
          else {
            if (
              profileDetails.isTempPassword &&
              this.$route.path != "/globe-chek/mysettings"
            )
              this.$router.push("/globe-chek/mysettings", () => {
                this.app.authValidated = true;
                this.loadAttributes();
              });
            else {
              this.app.authValidated = true;
              this.loadAttributes();
            }
          }
        });
      });
    }
  },
  provide() {
    const app = this.app;
    Object.defineProperty(app, "userProfile", {
      enumerable: true,
      get: () => this.userProfile,
    });
    return {
      app,
      Permissions,
      previewImage: this.previewImage,
      msgnocount: this.msgnocount,
      navigationBlocker: this.navigationBlocker,
      convertUTCDateToLocalDate: this.convertUTCDateToLocalDate,
    };
  },
  mounted() {
    this.enableInterceptor();
  },
};
