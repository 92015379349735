import * as api from "../../../api/dashboardApi";
import * as types from "./dashboard.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  allScreeningsCount({ commit }) {
    return api.getScreeningsAvailableCount();
  },

  // eslint-disable-next-line no-unused-vars
  getSidebarDate({ commit }, memberID) {
    return api.getSidebarDate(memberID).then((res) => {
      commit(types.GET_SCREENING_DATES, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  allOnholdScreeningsCount({ commit }) {
    return api.getScreeningsOnholdCount();
  },

  // eslint-disable-next-line no-unused-vars
  reportsCount({ commit }) {
    return api.getReportsCount();
  },

  // eslint-disable-next-line no-unused-vars
  reportsCountByReaderId({ commit }, readerID) {
    return api.getReportsCountByReaderId(readerID);
  },

  // eslint-disable-next-line no-unused-vars
  reportsCountByMemberId({ commit }, userID) {
    return api.getReportsCountByMemberId(userID);
  },

  // eslint-disable-next-line no-unused-vars
  getGlobeActivityCount({ commit }) {
    return api.getGlobeActivityCount();
  },

  // eslint-disable-next-line no-unused-vars
  globeActivity({ commit }, QueryData) {
    return api.getGlobeActivity(QueryData).then((res) => {
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  referralDoctorsCount({ commit }) {
    return api.getReferralDoctorsCount();
  },

  // eslint-disable-next-line no-unused-vars
  getMessagesCount({ commit }, userID) {
    return api.getMessagesCount(userID);
  },
};
