import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  data() {
    return {
      listPage: {},
      listFilters: {},
      showDialog: { val: false },
    };
  },
  methods: {
    ...mapActions("device", ["searchDevicesWithQueryData"]),
    getListFunc(props) {
      return new Promise((resolve) => {
        this.searchDevicesWithQueryData(props).then((res) => {
          resolve(res.data);
        });
      });
    },
    openDialog() {
      this.showDialog.val = true;
    },
    closeDialog() {
      this.showDialog.val = false;
    },
    getListCountFunc(props) {
      return new Promise((resolve) => {
        this.searchDevicesWithQueryData(props).then((res) => {
          resolve(res.totalRecords);
        });
      });
    },
  },
  provide() {
    return {
      feature: Features.Devices,
      listPage: this.listPage,
      closeDialog: this.closeDialog,
      openDialog: this.openDialog,
      showDialog: this.showDialog,
    };
  },
};
