import { withProviderModuleHandler } from "../../handlers/module-handlers/providers/ProviderModuleHandler";
import { withProviderListHandler } from "../../handlers/module-handlers/providers/ProviderListHandler";

export default [
  {
    path: "providers",
    component: withProviderModuleHandler(() =>
      import("./ProvidersRoutePage.vue")
    ),
    children: [
      {
        path: "",
        component: withProviderListHandler(() =>
          import("./provider-list/ProviderListPage.vue")
        ),
      },
    ],
  },
];
