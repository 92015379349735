import * as HttpService from "../services/common-services/HttpService";

export function getScreeningsAvailable(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Screenings/ScreeningList",
    QueryData,
    "screeningsApi.getScreeningsAvailable"
  );
}
export function deleteScreeningsAvailable(id) {
  return HttpService.del("api/v1/Screenings/" + id);
}
export function getScreeningsAvailableCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Screenings/ScreeningListCount",
    QueryData,
    "screeningsApi.getScreeningsAvailableCount"
  );
}

export function getDurationList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/Duration");
}
export function getScrSortList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/SortScr");
}
export function getHoldSortList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/SortHold");
}

export function getScreeningsOnhold(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Screenings/OnHoldScreeningList",
    QueryData,
    "screeningsApi.getScreeningsAvailable"
  );
}

export function getScreeningsOnholdCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Screenings/OnHoldScreeningListCount",
    QueryData,
    "screeningsApi.getScreeningsOnholdCount"
  );
}

export function updateHold(onholdscreeningData) {
  return HttpService.put(
    "api/v1/Screenings/updateHold/" + onholdscreeningData.id,
    onholdscreeningData,
    "Default"
  );
}

export function requestHold(requestscreeningData) {
  return HttpService.post(
    "api/v1/Messages/ReleaseNotification/" + requestscreeningData.screeningId,
    requestscreeningData,
    "Default"
  );
}

export function deleteAllTestPatients(orgId) {
  return HttpService.del(
    "api/v1/Screenings/AllTestPatients" + (orgId ? "?orgId=" + orgId : "")
  );
}
