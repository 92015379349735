import state from "./admin.state";
import actions from "./admin.actions";
import mutations from "./admin.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
