import { withScreeningsModuleHandler } from "../../handlers/module-handlers/screeningsAvailable/screeningsModuleHandler";
import { withScreeningsHandler } from "../../handlers/module-handlers/screeningsAvailable/screeningsHandler";
import { withscreeningevalHandler } from "../../handlers/module-handlers/screeningevalhandler/screeningevalHandler";

export default [
  {
    path: "Screenings",
    name: "Screenings",
    component: withScreeningsModuleHandler(() =>
      import("../Screening/ScreeningsRoutePage.vue")
    ),
    children: [
      {
        path: "",
        component: withScreeningsHandler(() =>
          import("../Screening/ScreeningsAvailableListPage.vue")
        ),
        meta: { title: "Screenings" },
      },
      {
        path: "evaluation/:id",
        name: "ScreeningsEval",

        component: withscreeningevalHandler(() =>
          import("../Screening/Screenings.vue")
        ),
        meta: { title: "Screenings" },
      },
    ],
  },
];
