import MasterService from "../../../services/common-services/MasterService";
import UserModuleService from "../../../services/module-services/user/UserModuleService";
import ListHandler from "../../common-handlers/ListHandler";

const UserModuleHandler = {
  mixins: [UserModuleService, MasterService],
  created() {
    this.loadMasterList(["roleList"]);
    this.loadSelectedRole();
  },
  watch: {
    "master.roleList": function () {
      this.loadSelectedRole();
    },
    "listPage.selectedRoleId": function () {
      this.listPage.selectedRole = this.master.roleList.find(
        (x) => x.id == this.listPage.selectedRoleId
      );
      if (this.listPage.selectedRole && this.listPage.selectedRole.name) {
        if (["Doctor", "Reader"].includes(this.listPage.selectedRole.name)) {
          this.listFilters.doctorType = this.listPage.selectedRole.name;
          if (this.listFilters.doctorType == "Doctor")
            this.listPage.module = "doctors";
          else if (this.listFilters.doctorType == "Reader")
            this.listPage.module = "readers";
        } else if (["Admin"].includes(this.listPage.selectedRole.name)) {
          this.listPage.module = "Admin";
        } else if (["Technician"].includes(this.listPage.selectedRole.name)) {
          this.listPage.module = "Technician";
        } else if (["Patient"].includes(this.listPage.selectedRole.name)) {
          this.listPage.module = "Patient";
        }
      }
    },
  },
  methods: {
    loadSelectedRole() {
      if (
        !this.listPage.selectedRoleId &&
        this.master.roleList &&
        this.master.roleList.length > 0 &&
        this.master.roleList.find((x) => x.name == "Doctor")
      ) {
        this.listPage.selectedRole = this.master.roleList.find(
          (x) => x.name == "Doctor"
        );
        this.listPage.selectedRoleId = this.listPage.selectedRole.id;
      }
    },
  },
  provide() {
    return {
      listFilters: this.listFilters,
      getList: this.getListFunc,
      getListCount: this.getListCountFunc,
      manualRefreshList: this.manualRefreshList,
    };
  },
  template: `<ListHandler :canRefreshOnFilter="true" :preventDefaultRefresh="true" @getRefreshList="(fun) => (listPage.refreshList = fun)"><div><slot></slot></div></ListHandler>`,
  components: {
    ListHandler,
  },
};

export default UserModuleHandler;

export const withUserModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<UserModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></UserModuleHandler>`,
    components: { UserModuleHandler, WrappedComponent },
  };
};
