import * as HttpService from "../services/common-services/HttpService";

export function addUser(userData) {
  return HttpService.postForm("api/Users", userData, "IDP");
}

export function updateUser(userData) {
  return HttpService.putFormWithAuth(
    "api/Users/" + userData.id,
    userData,
    "IDP"
  );
}

export function deleteUser(id) {
  return HttpService.del("api/Users/" + id, "IDP");
}

export function getUser(id) {
  return HttpService.get("api/Users/" + id, "IDP");
}

export function ResetPasswordPost(postData) {
  return HttpService.postForm("api/Accounts/ForgotPassword", postData, "IDP");
}

export function ResetPasswordConfirmPost(postData) {
  return HttpService.postForm("api/Accounts/ResetPassword", postData, "IDP");
}

export function ChangePasswordConfirmPost(postData) {
  return HttpService.postForm("api/Accounts/ChangePassword", postData, "IDP");
}

export function ChangePasswordDirect(postData) {
  return HttpService.postFormWithAuth(
    "api/Accounts/ChangePasswordDirect",
    postData,
    "IDP"
  );
}

export function getUsers(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/Users",
    QueryData,
    "userApi.getUsers",
    "IDP"
  );
}

export function getUsersCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/Users/Count",
    QueryData,
    "userApi.getUsersCount",
    "IDP"
  );
}

export function updateUserActive(userData) {
  return HttpService.put(
    "api/Users/" + userData.id + "/Active",
    userData,
    "IDP"
  );
}

export function getDoctorUsers(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Doctors",
    QueryData,
    "userApi.getDoctorUsers"
  );
}

export function getDoctorUsersCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Doctors/Count",
    QueryData,
    "userApi.getDoctorUsersCount"
  );
}

export function RegisterUser(request) {
  return HttpService.post("api/v1/ClientUser/register", request);
}

export function updateClientUser(request) {
  return HttpService.put("/api/v1/ClientUser/" + request.id, request);
}

export function getClientUser(ClientUserid) {
  return HttpService.get("/api/v1/ClientUser/" + ClientUserid);
}

export function updateUserAccountCreated(userId) {
  return HttpService.put("api/v1/User/" + userId + "/AccountCreated");
}
