import ValidationHandler, {
  ValidationListener,
} from "../handlers/common-handlers/ValidationHandler";
import HasPermission from "./HasPermission.vue";
import { Fragment } from "vue-fragment";
import PrimaryButton from "./controls/button/PrimaryButton.vue";
import RoundedButton from "./controls/button/RoundedButton.vue";
import SelectInput from "./controls/SelectInput.vue";
import CheckboxSingleInput from "./controls/CheckBoxSingleInput.vue";
import CheckBoxInput from "./controls/CheckBoxInput.vue";
import TextInput from "./controls/TextInput.vue";
import SearchTextInput from "./controls/autocomplete/SearchTextInput.vue";
import ListBox from "./controls/List/ListBox.vue";
import Spinner from "./controls/Spinner.vue";
import Loading from "vue-loading-overlay";
import VueHtml2pdf from "vue-html2pdf";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("ValidationHandler", ValidationHandler);
    Vue.component("ValidationListener", ValidationListener);
    Vue.component("HasPermission", HasPermission);
    Vue.component("fragment", Fragment);
    Vue.component("primary-button", PrimaryButton);
    Vue.component("rounded-button", RoundedButton);
    Vue.component("select-input", SelectInput);
    Vue.component("checkbox-single-input", CheckboxSingleInput);
    Vue.component("check-box-input", CheckBoxInput);
    Vue.component("text-input", TextInput);
    Vue.component("search-text-input", SearchTextInput);
    Vue.component("spinner", Spinner);
    Vue.component("loading", Loading);
    Vue.component("list-box", ListBox);
    Vue.component("VueHtml2pdf", VueHtml2pdf);
    Vue.component("VuePdfApp", VuePdfApp);
  },
};

export default GlobalComponents;
