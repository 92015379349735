import { mapActions } from "vuex";
import { Success } from "../../../models/SwalMessages";

export default {
  inject: [
    "confirmation",
    "showSuccess",
    "showError",
    "list",
    "listDetails",
    "listInput",
    "refreshList",
  ],
  methods: {
    ...mapActions("Client", ["DeleteOrganisation"]),
    delete(orgID) {
      this.DeleteOrganisation(orgID).then((res) => {
        if (res) {
          var success = new Success();
          success.text = "Deleted successfully";
          this.showSuccess(success);
          this.list.pages[this.listInput.page].items = this.list.pages[
            this.listInput.page
          ].items.filter((x) => x.clientID != orgID);
          this.listDetails.count--;
          if (this.list.pages[this.listInput.page].items.length == 0)
            this.refreshList();
        } else {
          var error = new Error();
          error.text = "Can not delete this Client";
          this.showError(error);
        }
      });
    },
  },
};
