import * as api from "../../../api/userApi";
import * as types from "./user.action.types";
export default {
  // eslint-disable-next-line no-unused-vars
  addUser({ commit }, userData) {
    return api.addUser(userData);
  },
  // eslint-disable-next-line no-unused-vars
  updateUser({ commit }, userData) {
    return api.updateUser(userData);
  },
  // eslint-disable-next-line no-unused-vars
  deleteUser({ commit }, id) {
    return api.deleteUser(id);
  },
  // eslint-disable-next-line no-unused-vars
  getUser({ commit }, id) {
    return api.getUser(id);
  },
  // eslint-disable-next-line no-unused-vars
  ResetPasswordPost({ commit }, postData) {
    return api.ResetPasswordPost(postData);
  },
  // eslint-disable-next-line no-unused-vars
  ResetPasswordConfirmPost({ commit }, postData) {
    return api.ResetPasswordConfirmPost(postData);
  },
  // eslint-disable-next-line no-unused-vars
  ChangePasswordConfirmPost({ commit }, postData) {
    return api.ChangePasswordConfirmPost(postData);
  },
  // eslint-disable-next-line no-unused-vars
  ChangePassworDirect({ commit }, postData) {
    return api.ChangePasswordDirect(postData);
  },
  // eslint-disable-next-line no-unused-vars
  getUsers({ commit }, QueryData) {
    return api.getUsers(QueryData);
  },
  // eslint-disable-next-line no-unused-vars
  getUsersCount({ commit }, QueryData) {
    return api.getUsersCount(QueryData);
  },
  // eslint-disable-next-line no-unused-vars
  updateUserActive({ commit }, userData) {
    return api.updateUserActive(userData);
  },
  // eslint-disable-next-line no-unused-vars
  getDoctorUsers({ commit }, QueryData) {
    return api.getDoctorUsers(QueryData).then((res) => {
      commit(types.GET_DoctorUsers, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  getDoctorUsersCount({ commit }, QueryData) {
    return api.getDoctorUsersCount(QueryData);
  },

  // eslint-disable-next-line no-unused-vars
  RegisterUser({ commit }, request) {
    return api.RegisterUser(request);
  },

  // eslint-disable-next-line no-unused-vars
  updateClientUser({ commit }, request) {
    return api.updateClientUser(request);
  },

  // eslint-disable-next-line no-unused-vars
  getClientUser({ commit }, request) {
    return api.getClientUser(request);
  },
  // eslint-disable-next-line no-unused-vars
  updateUserAccountCreated({ commit }, userId) {
    return api.updateUserAccountCreated(userId);
  },
};
