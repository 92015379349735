import MasterService from "../../../services/common-services/MasterService";
import ManageTechnicianService from "../../../services/module-services/technician/ManageTechnicianService";
import NavigationBlocker from "../../../components/NavigationBlocker";

const ManageTechnicianHandler = {
  mixins: [ManageTechnicianService, MasterService],
  created() {
    let masterList = ["stateList", "countryCodeList", "genderList", "roleList"];
    if (!location.pathname.includes("/register/"))
      masterList = masterList.concat(["organisationList"]);
    this.loadMasterList(masterList);
    if (location.pathname.endsWith("/approvals")) {
      this.manageTechnician.page = "Approval";
    } else if (location.pathname.includes("/register/")) {
      this.manageTechnician.page = "Register";
    } else this.manageTechnician.page = "manage";
    if (this.$route.params.technicianId) {
      this.UserForm.postData.id = this.$route.params.technicianId;
      this.manageTechnician.id = this.$route.params.technicianId;
      this.loadTechnician();
    }
    this.loadSelectedRole();
  },
  watch: {
    "master.roleList": function () {
      this.loadSelectedRole();
    },
  },
  methods: {
    loadSelectedRole() {
      let userType;
      if (
        this.$router.currentRoute.meta &&
        this.$router.currentRoute.meta.userType
      )
        userType = this.$router.currentRoute.meta.userType;
      else if (this.$route.params.userType)
        userType = this.$route.params.userType;
      if (userType && this.master.roleList.length > 0) {
        let selecetedRole = this.master.roleList.find(
          (x) => String(x.name).toLowerCase() == String(userType).toLowerCase()
        );
        if (!selecetedRole) this.$router.back();
        else {
          this.manageTechnician.role = selecetedRole;
          this.manageTechnician.userType = userType;
        }
      }
    },
    async isValidForm() {
      return await this.UserForm.isPersonalDataValid();
    },
    handleTechnicianApprove(isApproved) {
      this.UserForm.postData.isApproved = isApproved;
      this.saveTechnician();
    },
  },
  provide() {
    return {
      isValidForm: this.isValidForm,
      handleTechnicianApprove: this.handleTechnicianApprove,
      handleTechnicianSave: this.saveTechnician,
      handleTechnicianLoad: this.loadTechnician,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ManageTechnicianHandler;

export const withManageTechnicianHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    mixins: [NavigationBlocker],
    template: `<ManageTechnicianHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManageTechnicianHandler>`,
    components: { ManageTechnicianHandler, WrappedComponent },
  };
};
