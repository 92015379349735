import * as HttpService from "../services/common-services/HttpService";
export function getPackages(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Packages",
    QueryData,
    "packagesApi.getPackages"
  );
}
export function getPackagesCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Packages/Count",
    QueryData,
    "packagesApi.getPackagesCount"
  );
}

export function getScreeningsAvailable(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Screenings/ScreeningList",
    QueryData,
    "screeningsApi.getScreeningsAvailable"
  );
}
