import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  inject: ["app", "Permissions"],
  data() {
    return {
      listFilters: {},
    };
  },

  methods: {
    ...mapActions("referraldoctors", [
      "getReferralDoctor",
      "referralDoctorsCount",
    ]),

    getReferralDoctorsList() {
      this.getReferralDoctor().then((response) => {
        return response;
      });
    },
  },
  provide() {
    return {
      feature: Features.ReferralDoctor,
    };
  },
};
