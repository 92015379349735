import { mapActions, mapState } from "vuex";
import Features from "../../../constants/Features";
import { PersonStatus } from "../../../constants/Person";

export default {
  inject: ["checkPermission", "Permissions"],
  data() {
    return {
      listPage: {
        selectedRoleId: undefined,
        selectedRole: undefined,
        module: "",
        placeHolderFilterList: [{ value: "", displayValue: "Filter" }],
        doctorFilterList: [
          {
            value: "OphMD",
            displayValue: "US Ophthalmologist MD",
          },
          {
            value: "OphOD",
            displayValue: "US Ophthalmologist OD",
          },
          {
            value: "OptOD",
            displayValue: "US Optometrist OD",
          },
          {
            value: "Other",
            displayValue: "Other",
          },
          {
            value: "Domestic",
            displayValue: "All Domestic",
          },
          {
            value: "Foreign",
            displayValue: "All Foreign",
          },
        ],
        commonFilterList: [
          {
            value: "Active",
            displayValue: "Active",
          },
          {
            value: "Pending",
            displayValue: "Pending",
          },
        ],
      },
      listFilters: { onlyReader: false },
      Status: [
        { value: 1, text: "Approval pending" },
        { value: 2, text: "Approved" },
      ],
    };
  },
  computed: {
    ...mapState({
      currentOrg: (state) => state.Client.currentOrg,
    }),
  },
  methods: {
    ...mapActions("user", ["getDoctorUsers", "getDoctorUsersCount"]),
    ...mapActions("admin", ["getAdminUsers", "getAdminUsersCount"]),
    ...mapActions("technician", [
      "getTechnicianUsersCount",
      "getTechnicianUsers",
    ]),
    ...mapActions("patient", ["getPatientUsersCount", "getPatientUsers"]),
    setPropsQueries(props) {
      if (props.filter == "Active") props.Status = PersonStatus.Active;
      else if (props.filter == "Pending")
        props.Status = PersonStatus.ApprovalPending;
      else if (["OphMD", "OphOD", "OptOD", "Other"].includes(props.filter))
        props.qualificationName = props.filter;
      else if (["Domestic", "Foreign"].includes(props.filter))
        props.country = props.filter;
    },
    getListFunc(props) {
      this.setPropsQueries(props);
      if (
        this.listPage.selectedRole.name == "Doctor" ||
        this.listPage.selectedRole.name == "Reader"
      )
        return this.getDoctorUsers(props);
      else if (this.listPage.selectedRole.name == "Admin") {
        return this.getAdminUsers(props);
      } else if (this.listPage.selectedRole.name == "Technician") {
        return this.getTechnicianUsers(props);
      } else if (this.listPage.selectedRole.name == "Patient") {
        return this.getPatientUsers(props);
      }
    },
    getListCountFunc(props) {
      this.setPropsQueries(props);
      if (
        this.listPage.selectedRole.name == "Doctor" ||
        this.listPage.selectedRole.name == "Reader"
      )
        return this.getDoctorUsersCount(props);
      else if (this.listPage.selectedRole.name == "Admin") {
        return this.getAdminUsersCount(props);
      } else if (this.listPage.selectedRole.name == "Technician") {
        return this.getTechnicianUsersCount(props);
      } else if (this.listPage.selectedRole.name == "Patient") {
        return this.getPatientUsersCount(props);
      }
    },
  },
  provide() {
    return {
      feature: Features.Users,
      listPage: this.listPage,
      Status: this.Status,
    };
  },
};
