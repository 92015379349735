import { withReportsModuleHandler } from "../../handlers/module-handlers/reports/reportsModuleHandler";
import { withReportsHandler } from "../../handlers/module-handlers/reports/reportsHandler";
import { withscreeningevalHandler } from "../../handlers/module-handlers/screeningevalhandler/screeningevalHandler";

export default [
  {
    path: "Reports",
    name: "Reports",
    component: withReportsModuleHandler(() =>
      import("../Reports/ReportsRoutePage.vue")
    ),
    children: [
      {
        path: "",
        component: withReportsHandler(() => import("../Reports/Reports.vue")),
        meta: { title: "Reports" },
      },
      {
        path: "ReportViewer/:id",
        name: "ReportViewer",

        component: withReportsHandler(() =>
          import("../Reports/ReportViewer.vue")
        ),
        meta: { title: "Reports" },
      },
      {
        path: "Edit/:id",
        name: "ScreeningsEval",

        component: withscreeningevalHandler(() =>
          import("../Screening/Screenings.vue")
        ),

        meta: { title: "Screenings/Edit" },
      },
    ],
  },
];
