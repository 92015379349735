import { mapActions, mapState } from "vuex";
import { Success } from "../../../models/SwalMessages";

export default {
  inject: ["showSuccess", "showError", "app"],
  data() {
    return {
      userId: this.app.userProfile.id,
      userType: this.app.userProfile.roles[0].name,
    };
  },
  computed: {
    ...mapState({
      screenings: (state) => state.screenings,
    }),
  },
  methods: {
    ...mapActions("screenings", ["updateHold"]),
    updateHoldStatus(postformData, onSuccessFunc) {
      this.updateHold(postformData)
        .then(() => {
          var success = new Success();
          success.text = "Screening released successfully";
          this.showSuccess(success);
          if (onSuccessFunc) onSuccessFunc();
        })
        .catch((error) => this.showError(error))
        .finally();
    },
  },
  provide() {
    return {};
  },
};
