<template>
  <div style="position: absolute; top: 45%; left: 45%">
    <Spinner />
  </div>
</template>
<script>
import Spinner from "./controls/Spinner.vue";
export default {
  components: {
    Spinner,
  },
};
</script>
