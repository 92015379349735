import * as HttpService from "../services/common-services/HttpService";

export function uploadFile(file, uploadProgress) {
  return fetch(file.link)
    .then((res) => res.blob())
    .then((blob) => {
      return HttpService.uploadBlobToAzure(
        blob,
        file.uploadLink,
        file.contentType,
        uploadProgress
      );
    });
}

export function postFiles(options, files) {
  return HttpService.post(
    `api/v1/${options.publicFeature || options.feature}/${
      options.featureId
    }/files/${options.category}`,
    files
  );
}

export function getFiles(options) {
  return HttpService.get(
    `api/v1/${options.feature}/${options.featureId}/files`
  );
}

export function getFilesbycategory(options) {
  return HttpService.get(
    `api/v1/${options.feature}/${options.featureId}/files/${options.category}`
  );
}

export function deleteFile(options) {
  return HttpService.del(
    `api/v1/${options.feature}/${options.featureId}/files/${options.fileId}`
  );
}
