export default {
  methods: {
    navigateTo(page) {
      let _path = "";
      switch (page) {
        case "login":
          _path = "/login";
          break;
        case "home":
          _path = "/";
          break;
        case "settings":
          _path = "/globe-chek/mysettings";
          break;
        case "notificationpreference":
          _path = "/globe-chek/mysettings/notificationpreference";
          break;
        case "Screenings":
          _path = "/globe-chek/Screenings";
          break;
        case "Clients":
          _path = "/globe-chek/clients";
          break;
        case "devices":
          _path = "/globe-chek/devices";
          break;
        case "forgotpwd":
          _path = "/reset-psw";
          break;
        case "Reports":
          _path = "/globe-chek/Reports";
          break;
        case "MasterData":
          _path = "/globe-chek/MasterData";
          break;
      }
      this.$router.push({ path: _path });
    },
    navigateBack() {
      this.$router.back();
    },
    refreshRoute() {
      this.$router.go();
    },
  },
};
