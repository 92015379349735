import { LocationRequest } from "./Location";

import { DeviceLocationRequest } from "./DeviceLocation";
export class OrganisationData {
  clientId = "";
  clientName = "";
  businessName = "";
  businessType = "";
  contactName = "";
  contactEmail = "";
  contactPhone = "";
  domain = "";
}

export class ClientLocationRequest {
  DeviceLocation = new DeviceLocationRequest();
  location = new LocationRequest();
}
