import * as types from "./files.action.types";

export default {
  [types.ADD_UPLOADING_FILE]: (state, res) => {
    state.uploadingFiles.push(res);
  },
  [types.REMOVE_UPLOADING_FILE]: (state, id) => {
    state.uploadingFiles = state.uploadingFiles.filter((x) => x.id != id);
  },
};
