import { mapActions } from "vuex";
import { mapState } from "vuex";
import { MessageRequest, Attachmentrequest } from "../../../models/Message";
import { Success } from "../../../models/SwalMessages";
import { UserSearchParams } from "../../../models/Message";

export default {
  inject: ["app", "showError", "showSuccess", "Permissions"],
  data() {
    return {
      UserSearchForm: new UserSearchParams(),
      progress: {
        isApproval: false,
        isDetailsLoading: false,
        isSaving: false,
        isApprovalProcessing: false,
        isloaded: false,
        isSending: false,
      },
      MessageInput: {
        id: "",
        type: 0,
      },
      Message1: {
        postData: new MessageRequest(),
      },
      AddAttachment: {
        postData: new Attachmentrequest(),
      },
    };
  },
  computed: {
    ...mapState({
      messagestate: (state) => state.messages,
      userClaims: (state) => state.profile.userClaims,
    }),
  },
  methods: {
    ...mapActions("messages", [
      "messageDetails",
      "messageSentDetails",
      "messageThread",
      "messageDetailBymsgID",
      "attachmentsDetails",
      "SendMessagePost",
      "SendAttachmentPost",
      "UpdateStatusPut",
      "DeleteMessage",
      "getUsersForToSearch",
    ]),
    searchUserwithParams(UserSearchForm) {
      var Role;
      if (this.userClaims.includes(this.Permissions.IsSuperAdmin)) {
        Role = "SuperAdmin";
      } else if (this.userClaims.includes(this.Permissions.IsAdmin)) {
        Role = "Admin";
      } else if (this.userClaims.includes(this.Permissions.IsPatient)) {
        Role = "Patient";
      } else if (this.userClaims.includes(this.Permissions.IsDoctor)) {
        Role = "Reader";
      } else if (this.userClaims.includes(this.Permissions.IsTechnician)) {
        Role = "Technician";
      }
      let searchParams = {
        Role: Role,
        searchText: UserSearchForm.searchText,
        OrgID: this.app.userProfile.organisations[0].id,
      };

      this.getUsersForToSearch(searchParams);
    },

    getmessages() {
      this.progress.isDetailsLoading = true;
      this.messageDetails().finally(
        () => (this.progress.isDetailsLoading = false)
      );
    },
    getsentmessages() {
      this.messageSentDetails();
    },
    getmessageByID(msgID, msgType) {
      (this.MessageInput.id = msgID),
        (this.MessageInput.type = msgType),
        (this.progress.isloaded = true);

      this.messageDetailBymsgID(this.MessageInput)
        .then(() => {})
        .finally(() => {
          this.progress.isloaded = false;
        });
    },
    getmessageThreadByID(msgID, type) {
      (this.MessageInput.id = msgID),
        (this.MessageInput.type = type),
        (this.progress.isloaded = true);
      this.messageThread(this.MessageInput)
        .then(() => {})
        .finally(() => {
          this.progress.isloaded = false;
        });
    },
    getattachments(msgID) {
      this.attachmentsDetails(msgID);
    },
    UpdateStatus(msgID) {
      this.UpdateStatusPut(msgID);
    },

    DeleteMessageByID(msgID) {
      this.DeleteMessage(msgID).then((res) => {
        if (res) {
          var success = new Success();
          success.text = "Deleted successfully";
          this.showSuccess(success);
          this.getmessages();
        } else {
          var error = new Error();
          error.text = "Can not delete this Client";
          this.showError(error);
        }
      });
    },
    postmessages() {
      let postData = {
        To: this.Message1.postData.to,
        Body: this.Message1.postData.body,
        Subject: this.Message1.postData.subject,
        ParentMsgID: this.Message1.postData.parentMsgID,
        OriginID: this.Message1.postData.originID,
      };
      this.progress.isSending = true;
      this.SendMessagePost(postData)
        .then((res) => {
          if (res && this.Message1.fileUploadRef)
            this.Message1.fileUploadRef.handleSendNewFiles(res);

          let success = new Success();
          success.title = "Your Message sent successfully";
          success.confirmButtonClass = "md-button md-success";
          this.showSuccess(success);

          this.Message1.postData.composemsg = false;
        })
        .catch((err) => {
          this.progress.isSending = false;
          let error = new Error();
          error.title = "Error";
          error.text = err;
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        })
        .finally(() => {
          this.progress.isSending = false;
          this.Message1.postData.to = "";
          this.Message1.postData.body = "";
          this.Message1.postData.subject = "";
        });
    },
  },
  provide() {
    return {
      message: this.messagestate,
      progress: this.progress,
    };
  },
};
