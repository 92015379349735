<template>
  <md-progress-spinner
    md-mode="indeterminate"
    :md-diameter="diameter || 60"
    :md-stroke="3"
  ></md-progress-spinner>
</template>
<script>
export default {
  props: ["diameter"],
};
</script>
