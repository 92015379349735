import "../../services/common-services/ValidationService";
import { ValidationProvider, ValidationObserver } from "vee-validate";

const ValidationHandler = {
  props: ["rules"],
  template: `<ValidationProvider :rules="rules" v-slot="{ errors }"><slot :error="errors[0]"></slot></ValidationProvider>`,
  components: {
    ValidationProvider,
  },
};

export default ValidationHandler;

export const ValidationListener = {
  props: {
    getIsValid: Function,
  },
  methods: {
    isValid() {
      return this.$refs.obs.validate();
    },
  },
  mounted() {
    this.$emit("getIsValid", this.isValid);
  },
  template: `<ValidationObserver ref="obs"><slot></slot></ValidationObserver>`,
  components: {
    ValidationObserver,
  },
};
