import state from "./organisations.state";
import actions from "./organisations.actions";
import mutations from "./organisations.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
