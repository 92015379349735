import Permissions from "../constants/Permissions";
import { withDashboardHandler } from "../handlers/module-handlers/dashboard/dashboardHandler";
import { withMessageHandler } from "../handlers/module-handlers/message/messageHandler";
import { withPackageHandler } from "../handlers/module-handlers/package/PackageHandler";
import { withReportsHandler } from "../handlers/module-handlers/reports/reportsHandler";
import { withReferralDoctorHandler } from "../handlers/module-handlers/referralDoctor/ReferralDoctorHandler";
import Profile from "../views/profile/profile";
import UserRoute from "../views/users/UserRoute";
import { withMySettingsHandler } from "../handlers/module-handlers/profile/MySettingsHandler";
import ClientRoute from "../views/Client/ClientRoute";
import DeviceRoute from "../views/landing/device/DeviceRoute";
import MasterDataRoute from "../views/landing/MasterData/MasterDataRoute";
import ProvidersRoute from "../views/providers/ProvidersRoute";
import ScreeningsRoute from "../views/Screening/ScreeningsRoute";
import OnholdRoute from "../views/ScreeningsOnhold/OnholdRoute";
import ReportsRoute from "../views/Reports/ReportsRoute";
export default [
  {
    path: "/globe-chek",
    component: () => import("../views/landing/LandingPage.vue"),
    name: "Dashboard",
    redirect: "/globe-chek/dashboard",
    children: [
      {
        path: "dashboard",
        component: withDashboardHandler(() =>
          import("../views/Dashboard/Dashboard.vue")
        ),
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
      },
      {
        path: "mysettings",
        name: "mysettings",
        component: withMySettingsHandler(() =>
          import("../views/profile/MySettings")
        ),
      },

      {
        path: "Messages",
        name: "Messages",
        component: withMessageHandler(() =>
          import("../views/Message/Message.vue")
        ),
      },
      {
        path: "createmsg/:id/:fromid",
        name: "createmsg",
        component: withMessageHandler(() =>
          import("../views/Message/CreateMessage.vue")
        ),
      },
      {
        path: "patientHistory/:patientId",
        name: "patientHistory",

        component: () => import("../views/Dashboard/PatientHistory.vue"),
        meta: { userType: "Patient" },
      },

      {
        path: "/Referrals",
        name: "ReferralDoctorsScreening",

        component: withReferralDoctorHandler(() =>
          import("../views/Dashboard/patientReferralDoctors.vue")
        ),
      },
      {
        path: "/ReportViewer/:id",
        name: "ReportViewer",

        component: withReportsHandler(() =>
          import("../views/Reports/ReportViewer.vue")
        ),
        meta: { title: "Reports" },
      },

      {
        path: "/globe-chek/Packages",
        name: "Packages",
        component: withPackageHandler(() =>
          import("../views/Package/Package.vue")
        ),
      },
      ...ClientRoute,
      ...UserRoute,
      ...DeviceRoute,
      ...MasterDataRoute,
      ...ProvidersRoute,
      ...ScreeningsRoute,
      ...OnholdRoute,
      ...ReportsRoute,
    ],
  },
  {
    path: "/unauthorize",
    component: () => import("../components/UnAuthorized.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/test/TestPage.vue"),
    meta: { permission: Permissions.TestRead },
  },
];
