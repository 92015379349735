export class MessageRequest {
  to = "";
  body = "";
  subject = "";
  parentMsgID = "";
  originID = null;
  composemsg = false;
}

export class Attachmentrequest {
  file = [];
}

export class UserSearchParams {
  searchText = "";
  OrgID = "";
}
