import { mapState } from "vuex";

export default {
  inject: ["app"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      screenings: (state) => state.screenings,
    }),
  },
  provide() {
    return {
      screenings: this.screenings,
    };
  },
};
