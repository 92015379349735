import Vue from "vue";
import appPlugin from "./plugins";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMask from "v-mask";
import { withAppHandler } from "./handlers/module-handlers/AppHandler";
import "vue-loading-overlay/dist/vue-loading.css";
import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.use(appPlugin);
Vue.use(VueMask);

Vue.use(VueMoment, {
  moment,
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(withAppHandler(App)),
  router,
  store,
}).$mount("#app");
