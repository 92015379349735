<template>
  <fragment>
    <slot v-if="hasPermission"></slot>
  </fragment>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["permission", "strict"],
  inject: ["Permissions"],
  data() {
    return {
      hasPermission: false,
    };
  },
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
  },
  created() {
    if (
      this.userClaims.includes(this.permission) ||
      (this.userClaims.includes(this.Permissions.SuperAdmin) && !this.strict)
    )
      this.hasPermission = true;
  },
};
</script>
