import * as api from "../../../api/packagesApi";

export default {
  // eslint-disable-next-line no-unused-vars
  getPackagesCount({ commit }, QueryData) {
    return api.getPackagesCount(QueryData).then((res) => {
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getPackages({ commit }, QueryData) {
    return api.getPackages(QueryData).then((res) => {
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getScreeningsAvailable({ commit }, QueryData) {
    return api.getScreeningsAvailable(QueryData).then((res) => {
      return res;
    });
  },
};
