import * as HttpService from "../services/common-services/HttpService";

export function updateFindings(request) {
  return HttpService.put("api/v1/Findings/" + request.findingsID, request);
}

export function createFindings(request) {
  return HttpService.post("api/v1/Findings", request);
}

export function getFindings(request) {
  return HttpService.getByQuery("api/v1/Findings", request);
}

export function deleteFindings(findingsMasterName, id) {
  return HttpService.del("api/v1/Findings/" + findingsMasterName + "/" + id);
}
