import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  inject: ["app"],
  data() {
    return {
      listFilters: { userType: "" },
    };
  },
  provide() {
    return {
      feature: Features.Package,
    };
  },
  methods: {
    ...mapActions("packages", ["getPackages", "getPackagesCount"]),
  },
};
