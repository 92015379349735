import { mapActions } from "vuex";
import DoctorListService from "../doctor/DoctorListService";
import AdminListService from "../admin/AdminListService";
import TechnicianListService from "../technician/TechnicianListService";
import PasswordEnformentService from "../../common-services/PasswordEnformentService";
import PatientListService from "../patient/PatientListService";
import { PersonStatus } from "../../../constants/Person";
import { Success } from "../../../models/SwalMessages";

export default {
  data() {
    return {
      changePasswordForm: {
        showChangePassword: false,
        processing: false,
      },
    };
  },
  mixins: [
    DoctorListService,
    AdminListService,
    TechnicianListService,
    PatientListService,
    PasswordEnformentService,
  ],
  inject: [
    "showError",
    "showSuccess",
    "list",
    "listInput",
    "refreshList",
    "listPage",
  ],
  methods: {
    ...mapActions("user", [
      "deleteUser",
      "updateUserActive",
      "ChangePassworDirect",
    ]),
    deleteSelectedUser(request) {
      this.list.pages[this.listInput.page].items = this.list.pages[
        this.listInput.page
      ].items.map((x) =>
        x.personEntryID == request.personEntryID
          ? {
              ...x,
              isActionLoading: true,
            }
          : x
      );
      if (
        this.listPage.selectedRole.name != "Doctor" ||
        request.isAccountCreated
      )
        this.deleteUser(request.userID);
      let deleteFunc;
      if (
        this.listPage.module == "doctors" ||
        this.listPage.module == "readers"
      )
        deleteFunc = this.deleteDoctor;
      else if (this.listPage.module == "Admin") {
        deleteFunc = this.deleteAdmin;
      } else if (this.listPage.module == "Technician") {
        deleteFunc = this.deleteTechnician;
      } else if (this.listPage.module == "Patient") {
        deleteFunc = this.deletePatient;
      }

      if (deleteFunc)
        deleteFunc(request.personEntryID)
          .then(() => {
            var success = new Success();
            success.text = "Deleted successfully";
            this.showSuccess(success);
            this.list.pages[this.listInput.page].items = this.list.pages[
              this.listInput.page
            ].items.filter((x) => x.personEntryID != request.personEntryID);
            if (this.list.pages[this.listInput.page].items.length == 0)
              this.refreshList();
          })
          .catch((error) => this.showError(error))
          .finally(
            () =>
              (this.list.pages[this.listInput.page].items = this.list.pages[
                this.listInput.page
              ].items.map((x) =>
                x.personEntryID == request.personEntryID
                  ? {
                      ...x,
                      isActionLoading: false,
                    }
                  : x
              ))
          );
      else
        this.list.pages[this.listInput.page].items = this.list.pages[
          this.listInput.page
        ].items.map((x) =>
          x.personEntryID == request.personEntryID
            ? {
                ...x,
                isActionLoading: false,
              }
            : x
        );
    },
    activeSelectedUser(activeData) {
      this.list.pages[this.listInput.page].items = this.list.pages[
        this.listInput.page
      ].items.map((x) =>
        x.personEntryID == activeData.personEntryID
          ? {
              ...x,
              isActionLoading: true,
            }
          : x
      );
      if (
        this.listPage.selectedRole.name != "Doctor" ||
        activeData.isAccountCreated
      )
        this.updateUserActive({
          id: activeData.userID,
          isActive: activeData.isActive,
        });
      let updateActiveFunc;
      if (
        this.listPage.module == "doctors" ||
        this.listPage.module == "readers"
      )
        updateActiveFunc = this.updateDoctorActive;
      else if (this.listPage.module == "Admin") {
        updateActiveFunc = this.admindeactive;
      } else if (this.listPage.module == "Technician") {
        updateActiveFunc = this.Techniciandeactive;
      } else if (this.listPage.module == "Patient") {
        updateActiveFunc = this.Patientdeactive;
      }
      if (updateActiveFunc) {
        updateActiveFunc({
          id: activeData.personEntryID,
          isActive: activeData.isActive,
        })
          .then(() => {
            this.list.pages[this.listInput.page].items = this.list.pages[
              this.listInput.page
            ].items.map((x) =>
              x.personEntryID == activeData.personEntryID
                ? {
                    ...x,
                    status: activeData.isActive
                      ? PersonStatus.Active
                      : PersonStatus.InActive,
                    statusText: activeData.isActive ? "Active" : "InActive",
                  }
                : x
            );
          })
          .catch((error) => this.showError(error))
          .finally(
            () =>
              (this.list.pages[this.listInput.page].items = this.list.pages[
                this.listInput.page
              ].items.map((x) =>
                x.personEntryID == activeData.personEntryID
                  ? {
                      ...x,
                      isActionLoading: false,
                    }
                  : x
              ))
          );
      } else
        this.list.pages[this.listInput.page].items = this.list.pages[
          this.listInput.page
        ].items.map((x) =>
          x.personEntryID == activeData.personEntryID
            ? {
                ...x,
                isActionLoading: false,
              }
            : x
        );
    },
    processChangePassword() {
      if (
        this.changePasswordForm.confirmPassword ===
        this.changePasswordForm.password
      ) {
        if (
          this.PasswordEnforcementCheck(
            this.changePasswordForm.userEmail,
            this.changePasswordForm.password
          )
        ) {
          let postData = {
            userId: this.changePasswordForm.userId,
            password: this.changePasswordForm.password,
          };
          this.changePasswordForm.processing = true;
          this.ChangePassworDirect(postData)
            .then(() => {
              var success = new Success();
              success.text = "Password changed successfully";
              this.showSuccess(success);
            })
            .catch((error) => this.showError(error))
            .finally(() => {
              this.changePasswordForm.processing = false;
              this.changePasswordForm.showChangePassword = false;
            });
        } else {
          this.changePasswordForm.showChangePassword = false;
          let error = new Error();
          error.title = "Password not matching the requirements";
          error.text =
            "Please enter a password based on the following criteria:Password must be at least 10 characters long and atmost of 50 characters,Password must have at least one alphabet, one numerical, and one special character,Username and password must not be the same";
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        }
      } else {
        this.changePasswordForm.showChangePassword = false;
        let error = new Error();
        error.title = "Password not matching";
        error.text = "Please re-enter your password again";
        error.confirmButtonClass = "md-button md-info";
        this.showError(error);
      }
    },
  },
  provide() {
    return {
      changePasswordForm: this.changePasswordForm,
    };
  },
};
