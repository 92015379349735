import dashboardService from "../../../services/module-services/dashboard/dashboardService";
import ListLayoutHandler from "../../common-handlers/ListLayoutHandler";
import ListHandler from "../../common-handlers/ListHandler";

const DashboardHandler = {
  mixins: [dashboardService],
  provide() {
    return {
      listFilters: [],
      getCounts: this.getDashboardCounts,
      getList: this.getListFunc,
      getListCount: this.getListCountFunc,
    };
  },
  template: `<ListHandler><ListLayoutHandler><slot></slot></ListLayoutHandler></ListHandler>`,
  components: {
    ListHandler,
    ListLayoutHandler,
  },
};

export default DashboardHandler;

export const withDashboardHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<DashboardHandler><WrappedComponent v-bind="$props"></WrappedComponent></DashboardHandler>`,
    components: { DashboardHandler, WrappedComponent },
  };
};
