import * as api from "../../../api/screeningevalapi";
import * as types from "./screeningeval.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  getscreeninginfo({ commit }, screeningID) {
    return api.getScreeningsAvailable(screeningID);
  },

  // eslint-disable-next-line no-unused-vars
  postPrescription({ commit }, postData) {
    return api.postPrescription(postData);
  },

  // eslint-disable-next-line no-unused-vars
  getLookupValues({ commit }) {
    return api.getLookupValues().then((res) => {
      commit(types.GET_LOOKUP_LIST, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  saveScreening({ commit }, postData) {
    return api.postScreeningEvaluation(postData);
  },

  // eslint-disable-next-line no-unused-vars
  updateScreening({ commit }, postData) {
    return api.updateScreeningEvaluation(postData);
  },

  // eslint-disable-next-line no-unused-vars
  updateScreeningEvaluationStatus({ commit }, screeningId) {
    return api.updateScreeningEvaluationStatus(screeningId);
  },

  // eslint-disable-next-line no-unused-vars
  getSavedScreening({ commit }, screeningID) {
    return api.getSavedScreeningInfo(screeningID);
  },

  // eslint-disable-next-line no-unused-vars
  getPrescription({ commit }, screeningID) {
    return api.getPrescription(screeningID);
  },

  // eslint-disable-next-line no-unused-vars
  getScrReferralDoctor({ commit }, screeningID) {
    return api.getScrReferralDoctor(screeningID).then((res) => {
      commit(types.GET_REFERRAL_DOC, res);
      return res;
    });
  },
};
