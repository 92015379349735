import FileViewService from "../../services/common-services/FileViewService";

export default {
  props: ["options"],
  mixins: [FileViewService],
  created() {
    this.files.options = this.options;
    if (this.files.options.featureId) this.loadFiles();
  },
  watch: {
    options() {
      if (
        this.files.options &&
        this.options &&
        this.files.options.featureId != this.options.featureId
      ) {
        this.files.options = this.options;
        this.loadFiles();
      }
    },
  },
  template: `<div><slot :uploadedFiles="files.list"></slot></div>`,
};
