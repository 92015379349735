import Vue from "vue";
import Router from "vue-router";
import { getToken } from "../services/common-services/AuthService";
import appRoute from "./app.route";
import homeRoute from "./home.route";
import { authorizePermission } from "../services/common-services/SharedFunctionService";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [...homeRoute, ...appRoute],
});

router.beforeEach((to, from, next) => {
  const { isPublic, permission } = to.meta;
  if (getToken() == null && !isPublic) {
    return next({ path: "/login", query: { returnUrl: to.path } });
  } else if (getToken() != null && isPublic) {
    return next({
      path: "/globe-chek",
    });
  } else if (permission && !authorizePermission(permission))
    return next({
      path: "/unauthorize",
    });
  next();
});

export default router;
