import * as types from "./reports.action.types";

export default {
  [types.GET_REPORTS]: (state, res) => {
    state.reports = res;
  },
  [types.GET_REPORTS_SORT_LIST]: (state, res) => {
    state.reportsSort = res;
  },
};
