import * as api from "../../../api/doctorApi";
import * as types from "./doctor.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  updateDoctor({ commit }, request) {
    return api.updateDoctor(request);
  },
  // eslint-disable-next-line no-unused-vars
  getDoctor({ commit }, id) {
    return api.getDoctor(id);
  },

  // eslint-disable-next-line no-unused-vars
  updateDoctorApproval({ commit }, request) {
    return api.updateDoctorApproval(request);
  },
  // eslint-disable-next-line no-unused-vars
  updateDoctorActive({ commit }, request) {
    return api.updateDoctorActive(request);
  },
  // eslint-disable-next-line no-unused-vars
  deleteDoctor({ commit }, id) {
    return api.deleteDoctor(id);
  },
  // eslint-disable-next-line no-unused-vars
  RegisterDoctor({ commit }, request) {
    return api.RegisterDoctor(request);
  },
  // eslint-disable-next-line no-unused-vars
  createDoctor({ commit }, request) {
    return api.createDoctor(request);
  },

  // eslint-disable-next-line no-unused-vars
  searchDoctorsWithQueryData({ commit }, request) {
    return api.searchDoctors(request).then((res) => {
      commit(types.SEARCH_DOCTORS, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getNearestDoctors({ commit }, QueryData) {
    return api.getNearestDoctors(QueryData).then((res) => {
      return res.map((x) => ({ ...x, personEntryID: x.id }));
    });
  },
  // eslint-disable-next-line no-unused-vars
  getNearestDoctorsCount({ commit }, QueryData) {
    return api.getNearestDoctorsCount(QueryData);
  },
};
