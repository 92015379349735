import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("files", ["postFiles", "uploadFile", "deleteFile"]),
    async sendFiles(options, files) {
      // Delete previous uploaded file (i.e Profile upload)
      if (options.type == "Single" && options.uploadedFileId)
        await this.deletePreviousUploadedFile({
          ...options,
          fileId: options.uploadedFileId,
        });
      // Get upload links for selected files
      this.postFiles({ options, files }).then((res) => {
        if (options.category == "") res = res.value;
        for (let i = 0; i < res.length; i++)
          files[i] = {
            ...files[i],
            uploadLink: res[i].link,
            id: res[i].id,
            category: options.category,
          };
        // upload selected files to Blob by received links from API
        for (let file of files)
          this.uploadFile({
            file,
          });
      });
    },
    deletePreviousUploadedFile(options) {
      this.deleteFile(options);
    },
    processSelectedFiles(category, files) {
      let uploadFiles = [];
      for (let file of files)
        uploadFiles.push({
          link: URL.createObjectURL(file),
          name: file.name,
          contentType: file.type,
          description: "",
          category,
        });
      return uploadFiles;
    },
  },
};
