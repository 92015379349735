import { Subject } from "rxjs";

const submit = new Subject();

const SnackbarService = {
  data() {
    return {
      snackbar: {
        title: "",
        description: "",
        showSuccess: false,
        showError: false,
        showWarning: false,
        showInformation: false,
        hasGoback: false,
        isSubscribed: false,
      },
    };
  },
  methods: {
    showSuccess(success) {
      this.snackbar.title = "SUCCESS";
      this.snackbar.description = success.text;
      this.snackbar.showSuccess = true;
    },
    showError(error) {
      this.snackbar.title =
        typeof error.title === "undefined" ? "Failed" : error.title;
      this.snackbar.description = error.text;
      this.snackbar.showError = true;
    },
    showSuccessMsg(msg) {
      this.showSuccess({ text: msg });
    },
    showErrorMsg(msg) {
      this.showError({ text: msg });
    },
    showInformation(information) {
      console.log(this.snackbar.showInformation);
      this.snackbar.title = information.title;
      this.snackbar.description = information.description;
      this.snackbar.showInformation = true;
      console.log(this.snackbar.showInformation);
    },
    showWarning(warning) {
      this.snackbar.title = warning.title;
      this.snackbar.description = warning.text;
      this.snackbar.showWarning = true;
      this.snackbar.hasGoback = warning.hasGoback;
    },
  },
  provide: function () {
    return {
      showSuccess: this.showSuccess,
      showError: this.showError,
      showSuccessMsg: this.showSuccessMsg,
      showErrorMsg: this.showErrorMsg,
      snackbar: this.snackbar,
      showWarning: this.showWarning,
      showInformation: this.showInformation,
    };
  },
};

export default SnackbarService;

export const getSubmit = () => submit.asObservable();
export const onSubmit = () => submit.next();
