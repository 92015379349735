import * as types from "./Client.action.types";

export default {
  [types.GET_DEVICE_LIST]: (state, res) => {
    if (res.length == 0) {
      state.deviceList = [
        {
          description: "No devices available",
          deviceType: null,
          id: "",
          name: "No devices available",
          serialNo: "No devices available",
          status: "",
        },
      ];
    } else {
      state.deviceList = res;
    }
  },
  [types.GET_CURRENT_ORG]: (state, res) => {
    state.currentOrg = res;
  },
};
