import ClientListService from "../../../services/module-services/Client/ClientListService";
import ListLayoutService from "../../../services/layout-services/ListLayoutService";
import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";
import MasterService from "../../../services/common-services/MasterService";

const ClientListHandler = {
  inject: ["confirmation"],
  mixins: [ClientListService, ListLayoutService, MasterService],
  methods: {
    deleteOrganisation(id) {
      this.confirmation.title = "Delete Client ?";
      this.confirmation.description =
        "Are you sure you want to delete this Client ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.delete(id);
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
  },
  provide() {
    return {
      deleteOrganisation: this.deleteOrganisation,
    };
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  created() {
    this.loadMasterList(["businessType"]);
  },
  template: `<div><slot></slot></div>`,
};

export default ClientListHandler;

export const withClientListHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ClientListHandler><WrappedComponent v-bind="$props"></WrappedComponent></ClientListHandler>`,
    components: { ClientListHandler, WrappedComponent },
  };
};
