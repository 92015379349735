import actions from "./dashboard.actions";
import mutations from "./dashboard.mutations";
import state from "./dashboard.state";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
