import state from "./reports.state";
import actions from "./reports.actions";
import mutations from "./reports.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
