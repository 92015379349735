export default {
  Users: "Users",
  Providers: "Providers",
  Doctors: "Doctors",
  Admin: "Admin",
  Technician: "Technician",
  Patient: "Patient",
  GlobeActivity: "Globe Activity",
  ScreeningsAvailable: "Available Screenings",
  Package: "PACKAGE INJEST MONITOR",
  ScreeningsOnhold: "Screenings On-hold",
  Devices: "Globechek Devices",
  Messages: "Messages",
  Clients: "Clients",
  Reports: "Reports",
  ReferralDoctor: "Referral Doctor",
};
