import * as api from "../../../api/referraldoctorApi";

export default {
  // eslint-disable-next-line no-unused-vars
  getReferralDoctor({ commit }) {
    return api.getReferralDoctor();
  },
  // eslint-disable-next-line no-unused-vars
  referralDoctorsCount({ commit }) {
    return api.getReferralDoctorsCount();
  },
};
