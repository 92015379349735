<template>
  <div>
    <md-list>
      <md-list-item
        v-for="item in items"
        v-bind:key="item.id"
        @click="onClick(item)"
      >
        {{ item.name }}
      </md-list-item>
    </md-list>
  </div>
</template>
<script>
export default {
  name: "ListBox",
  props: {
    items: [Array],
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onClick(item) {
      if (item) {
        this.$emit("select", item);
      }
    },
  },
};
</script>
