export default {
  inject: ["listFilters", "getList", "getListCount", "Permissions"],
  data() {
    return {
      list: {
        pages: [],
      },
      listDetails: {
        perPageOptions: [5, 10, 25, 50],
        count: 0,
      },
      listInput: {
        searchText: "",
        page: 1,
        size: 10,
        filterText: "",
        sortText: "",
        filter: "",
        sortOrder: "desc",
      },
    };
  },
  methods: {
    refreshListInput() {
      this.list.pages = [];
    },
    refreshList() {
      this.listInput.page = 1;
      this.refreshListInput();
      this.loadListCount();
      this.loadList();
    },
    reloadList() {
      this.list.pages[this.listInput.page] = {};
      this.loadList();
    },
    getSearchQuery() {
      return {
        searchText: this.listInput.searchText,
        filterText: this.listInput.filterText,
        sortText: this.listInput.sortText,
        sortOrder: this.listInput.sortOrder,
        filter: this.listInput.filter,
        ...this.listFilters,
      };
    },
    loadListCount() {
      if (!this.list.isQueryable && this.getListCount != null)
        this.getListCount({ ...this.getSearchQuery() }).then(
          (res) => (this.listDetails.count = res)
        );
    },
    loadList(page) {
      if (this.getList == null) return;
      if (!page) page = this.listInput.page;
      if (!this.list.pages[page]) this.list.pages[page] = {};
      if (!this.list.pages[page].items) {
        this.list.pages[page].isLoading = true;
        this.getList({
          ...this.getSearchQuery(),
          page,
          size: this.listInput.size,
        })
          .then((res) => {
            let responseList;
            if (this.list.isQueryable) {
              responseList = res.data;
              this.listDetails.count = res.totalRecords;
            } else responseList = res;
            this.list.pages = this.list.pages.map((x, i) =>
              i == page ? { ...x, items: responseList } : x
            );
          })
          .finally(() => (this.list.pages[page].isLoading = false));
      }
    },
  },
  provide() {
    return {
      list: this.list,
      listInput: this.listInput,
      listDetails: this.listDetails,
    };
  },
};
