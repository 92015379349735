import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default {
  methods: {
    getappInsights() {
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.VUE_APP_INSTRUMENTATION_KEY,
        },
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    },
  },
};
