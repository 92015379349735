import { withDeviceModuleHandler } from "../../../handlers/module-handlers/device/DeviceModuleHandler";
import { withDeviceListHandler } from "../../../handlers/module-handlers/device/DeviceListHandler";
export default [
  {
    path: "devices",
    name: "devices",
    component: withDeviceModuleHandler(() => import("./DeviceLanding.vue")),
    redirect: "/globe-chek/devices",
    children: [
      {
        path: "",
        name: "device-list",
        component: withDeviceListHandler(() =>
          import("./device-list/DeviceListPage.vue")
        ),
      },
    ],
  },
];
