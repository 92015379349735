import * as types from "./device.action.types";

export default {
  [types.SEARCH_DEVICES]: (state, res) => {
    state.searchResults = [];
    state.searchResults = res.data.map((re) => {
      return re;
    });
  },
  [types.GET_DEVICE_BYID]: (state, res) => {
    state.device = res;
  },
  [types.UPDATE_DEVICE]: (state, res) => {
    if (res) state.deviceUpdated = true;
  },
  [types.DELETE_DEVICE]: (state, res) => {
    if (res) state.deviceDeleted = true;
  },
};
