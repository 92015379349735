import ListLayoutService from "../../../services/layout-services/ListLayoutService";
import ManageDeviceService from "../../../services/module-services/device/ManageDeviceService";
import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";
const DeviceListHandler = {
  inject: ["confirmation", "feature"],
  mixins: [ListLayoutService, ManageDeviceService],
  data() {
    return {};
  },
  watch: {},
  methods: {
    handleDeleteClick(selectedItem) {
      this.confirmation.title = "Delete Device ?";
      this.confirmation.description =
        "Are you sure you want to delete this Device ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.deleteSelectedDevice(selectedItem.id);
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
  },
  template: "<div><slot></slot></div>",
  provide() {
    return {
      handleDeleteClick: this.handleDeleteClick,
      handleEditClick: this.handleEditClick,
    };
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
};

export default DeviceListHandler;

export const withDeviceListHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<DeviceListHandler><WrappedComponent v-bind="$props"></WrappedComponent></DeviceListHandler>`,
    components: { DeviceListHandler, WrappedComponent },
  };
};
