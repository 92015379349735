import ResetPasswordService from "../../../services/module-services/user/ResetPasswordService";
import NavigationService from "../../../services/common-services/NavigationService";
const ResetPasswordConfirmHandler = {
  mixins: [ResetPasswordService, NavigationService],
  methods: {
    async ResetPasswordConfirm() {
      if (!(await this.passwordReset.isFormValid())) return;
      this.UserResetPasswordConfirm();
    },
    navigateToLoginPage() {
      this.navigateTo("login");
    },
  },
  template: `<div><slot></slot></div>`,
  mounted() {
    this.SetPasswordResetToken();
    this.SetPasswordResetEmail();
  },
  provide() {
    return {
      passwordReset: this.passwordReset,
      passwordResetConfirm: this.ResetPasswordConfirm,
      cancelReset: this.navigateToLoginPage,
    };
  },
};

export default ResetPasswordConfirmHandler;

export const withResetPasswordConfirmHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ResetPasswordConfirmHandler><WrappedComponent v-bind="$props"></WrappedComponent></ResetPasswordConfirmHandler>`,
    components: { ResetPasswordConfirmHandler, WrappedComponent },
  };
};
