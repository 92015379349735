import MasterService from "../../../services/common-services/MasterService";
import ClientManageService from "../../../services/module-services/Client/ClientManageService";
import { ClientLocationRequest } from "../../../models/Client";
import NavigationBlocker from "../../../components/NavigationBlocker";

import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";

const ClientManageHandler = {
  mixins: [ClientManageService, MasterService],
  inject: ["confirmation", "navigationBlocker"],
  created() {
    this.loadMasterList([
      "stateList",
      "countryCodeList",
      "deviceStatus",
      "regionList",
      "businessType",
    ]);
    this.navigationBlocker.val = true;
  },
  methods: {
    async handleSave() {
      this.navigationBlocker.val = true;
      if (await this.orgForm.isDataValid()) this.saveOrganisation();
    },
    submit(DeviceLocation) {
      this.submitLocation();
      console.log(DeviceLocation);
    },
    navigateToBack() {
      this.$router.back();
    },
    deleteDeviceLocation(item) {
      this.confirmation.title = "Delete Location ?";
      this.confirmation.description =
        "Are you sure you want to delete this Location ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.deleteLocation(item);
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
    handleCancelClick() {
      this.manageClient.showManageClientLocation = false;
      this.DeviceLocation.postData = new ClientLocationRequest();
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  provide() {
    return {
      handleSave: this.handleSave,
      handleLocationSave: this.saveLocation,
      handleUpdateDeviceLocation: this.editDeviceLocation,
      handleDeleteDeviceLocation: this.deleteDeviceLocation,
      handleCancelClick: this.handleCancelClick,
    };
  },
  template: "<div><slot></slot></div>",
};

export default ClientManageHandler;

export const withClientManageHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    mixins: [NavigationBlocker],
    template: `<ClientManageHandler><WrappedComponent v-bind="$props"></WrappedComponent></ClientManageHandler>`,
    components: { ClientManageHandler, WrappedComponent },
  };
};
