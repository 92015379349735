export class PersonDetails {
  id = "";
  firstName = "";
  lastName = "";
  title = "";
  email = "";
  cellNumber = "";
  allowSMS = false;
  gender = "";
  DOB = null;
  comments = "";
  languages = [];
}

export class Notifications {
  allowEmailNotification = "0";
  allowPopUpNotification = "0";
}
