<template>
  <div v-if="app.authValidated || !app.isLoggedIn">
    <Appinsight />
    <confirmation />
    <snackbar-error-confirmation />
    <snackbar-warning-confirmation />
    <snackbar-success-confirmation />
    <snackbar-information-confirmation />
    <image-preview-modal
      :show="previewImage.showImagePreview"
      :image="previewImage.image"
      @onClose="handleCloseImagePreview"
    />
    <!-- Enable below for app level loader/spinner   -->
    <!-- <loading :active="app.showLoader.value" :can-cancel="true"></loading> -->
    <loading :active="app.isLoading" :can-cancel="true"></loading>
    <router-view />
  </div>
  <Authenticating v-else />
</template>

<script>
import Authenticating from "./components/Authenticating.vue";
import Confirmation from "./components/controls/Confirmation.vue";
import SnackbarErrorConfirmation from "./components/controls/SnackbarErrorConfirmation.vue";
import SnackbarSuccessConfirmation from "./components/controls/SnackbarSuccessConfirmation.vue";
import Appinsight from "./components/Globechek/Appinsight.vue";
import SnackbarWarningConfirmation from "./components/controls/SnackbarWarningConfirmation.vue";
import SnackbarInformationConfirmation from "./components/controls/SnackbarInformationConfirmation.vue";
import ImagePreviewModal from "./components/controls/ImagePreviewModal.vue";
export default {
  components: {
    Authenticating,
    Confirmation,
    SnackbarErrorConfirmation,
    Appinsight,
    SnackbarWarningConfirmation,
    SnackbarSuccessConfirmation,
    SnackbarInformationConfirmation,
    ImagePreviewModal,
  },
  inject: ["app", "previewImage", "handleCloseImagePreview"],
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
