import * as api from "../../../api/messageApi";
import * as types from "./messages.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  messageDetails({ commit }) {
    return api.getMessages().then((res) => {
      commit(types.GET_MESSAGES, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  messageSentDetails({ commit }) {
    return api.getSentMessages().then((res) => {
      commit(types.GET_SENT_MESSAGES, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  messageDetailBymsgID({ commit }, msg) {
    return api.getMessageByID(msg.id, msg.type).then((res) => {
      commit(types.GET_SELECTEDMSG, res);
      return res;
    });
  },
  messageThread({ commit }, msg) {
    return api.getThread(msg.id, msg.type).then((res) => {
      commit(types.GET_THREAD, res);
      return res;
    });
  },
  attachmentsDetails({ commit }, msgID) {
    return api.getAttachments(msgID).then((res) => {
      commit(types.GET_ATTACHMENTS, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  SendMessagePost({ commit }, postData) {
    return api.SendMessagePost(postData);
  },
  // eslint-disable-next-line no-unused-vars
  SendAttachmentPost({ commit }, { featureID, postData }) {
    return api.SendAttachmentPost(featureID, postData);
  },

  // eslint-disable-next-line no-unused-vars
  UpdateStatusPut({ commit }, messageID) {
    return api.UpdateStatusPut(messageID);
  },

  // eslint-disable-next-line no-unused-vars
  DeleteMessage({ commit }, messageID) {
    return api.DeleteMessage(messageID);
  },

  // eslint-disable-next-line no-unused-vars
  getUsersForToSearch({ commit }, QueryData) {
    return api.getUsersForToSearch(QueryData).then((res) => {
      commit(types.GET_TO_USERS, res);
      return res;
    });
  },
};
