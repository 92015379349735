import state from "./screenings.state";
import actions from "./screenings.actions";
import mutations from "./screenings.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
