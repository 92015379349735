export const GET_ROLE_LIST = "GET_ROLES";

export const GET_STATE_LIST = "GET_STATES";

export const GET_ADDRESSTYPE_LIST = "GET_ADDRESSTYPES";

export const GET_COUNTRYCODE_LIST = "GET_COUNTRYCODES";

export const GET_SCREENINGTYPE_LIST = "GET_SCREENINGTYPES";

export const GET_EYEORIENTATION_LIST = "GET_EYEORIENTATION";

export const GET_MONTH_LIST = "GET_MONTHS";

export const GET_SPECIALIZATION_LIST = "GET_SPECIALIZATIONS";

export const GET_SUBSPECIALIZATION_LIST = "GET_SUBSPECIALIZATION";

export const GET_SPECIALITIES_LIST = "GET_SPECIALITIES";

export const GET_LANGUAGES_LIST = "GET_LANGUAGES";

export const GET_INSURANCE_LIST = "GET_INSURANCE";

export const GET_QUALIFICATION_LIST = "GET_QUALIFICATION";

export const GET_REGION_LIST = "GET_REGION_LIST";

export const GET_ORGANISATION_LIST = "GET_ORGANISATION_LIST";
export const GET_SPHEREPOWER_LIST = "GET_SPHEREPOWER";

export const GET_CYLINDICALPOWER_LIST = "GET_CYLINDICALPOWER";

export const GET_AXISPOWER_LIST = "GET_AXISPOWER";

export const GET_ADDITIONALPOWER_LIST = "GET_ADDITIONALPOWER";

export const GET_DISTANCES_LIST = "GET_DISTANCES";

export const GET_MESSAGE_TYPE = "GET_MESSAGE_TYPE";

export const GET_DEVICE_STATUS = "GET_DEVICE_STATUS";

export const GET_BUSINESS_TYPE = "GET_BUSINESS_TYPE";

export const GET_SURGERY = "GET_SURGERY";

export const GET_DIABETES = "GET_DIABETES";

export const GET_CANCER = "GET_CANCER";

export const CLEAR_REGION_LIST = "CLEAR_REGION_LIST";

export const CLEAR_STATE_LIST = "CLEAR_STATE_LIST";

export const CLEAR_COUNTRYCODE_LIST = "CLEAR_COUNTRYCODE_LIST";

export const CLEAR_SPECIALIZATIONS_LIST = "CLEAR_SPECIALIZATIONS_LIST";

export const CLEAR_SUBSPECIALIZATIONS_LIST = "CLEAR_SUBSPECIALIZATIONS_LIST";

export const CLEAR_SPECIALITY_LIST = "CLEAR_SPECIALITY_LIST";

export const CLEAR_LANGUAGES_LIST = "CLEAR_LANGUAGES_LIST";

export const CLEAR_INSURANCE_LIST = "CLEAR_INSURANCE_LIST";

//export const CLEAR_SPHEREPOWER_LIST = "CLEAR_SPHEREPOWER_LIST";

export const CLEAR_CYLINDRERPOWER_LIST = "CLEAR_CYLINDRERPOWER_LIST";

export const CLEAR_AXIS_LIST = "CLEAR_AXIS_LIST";

export const CLEAR_ADDITIONALPOWER_LIST = "CLEAR_ADDITIONALPOWER_LIST";

export const CLEAR_DISTANCE_LIST = "CLEAR_DISTANCE_LIST";

//export const CLEAR_BUSINESSTYPE_LIST = "CLEAR_BUSINESSTYPE_LIST";

export const CLEAR_SURGERY_LIST = "CLEAR_SURGERY_LIST";

export const CLEAR_DIABETS_LIST = "CLEAR_DIABETS_LIST";

export const CLEAR_CANCER_LIST = "CLEAR_CANCER_LIST";
