import { mapActions } from "vuex";
import { Success, Error } from "../../../models/SwalMessages";
import { DeviceRequest } from "../../../models/Device";

export default {
  inject: [
    "showError",
    "showSuccess",
    "refreshList",
    "reloadList",
    "closeDialog",
  ],
  data() {
    return {
      deviceForm: {
        postData: new DeviceRequest(),
      },
      manageDevice: {
        isSaving: false,
      },
      devices: [],
    };
  },
  methods: {
    ...mapActions("device", [
      "getDeviceById",
      "registerDevice",
      "updateDevice",
      "deleteDevice",
    ]),
    ...mapActions("Client", ["getDeviceList"]),
    loadDevice() {
      this.getDeviceById(this.deviceForm.postData.id).then((res) => {
        this.deviceForm.postData.name = res.name;
        this.deviceForm.postData.serialNo = res.serialNo;
        this.deviceForm.postData.description = res.description;
        this.deviceForm.postData.status = res.status;
      });
    },
    checkName(item) {
      let value = false;
      if (
        item.name.toLowerCase() === this.deviceForm.postData.name.toLowerCase()
      ) {
        value = true;
      } else if (item.name == this.deviceForm.postData.name) {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
    saveDevice(onSuccess) {
      this.manageDevice.isSaving = true;
      let postData = JSON.parse(JSON.stringify(this.deviceForm.postData));
      if (postData.id) {
        this.updateDevice(postData)
          .then(() => {
            var success = new Success();
            success.text = "Device updated successfully";
            this.showSuccess(success);
            this.refreshList();
            if (onSuccess) onSuccess();
            this.closeDialog();
          })
          .catch((err) => {
            var error = new Error();
            error.text = err;
            this.showError(error);
            this.closeDialog();
          })
          .finally(() => (this.manageDevice.isSaving = false));
      } else {
        if (this.devices.find(this.checkName)) {
          this.closeDialog();
          var error = new Error();
          error.text = "Device name already exists";
          this.showError(error);
        } else {
          this.registerDevice(postData)
            .then(() => {
              var success = new Success();
              success.text = "Device created successfully";
              this.showSuccess(success);
              this.refreshList();
              if (onSuccess) onSuccess();
              this.closeDialog();
            })
            .catch((err) => {
              var error = new Error();
              error.text = err;
              this.showError(error);
              this.closeDialog();
            })
            .finally(() => (this.manageDevice.isSaving = false));
        }
      }
    },

    deleteSelectedDevice(id) {
      this.deleteDevice(id)
        .then(() => {
          this.refreshList();
          var success = new Success();
          success.text = "Device deleted successfully";
          this.showSuccess(success);
        })
        .catch((error) => {
          var errorMsg = new Error();
          error.text = error;
          this.showError(errorMsg);
        });
    },
  },
  created() {
    this.getDeviceList().then((res) => {
      this.devices = res;
    });
  },
  provide() {
    return {
      deviceForm: this.deviceForm,
      manageDevice: this.manageDevice,
    };
  },
};
