import * as api from "../../../api/organisationApi";
import * as types from "./organisations.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  searchOrganisationsWithQueryData({ commit }, request) {
    return api.searchOrganisations(request).then((res) => {
      commit(types.SEARCH_ORGANISATIONS, res);
      return res;
    });
  },
};
