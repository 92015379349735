import { AddressDetails } from "./Address";
import { PersonDetails } from "./Person";

export class PatientRequest {
  id = "";
  wearCorrectiveLense = "";
  height = "";
  userType = "";
  insurance = "";
  history = new PatientHistoryDetails();
  familyHistory = new PatientFamilyHistoryDetails();
  address = new AddressDetails();
  person = new PersonDetails();
  height1 = "";
  height2 = "";
  organisations = [];
  alternateemail = "";
  PrimaryInsuranceCarrrier = "";
  PrimaryInsuranceContarct = "";
  PrimaryInsuranceGroup = "";
  SecondaryInsuranceGroup = "";
  SecondaryInsuranceCarrrier = "";
  SecondaryInsuranceContarct = "";
}

export class CorrectiveLenseOption {
  name = "";
  value = "";
}

export class PatientHistoryDetails {
  id = null;
  patientID = null;
  surgery = "";
  cancer = "";
  diabeties = "";
  others = "";
  cholestrol = "false";
  heart = "false";
  liver = "false";
  migranes = "false";
  pressure = "false";
  stroke = "false";
}

export class PatientFamilyHistoryDetails {
  id = null;
  patientID = null;
  alzheimers = "false";
  cancer = "false";
  cholesterol = "false";
  diabetes = "false";
  glaucoma = "false";
  heart = "false";
  liver = "false";
  macDegen = "false";
  migraines = "false";
  pressure = "false";
  stroke = "false";
  other = "";
}
