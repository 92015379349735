import { mapActions } from "vuex";
import Features from "../../../constants/Features";
import { mapState } from "vuex";
export default {
  inject: ["app", "Permissions", "msgnocount"],
  data() {
    return {
      dashboardCount: {
        messages: 0,
        screeningsAvailable: 0,
        screeningsOnhold: 0,
        reports: 0,
        referralDoctorsCount: 0,
        isReportsLoading: false,
        isScreeningsOnholdLoading: false,
        isScreeningsAvailableLoading: false,
        isMessagesLoading: false,
        isReferralDoctorsCountLoading: false,
      },
      userId: this.app.userProfile.id,
    };
  },
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
  },
  methods: {
    ...mapActions("dashboard", [
      "allScreeningsCount",
      "allOnholdScreeningsCount",
      "screeningsOnholdCountByReaderId",
      "globeActivity",
      "referralDoctorsCount",
      "getGlobeActivityCount",
      "getMessagesCount",
      "reportsCount",
    ]),
    //...mapActions("reports", ["getReportsCount"]),
    getDashboardCounts() {
      if (this.userClaims.includes(this.Permissions.IsSuperAdmin)) {
        this.dashboardCount.isMessagesLoading = true;
        this.getMessagesCount(this.userId)
          .then((response) => {
            this.dashboardCount.messages = response;
            this.msgnocount.value = response;
          })
          .finally(() => (this.dashboardCount.isMessagesLoading = false));
        this.dashboardCount.isScreeningsAvailableLoading = true;
        this.allScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsAvailable = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsAvailableLoading = false)
          );
        this.dashboardCount.isScreeningsOnholdLoading = true;
        this.allOnholdScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsOnhold = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsOnholdLoading = false)
          );
        this.dashboardCount.isReportsLoading = true;
        this.reportsCount()
          .then((response) => {
            this.dashboardCount.reports = response;
          })
          .finally(() => (this.dashboardCount.isReportsLoading = false));
      } else if (this.userClaims.includes(this.Permissions.IsAdmin)) {
        this.dashboardCount.isMessagesLoading = true;
        this.getMessagesCount(this.userId)
          .then((response) => {
            this.dashboardCount.messages = response;
            this.msgnocount.value = response;
          })
          .finally(() => (this.dashboardCount.isMessagesLoading = false));
        this.dashboardCount.isScreeningsAvailableLoading = true;
        this.allScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsAvailable = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsAvailableLoading = false)
          );
        this.dashboardCount.isScreeningsOnholdLoading = true;
        this.allOnholdScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsOnhold = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsOnholdLoading = false)
          );
        this.dashboardCount.isReportsLoading = true;
        this.reportsCount()
          .then((response) => {
            this.dashboardCount.reports = response;
          })
          .finally(() => (this.dashboardCount.isReportsLoading = false));
      } else if (this.userClaims.includes(this.Permissions.IsTechnician)) {
        this.dashboardCount.isMessagesLoading = true;
        this.getMessagesCount(this.userId)
          .then((response) => {
            this.dashboardCount.messages = response;
            this.msgnocount.value = response;
          })
          .finally(() => (this.dashboardCount.isMessagesLoading = false));
        this.dashboardCount.isScreeningsAvailableLoading = true;
        this.allScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsAvailable = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsAvailableLoading = false)
          );
      } else if (this.userClaims.includes(this.Permissions.IsDoctor)) {
        this.dashboardCount.isMessagesLoading = true;
        this.getMessagesCount(this.userId)
          .then((response) => {
            this.dashboardCount.messages = response;
            this.msgnocount.value = response;
          })
          .finally(() => (this.dashboardCount.isMessagesLoading = false));
        this.dashboardCount.isScreeningsAvailableLoading = true;
        this.allScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsAvailable = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsAvailableLoading = false)
          );
        this.dashboardCount.isScreeningsOnholdLoading = true;
        this.allOnholdScreeningsCount()
          .then((response) => {
            this.dashboardCount.screeningsOnhold = response;
          })
          .finally(
            () => (this.dashboardCount.isScreeningsOnholdLoading = false)
          );
        this.dashboardCount.isReportsLoading = true;
        this.reportsCount(this.userId)
          .then((response) => {
            this.dashboardCount.reports = response;
          })
          .finally(() => (this.dashboardCount.isReportsLoading = false));
      } else if (this.userClaims.includes(this.Permissions.IsPatient)) {
        this.dashboardCount.isMessagesLoading = true;
        this.getMessagesCount(this.userId)
          .then((response) => {
            this.dashboardCount.messages = response;
            this.msgnocount.value = response;
          })
          .finally(() => (this.dashboardCount.isMessagesLoading = false));
        this.dashboardCount.isReportsLoading = true;
        this.reportsCount(this.userId)
          .then((response) => {
            this.dashboardCount.reports = response;
          })
          .finally(() => (this.dashboardCount.isReportsLoading = false));
        this.dashboardCount.isReferralDoctorsCountLoading = true;
        this.referralDoctorsCount()
          .then((response) => {
            this.dashboardCount.referralDoctorsCount = response.length;
          })
          .finally(
            () => (this.dashboardCount.isReferralDoctorsCountLoading = false)
          );
      }
    },
    getListFunc(props) {
      if (!this.userClaims.includes(this.Permissions.IsPatient))
        return this.globeActivity(props);
    },
    getListCountFunc(props) {
      if (!this.userClaims.includes(this.Permissions.IsPatient))
        return this.getGlobeActivityCount(props);
    },
    patientActivity() {
      return new Promise((resolve) => {
        resolve({});
      });
    },
  },
  provide() {
    return {
      dashboardCount: this.dashboardCount,
      feature: Features.GlobeActivity,
      userClaims: this.userClaims,
    };
  },
};
