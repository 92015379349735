import { mapActions, mapState } from "vuex";
import { UserRegstartionRequest } from "../../../models/User";

export default {
  inject: [
    "showSuccessMsg",
    "showErrorMsg",
    "refreshList",
    "checkPermission",
    "Permissions",
    "navigationBlocker",
  ],
  data() {
    return {
      UserForm: { postData: new UserRegstartionRequest() },
      progress: {
        isApproval: false,
        isDetailsLoading: false,
        isSaving: false,
        isApprovalProcessing: false,
      },
      manageTechnician: {},
    };
  },
  computed: {
    ...mapState({
      currentOrg: (state) => state.Client.currentOrg,
    }),
  },
  created() {
    this.navigationBlocker.val = true;
  },
  methods: {
    ...mapActions("technician", [
      "RegisterTechnician",
      "createTechnician",
      "updateTechnicianApproval",
      "updateTechnician",
      "getTechnician",
      "deleteTechnician",
      "Techniciandeactive",
    ]),
    ...mapActions("user", [
      "addUser",
      "updateUserAccountCreated",
      "updateUser",
    ]),
    saveTechnician() {
      this.navigationBlocker.val = true;
      this.progress.isSaving = true;
      if (
        this.manageTechnician.page == "Register" ||
        !this.checkPermission(this.Permissions.SuperAdmin)
      )
        this.UserForm.postData.orgID =
          sessionStorage.getItem("Org_Id") != null
            ? sessionStorage.getItem("Org_Id")
            : this.currentOrg.clientID;
      this.UserForm.postData.userType = this.manageTechnician.role.name;
      this.UserForm.postData.person.languages = null;
      let postData = JSON.parse(JSON.stringify(this.UserForm.postData));
      if (postData.id) {
        if (this.manageTechnician.page == "Approval")
          this.updateTechnicianApproval(postData)
            .then(() => {
              if (this.UserForm.postData.isApproved)
                this.createUser("Approved successfully");
              else {
                this.refreshList();
                this.showSuccessMsg("Rejected successfully");
                this.$router.back();
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
        else
          this.updateTechnician(postData)
            .then(() => {
              this.updateUserDetails()
                .then(() => {
                  this.refreshList();
                  this.showSuccessMsg(
                    "Technician Details Updated successfully"
                  );
                  this.$router.back();
                })
                .catch((err) => {
                  this.showErrorMsg(err);
                })
                .finally(() => (this.progress.isSaving = false));
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
      } else {
        if (this.manageTechnician.page == "Register")
          this.RegisterTechnician(postData)
            .then((res) => {
              if (res.userID) {
                this.UserForm.fileUploadRef.handleSendNewFiles(res.userID);
                this.$router.push(
                  "/register/success/" + this.manageTechnician.role.name
                );
              } else this.showErrorMsg("Something went wrong");
            })
            .catch((err) => {
              this.showErrorMsg(err);
            })
            .finally(() => (this.progress.isSaving = false));
        else {
          this.createTechnician(postData)
            .then((res) => {
              if (res.userID) {
                this.UserForm.fileUploadRef.handleSendNewFiles(res.userID);
                this.UserForm.postData.userID = res.userID;
                this.createUser("Technician Created Successfully");
              } else {
                this.showErrorMsg("Something went wrong");
                this.progress.isSaving = false;
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
        }
      }
    },
    createUser(successMsg) {
      let userPostData = {
        id: this.UserForm.postData.userID,
        firstName: this.UserForm.postData.person.firstName,
        lastName: this.UserForm.postData.person.lastName,
        email: this.UserForm.postData.person.email,
        phone: this.UserForm.postData.person.cellNumber,
        role: this.manageTechnician.role.id,
        organisations: [this.UserForm.postData.orgID],
        password: this.manageTechnician.registeredPassword,
      };
      this.addUser(userPostData)
        .then(() => {
          this.updateUserAccountCreated(userPostData.id)
            .then(() => {
              this.refreshList();
              this.showSuccessMsg(successMsg);
              this.$router.back();
            })
            .catch((err) => {
              this.showErrorMsg(err);
            })
            .finally(() => (this.progress.isSaving = false));
        })
        .catch((err) => {
          this.showErrorMsg(err);
          this.progress.isSaving = false;
        });
    },
    updateUserDetails() {
      let userPostData = {
        id: this.UserForm.postData.userID,
        firstName: this.UserForm.postData.person.firstName,
        lastName: this.UserForm.postData.person.lastName,
        email: this.UserForm.postData.person.email,
        phone: this.UserForm.postData.cellNumber,
        role: this.manageTechnician.role.id,
        organisations: [this.UserForm.postData.orgID],
      };
      return this.updateUser(userPostData);
    },
    loadTechnician() {
      this.progress.isDetailsLoading = true;
      this.getTechnician(this.UserForm.postData.id)
        .then((res) => {
          this.UserForm.postData = JSON.parse(JSON.stringify(res));
          this.manageTechnician.userType = this.UserForm.postData.userType;
          this.manageTechnician.registeredPassword = res.registeredPassword;
          let selecetedRole = this.master.roleList.find(
            (x) =>
              String(x.name).toLowerCase() ==
              String(this.UserForm.postData.userType).toLowerCase()
          );
          if (!selecetedRole) this.$router.back();
          else this.manageTechnician.role = selecetedRole;
        })
        .finally(() => (this.progress.isDetailsLoading = false));
    },
  },
  provide() {
    return {
      UserForm: this.UserForm,
      manageTechnician: this.manageTechnician,
      progress: this.progress,
    };
  },
};
