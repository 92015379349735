import MasterService from "../../../services/common-services/MasterService";

const RegisterSelectionHandler = {
  mixins: [MasterService],
  created() {
    this.loadMasterList(["roleList"]);
  },
  template: `<div><slot></slot></div>`,
};
export default RegisterSelectionHandler;
export const withRegisterSelectionHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<RegisterSelectionHandler><WrappedComponent v-bind="$props"></WrappedComponent></RegisterSelectionHandler>`,
    components: { RegisterSelectionHandler, WrappedComponent },
  };
};
