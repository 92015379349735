import { DeviceRequest } from "./Device";

export class DeviceLocationRequest {
  device = new DeviceRequest();
  id = "";
  locationId = "";
  deviceId = "";
  deviceStatus = "";
  orgId = "";
  activationDate = "";
  lastActiveDate = "";
}
