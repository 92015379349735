import * as HttpService from "../services/common-services/HttpService";

export function getTechnicianUsers(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Technicians",
    QueryData,
    "userApi.getTechnicianUsers"
  );
}

export function getTechnicianUsersCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Technician/Count",
    QueryData,
    "userApi.getTechnicianUsersCount"
  );
}

export function RegisterTechnician(request) {
  return HttpService.post("api/v1/Register/Technician", request);
}

export function getTechnician(technicianId) {
  return HttpService.get("api/v1/Technician/" + technicianId);
}

export function updateTechnician(request) {
  return HttpService.put("api/v1/Technician/" + request.id, request);
}

export function updateTechnicianApproval(request) {
  return HttpService.put(
    "api/v1/Technician/" + request.id + "/Approvals",
    request
  );
}

export function createTechnician(request) {
  return HttpService.post("api/v1/Technician", request);
}

export function deleteTechnician(technicianId) {
  return HttpService.del("api/v1/Technician/" + technicianId);
}

export function Techniciandeactive(request) {
  return HttpService.put(
    "api/v1/Technician/" + request.id + "/Active",
    request
  );
}
