export default {
  inject: ["handleFilesSelected", "handleFilesSend"],
  data() {
    return {
      files: {
        list: [],
      },
    };
  },
  methods: {
    sendFiles() {
      this.handleFilesSend(
        { ...this.files.options },
        JSON.parse(JSON.stringify(this.files.list))
      );
    },
    processSelectedFiles(files) {
      this.files.list = this.handleFilesSelected(
        this.files.options.category || "",
        files,
        this.files.options.fileName
      );
      if (this.files.options.featureId) this.sendFiles();
    },
  },
};
