import { mapActions } from "vuex";
import { mapState } from "vuex";
import { Prescription } from "../../../models/Prescription";
import { FindingsSave } from "../../../models/Findings";
import NavigationService from "../../../services/common-services/NavigationService";
import { Success } from "../../../models/SwalMessages";

// import { screeningmodel } from "../../../models/screeningeval";

export default {
  inject: [
    "showError",
    "showSuccess",
    "app",
    "Permissions",
    "refresh",
    "navigationBlocker",
  ],
  mixins: [NavigationService],
  data() {
    return {
      manageScreening: {
        page: "",
      },
      previewReport: {
        show: false,
      },
      imgArr: { value: [] },
      macularOCT: { value: [] },
      fundusODphotos: { value: [] },
      fundusOSphotos: { value: [] },
      patientinfo: { value: {} },
      screeninginfo: { value: {} },
      patientHistory: { value: {} },
      patientFamilyHistory: { value: {} },
      screeningVision: { value: {} },
      externalphoto: { value: [] },
      visionixphotos: { value: [] },
      externalphotos: { value: [] },

      FundusODphoto: { value: [] },
      FundusOSphoto: { value: [] },

      NerveODphoto: { value: [] },
      NerveOSphoto: { value: [] },
      NerveODphotos: { value: [] },
      NerveOSphotos: { value: [] },

      MacularODphoto: { value: [] },
      MacularOSphoto: { value: [] },
      MacularODphotos: { value: [] },
      MacularOSphotos: { value: [] },

      corneaODphoto: { value: [] },
      corneaOSphoto: { value: [] },
      corneaODphotos: { value: [] },
      corneaOSphotos: { value: [] },

      AnteriorODphotos: { value: [] },
      AnteriorOSphotos: { value: [] },
      AnteriorODphoto: { value: [] },
      AnteriorOSphoto: { value: [] },

      Fundusphoto: { value: [] },
      Opacityphoto: { value: [] },
      Nervephoto: { value: [] },
      Macularphoto: { value: [] },
      corneaphoto: { value: [] },
      lensStatus: { value: [] },
      lensStatusPhotos: { value: [] },
      AnteriorSegment: { value: [] },
      prescriptionData: {
        postData: new Prescription(),
      },
      findings: {
        selectedFindings: [],
        savedFindings: [],
      },
      screeningEvalData: new FindingsSave(),
      referralDoctors: {
        list: [],
        savedList: [],
      },
      imageView: {
        show: false,
        image: "",
      },
      progress: {
        isDetailsLoading: false,
        isSaving: false,
      },
      isEvaluated: {
        externalPhoto: false,
        fundusPhoto: false,
        anteriorSegmentOCT: false,
        opticNerveOCT: false,
        macularOCT: false,
        cornealTopography: false,
        lensStatus: false,
        screenStatus: "",
        isReadOnly: true,
        reqflag: false,
        istechnotes: true,
      },
      isCompleted: {
        externalPhoto: true,
        fundusPhoto: true,
        anteriorSegmentOCT: true,
        IOP: true,
        vision: true,
        lensStatus: true,
        macularOCT: true,
        opticNerveOCT: true,
        cornealTopography: true,
        anteroir: true,
        hist: true,
      },
      selecteddiagnosis: { value: "" },
      selectedrecommendation: { value: "" },
      diagnosisComments: { value: "" },
      recommendationComments: { value: "" },
      screeningNotes: { value: "" },
    };
  },
  computed: {
    ...mapState({
      userClaims: (state) => state.profile.userClaims,
    }),
    ...mapState({
      recommendedDoc: (state) => state.screeningeval.ReferralDoctors,
    }),
    rptdiagnosis: {
      get: function () {
        let diag = "";
        var groupSelected = {};
        for (let i in this.findings.selectedFindings) {
          let key = this.findings.selectedFindings[i].findingsID;

          groupSelected[key] = {
            findings: this.findings.selectedFindings[i],
            count:
              groupSelected[key] && groupSelected[key].count
                ? groupSelected[key].count + 1
                : 1,
          };
        }
        var selectedValue = Object.values(groupSelected);
        for (let i in selectedValue) {
          if (selectedValue[i].count > 1) selectedValue[i].side = "both";
          else selectedValue[i].side = selectedValue[i].findings.field;
        }
        var groupSaved = {};
        for (let i in this.findings.savedFindings) {
          let key = this.findings.savedFindings[i].findingsID;

          groupSaved[key] = {
            findings: this.findings.savedFindings[i],
            count:
              groupSaved[key] && groupSaved[key].count
                ? groupSaved[key].count + 1
                : 1,
          };
        }
        var savedValue = Object.values(groupSaved);
        for (let i in savedValue) {
          if (savedValue[i].count > 1) savedValue[i].side = "both";
          else savedValue[i].side = savedValue[i].findings.field;
        }

        for (let x = 0; x < selectedValue.length; x++) {
          var eye = selectedValue[x].side == "both" ? " OU, " : " eye, ";
          if (
            selectedValue[x].findings.diagnosys == null ||
            selectedValue[x].findings.diagnosys == ""
          ) {
            diag += "";
          } else {
            diag +=
              selectedValue[x].findings.diagnosys +
              " " +
              (selectedValue[x].side == "both" ? "" : selectedValue[x].side) +
              eye;
          }
        }
        return diag;
      },
      set: function () {
        //pass
      },
    },
    rptrecommendations: {
      get: function () {
        let rec = "";
        var groupSelected = {};
        for (let i in this.findings.selectedFindings) {
          let key = this.findings.selectedFindings[i].findingsID;

          groupSelected[key] = {
            findings: this.findings.selectedFindings[i],
            count:
              groupSelected[key] && groupSelected[key].count
                ? groupSelected[key].count + 1
                : 1,
          };
        }
        var selectedValue = Object.values(groupSelected);
        for (let i in selectedValue) {
          if (selectedValue[i].count > 1) selectedValue[i].side = "both";
          else selectedValue[i].side = selectedValue[i].findings.field;
        }
        var groupSaved = {};
        for (let i in this.findings.savedFindings) {
          let key = this.findings.savedFindings[i].findingsID;

          groupSaved[key] = {
            findings: this.findings.savedFindings[i],
            count:
              groupSaved[key] && groupSaved[key].count
                ? groupSaved[key].count + 1
                : 1,
          };
        }
        var savedValue = Object.values(groupSaved);
        for (let i in savedValue) {
          if (savedValue[i].count > 1) savedValue[i].side = "both";
          else savedValue[i].side = savedValue[i].findings.field;
        }
        // for (let x = 0; x < savedValue.length; x++) {
        //   var eyes = savedValue[x].side == "both" ? " eyes, " : " eye, ";
        //   rec +=
        //     savedValue[x].findings.recommendation +
        //     " on the " +
        //     savedValue[x].side +
        //     eyes;
        // }
        for (let x = 0; x < selectedValue.length; x++) {
          var eye = selectedValue[x].side == "both" ? " OU, " : " eye, ";
          if (
            selectedValue[x].findings.recommendation == null ||
            selectedValue[x].findings.recommendation == ""
          ) {
            rec += "";
          } else {
            rec +=
              selectedValue[x].findings.recommendation +
              " " +
              (selectedValue[x].side == "both" ? "" : selectedValue[x].side) +
              eye;
          }
        }
        return rec;
      },
      set: function () {
        //pass
      },
    },
  },

  methods: {
    ...mapActions("screeningeval", [
      "getscreeninginfo",
      "postPrescription",
      "getLookupValues",
      "saveScreening",
      "getSavedScreening",
      "getScrReferralDoctor",
      "updateScreening",
      "updateScreeningEvaluationStatus",
      "getPrescription",
    ]),
    ...mapActions("screenings", ["updateHold", "requestHold"]),
    getScreening() {
      this.progress.isDetailsLoading = true;
      this.getscreeninginfo(this.$route.params.id)
        .then((response) => {
          let screeningImages = response.screeningInfo.screeningImages;
          this.imgArr.value = response.screeningInfo.screeningImages;
          this.isEvaluated.screenStatus = response.screeningInfo.status;
          this.patientinfo.value = response.patientInfo;
          this.patientinfo.value.name = response.patientInfo.name;
          var today = new Date();
          var birthDate = new Date(this.patientinfo.value.dob);
          var age = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
          }
          this.patientinfo.value.age = age;
          this.screeninginfo.value = response.screeningInfo;
          this.patientHistory.value = response.patientInfo.history;

          this.patientFamilyHistory.value = response.patientInfo.familyHistory;
          this.screeningVision.value = response.screeningInfo.screeningVision;
          this.screeningNotes.value = response.screeningInfo.screeningNotes;
          this.externalphotos.value = screeningImages.filter(
            (x) => x.screeningType == "External"
          );

          this.visionixphotos.value = screeningImages.filter(
            (x) => x.screeningType == "visionix"
          );

          this.externalphoto.value = screeningImages.filter(
            (x) => x.screeningType == "External"
          );

          // console.log("external photo is :", this.externalphoto);
          this.Fundusphoto.value = screeningImages.filter(
            (x) => x.screeningType == "Fundus"
          );
          this.fundusODphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Fundus" && x.eyeSection.toLowerCase() == "od"
          );
          this.FundusODphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Fundus" && x.eyeSection.toLowerCase() == "od"
          );
          this.fundusOSphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Fundus" && x.eyeSection.toLowerCase() == "os"
          );
          this.FundusOSphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Fundus" && x.eyeSection.toLowerCase() == "os"
          );
          this.Opacityphoto.value = screeningImages.filter(
            (x) => x.screeningType == "Opacity"
          );
          this.Nervephoto.value = screeningImages.filter(
            (x) => x.screeningType == "Nerve"
          );

          this.NerveODphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Nerve" &&
              x.eyeSection.toLowerCase() == "od" &&
              x.isDefault == true
          );
          if (this.NerveODphoto.value.length == 0)
            this.NerveODphoto.value = screeningImages.filter(
              (x) =>
                x.screeningType == "Nerve" && x.eyeSection.toLowerCase() == "od"
            );
          this.NerveOSphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Nerve" &&
              x.eyeSection.toLowerCase() == "os" &&
              x.isDefault == true
          );
          if (this.NerveOSphoto.value.length == 0)
            this.NerveOSphoto.value = screeningImages.filter(
              (x) =>
                x.screeningType == "Nerve" && x.eyeSection.toLowerCase() == "os"
            );
          this.NerveODphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Nerve" && x.eyeSection.toLowerCase() == "od"
          );
          this.NerveOSphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Nerve" && x.eyeSection.toLowerCase() == "os"
          );

          this.Macularphoto.value = screeningImages.filter(
            (x) => x.screeningType == "Macula"
          );

          this.MacularODphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Macula" &&
              x.eyeSection.toLowerCase() == "od" &&
              x.isDefault == true
          );
          if (this.MacularODphoto.value.length == 0)
            this.MacularODphoto.value = screeningImages.filter(
              (x) =>
                x.screeningType == "Macula" &&
                x.eyeSection.toLowerCase() == "od"
            );
          this.MacularOSphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Macula" &&
              x.eyeSection.toLowerCase() == "os" &&
              x.isDefault == true
          );
          if (this.MacularOSphoto.value.length == 0)
            this.MacularOSphoto.value = screeningImages.filter(
              (x) =>
                x.screeningType == "Macula" &&
                x.eyeSection.toLowerCase() == "os"
            );
          this.MacularODphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Macula" && x.eyeSection.toLowerCase() == "od"
          );
          this.MacularOSphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Macula" && x.eyeSection.toLowerCase() == "os"
          );

          this.corneaphoto.value = screeningImages.filter(
            (x) => x.screeningType == "Cornea"
          );

          this.corneaODphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Cornea" && x.eyeSection.toLowerCase() == "od"
          );
          this.corneaOSphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Cornea" && x.eyeSection.toLowerCase() == "os"
          );
          this.corneaODphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Cornea" && x.eyeSection.toLowerCase() == "od"
          );
          this.corneaOSphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Cornea" && x.eyeSection.toLowerCase() == "os"
          );

          this.lensStatus.value = screeningImages.filter(
            (x) => x.screeningType == "Opacity"
          );
          this.lensStatusPhotos.value = screeningImages.filter(
            (x) => x.screeningType == "Opacity"
          );

          this.AnteriorSegment.value = screeningImages.filter(
            (x) => x.screeningType == "Pachymetry"
          );

          this.AnteriorODphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Pachymetry" &&
              x.eyeSection.toLowerCase() == "od"
          );
          this.AnteriorOSphotos.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Pachymetry" &&
              x.eyeSection.toLowerCase() == "os"
          );
          this.AnteriorODphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Pachymetry" &&
              x.eyeSection.toLowerCase() == "od"
          );
          this.AnteriorOSphoto.value = screeningImages.filter(
            (x) =>
              x.screeningType == "Pachymetry" &&
              x.eyeSection.toLowerCase() == "os"
          );

          if (
            response.screeningInfo.status.toLowerCase() == "released" ||
            response.screeningInfo.status.toLowerCase() == "hold" ||
            response.screeningInfo.status.toLowerCase() == "closed"
          ) {
            this.getSavedScreening(this.$route.params.id).then((response) => {
              this.findings.savedFindings = response.selectedFindings;
              let fundusOD = response.selectedImages.filter(
                (i) =>
                  i.screeningType.toLowerCase() == "fundus" &&
                  i.eyeSection.toLowerCase() == "od"
              );
              if (fundusOD.length > 0) {
                this.FundusODphoto.value = this.fundusODphotos.value.filter(
                  (i) =>
                    i.screeningType.toLowerCase() == "fundus" &&
                    i.eyeSection.toLowerCase() == "od" &&
                    i.imgFileName == fundusOD[0].imgFileName
                );
              }

              let fundusOS = response.selectedImages.filter(
                (i) =>
                  i.screeningType.toLowerCase() == "fundus" &&
                  i.eyeSection.toLowerCase() == "os"
              );
              if (fundusOS.length > 0) {
                this.FundusOSphoto.value = this.fundusOSphotos.value.filter(
                  (i) =>
                    i.screeningType.toLowerCase() == "fundus" &&
                    i.eyeSection.toLowerCase() == "os" &&
                    i.imgFileName == fundusOS[0].imgFileName
                );
              }

              let external = response.selectedImages.filter(
                (x) => x.screeningType == "External"
              );
              if (external.length > 0) {
                this.externalphoto.value = this.externalphotos.value.filter(
                  (x) =>
                    x.screeningType == "External" &&
                    x.imgFileName == external[0].imgFileName
                );
              }

              let anteriorOS = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Pachymetry" &&
                  x.eyeSection.toLowerCase() == "os"
              );
              if (anteriorOS.length > 0) {
                this.AnteriorOSphoto.value = this.AnteriorOSphotos.value.filter(
                  (x) =>
                    x.screeningType == "Pachymetry" &&
                    x.eyeSection.toLowerCase() == "os" &&
                    x.imgFileName == anteriorOS[0].imgFileName
                );
              }

              let anteriorOD = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Pachymetry" &&
                  x.eyeSection.toLowerCase() == "od"
              );
              if (anteriorOD.length > 0) {
                this.AnteriorODphoto.value = this.AnteriorODphotos.value.filter(
                  (x) =>
                    x.screeningType == "Pachymetry" &&
                    x.eyeSection.toLowerCase() == "od" &&
                    x.imgFileName == anteriorOD[0].imgFileName
                );
              }
              let corneaOS = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Cornea" &&
                  x.eyeSection.toLowerCase() == "os"
              );
              if (corneaOS.length > 0) {
                this.corneaOSphoto.value = this.corneaOSphotos.value.filter(
                  (x) =>
                    x.screeningType == "Cornea" &&
                    x.eyeSection.toLowerCase() == "os" &&
                    x.imgFileName == corneaOS[0].imgFileName
                );
              }

              let corneaOD = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Cornea" &&
                  x.eyeSection.toLowerCase() == "od"
              );
              if (corneaOD.length > 0) {
                this.corneaODphoto.value = this.corneaODphotos.value.filter(
                  (x) =>
                    x.screeningType == "Cornea" &&
                    x.eyeSection.toLowerCase() == "od" &&
                    x.imgFileName == corneaOD[0].imgFileName
                );
              }

              let lens = response.selectedImages.filter(
                (x) => x.screeningType == "Opacity"
              );
              if (lens.length > 0) {
                this.lensStatus.value = this.lensStatusPhotos.value.filter(
                  (x) =>
                    x.screeningType == "Opacity" &&
                    x.imgFileName == lens[0].imgFileName
                );
              }

              let nerveOS = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Nerve" &&
                  x.eyeSection.toLowerCase() == "os"
              );
              if (nerveOS.length > 0) {
                this.NerveOSphoto.value = this.NerveOSphotos.value.filter(
                  (x) =>
                    x.screeningType == "Nerve" &&
                    x.eyeSection.toLowerCase() == "os" &&
                    x.imgFileName == nerveOS[0].imgFileName
                );
              }

              let nerveOD = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Nerve" &&
                  x.eyeSection.toLowerCase() == "od"
              );
              if (nerveOD.length > 0) {
                this.NerveODphoto.value = this.NerveODphotos.value.filter(
                  (x) =>
                    x.screeningType == "Nerve" &&
                    x.eyeSection.toLowerCase() == "od" &&
                    x.imgFileName == nerveOD[0].imgFileName
                );
              }

              let maculaOS = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Macula" &&
                  x.eyeSection.toLowerCase() == "os"
              );
              if (maculaOS.length > 0) {
                this.MacularOSphoto.value = this.MacularOSphotos.value.filter(
                  (x) =>
                    x.screeningType == "Macula" &&
                    x.eyeSection.toLowerCase() == "os" &&
                    x.imgFileName == maculaOS[0].imgFileName
                );
              }

              let maculaOD = response.selectedImages.filter(
                (x) =>
                  x.screeningType == "Macula" &&
                  x.eyeSection.toLowerCase() == "od"
              );
              if (maculaOD.length > 0) {
                this.MacularODphoto.value = this.MacularODphotos.value.filter(
                  (x) =>
                    x.screeningType == "Macula" &&
                    x.eyeSection.toLowerCase() == "od" &&
                    x.imgFileName == maculaOD[0].imgFileName
                );
              }
              this.diagnosisComments.value = response.diagnosis;
              this.recommendationComments.value = response.recommendation;
            });
            this.getScrReferralDoctor(this.$route.params.id).then(() => {
              this.referralDoctors.savedList = this.recommendedDoc;
            });
            this.getPrescription(this.$route.params.id).then((res) => {
              this.prescriptionData.postData = res;
            });
          }
          if (
            this.isEvaluated.screenStatus.toLowerCase() == "hold" ||
            this.userClaims.includes(this.Permissions.IsTechnician)
          ) {
            this.isEvaluated.isReadOnly = true;
          } else {
            this.isEvaluated.isReadOnly = false;
          }
          if (
            (this.isEvaluated.screenStatus.toLowerCase() == "new" ||
              this.isEvaluated.screenStatus.toLowerCase() == "released" ||
              this.isEvaluated.screenStatus.toLowerCase() == "closed") &&
            (this.userClaims.includes(this.Permissions.IsTechnician) ||
              this.userClaims.includes(this.Permissions.IsReader) ||
              this.userClaims.includes(this.Permissions.IsSuperAdmin) ||
              this.userClaims.includes(this.Permissions.IsAdmin))
          ) {
            this.isEvaluated.istechnotes = false;
          }
          if (this.isEvaluated.screenStatus.toLowerCase() == "hold") {
            this.isEvaluated.reqflag = true;
          }
          this.testcomp();
        })
        .catch((errors) => {
          console.log(errors);
          if (this.$route.params.from == "packages") {
            let error = new Error();
            error.title = "Screening Not Found";
            error.text =
              "Sorry! We couldn't find the screening for selected package.";
            error.confirmButtonClass = "md-button md-info";
            this.showError(error);
            this.navigationBlocker.val = true;
            this.$router.push("/globe-chek/Packages");
          } else if (this.$route.params.from == "screenings") {
            let error = new Error();
            error.title = "Failed";
            error.text = "Oops! Something went wrong.";
            error.confirmButtonClass = "md-button md-info";
            this.showError(error);
            this.navigationBlocker.val = true;
            this.navigateTo("Screenings");
          }
          this.status = errors;
        })
        .finally(() => (this.progress.isDetailsLoading = false));
    },
    getLableFindings(masterName, field, title) {
      var tempSelectedFindings = this.findings.selectedFindings || [];

      var filteredFindings = tempSelectedFindings
        .filter((m) => m.findingMasterName == masterName && m.field == field)
        .map((m) => m.findingsName);
      var formattedFindings = filteredFindings.join(", ");
      formattedFindings = formattedFindings
        ? title + " : " + formattedFindings
        : formattedFindings;
      return formattedFindings;
    },
    resetSelectedFindings(masterName) {
      var tempSelectedFindings = this.findings.selectedFindings || [];
      this.findings.selectedFindings = tempSelectedFindings.filter(
        (m) => m.findingMasterName == masterName
      );
    },

    handleChildDragstart(image) {
      console.log("handlechild drag called ", image);
      localStorage.setItem("testObject", JSON.stringify(image));
      let img = this.screeningEvalData.selectedImages.filter(
        (i) =>
          i.screeningType == image.screeningType &&
          i.eyeSection.toLowerCase() == image.eyeSection.toLowerCase()
      );
      if (img.length > 0) {
        let ind = this.screeningEvalData.selectedImages.findIndex(
          (i) =>
            i.screeningType == img[0].screeningType &&
            i.eyeSection.toLowerCase() == img[0].eyeSection.toLowerCase()
        );
        this.screeningEvalData.selectedImages.splice(ind, 1);
      }
      this.screeningEvalData.selectedImages.push(image);
      //event.stopPropagation();
    },

    handleDrop() {
      var img = localStorage.getItem("testObject");

      var image_object = JSON.parse(img);

      if (image_object.screeningType == "External") {
        this.externalphoto.value = [image_object];
      } else if (
        image_object.screeningType == "Fundus" &&
        image_object.eyeSection == "os"
      ) {
        let fundus_image = this.FundusOSphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.FundusOSphoto.value = fundus_image;
      } else if (image_object.screeningType == "Opacity") {
        this.lensStatus.value = [image_object];
      } else if (
        image_object.screeningType == "Fundus" &&
        image_object.eyeSection == "od"
      ) {
        let fundus_image = this.FundusODphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.FundusODphoto.value = fundus_image;
      } else if (
        image_object.screeningType == "Nerve" &&
        image_object.eyeSection == "od"
      ) {
        console.log(this.NerveODphoto);
        let nerve_image = this.NerveODphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.NerveODphoto.value = nerve_image;
      } else if (
        image_object.screeningType == "Nerve" &&
        image_object.eyeSection == "os"
      ) {
        console.log(this.NerveOSphoto);
        let nerve_image = this.NerveOSphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.NerveOSphoto.value = nerve_image;
      }
      // else if (image_object.screeningType == "Opacity") {
      //   let opacity_image = this.Opacityphoto.value.map((m) =>
      //     m.eyeSection == image_object.eyeSection ? image_object : m
      //   );
      //   this.Opacityphoto.value = opacity_image;
      // }
      // else if (image_object.screeningType == "Nerve") {
      //   let Nerve_image = this.Nervephoto.value.map((m) =>
      //     m.eyeSection == image_object.eyeSection ? image_object : m
      //   );
      //   // console.log("this is opacity screen type");
      //   this.Nervephoto.value = Nerve_image;
      // } else if (image_object.screeningType == "Macula") {
      //   let Macula_image = this.Macularphoto.value.map((m) =>
      //     m.eyeSection == image_object.eyeSection ? image_object : m
      //   );
      //   // console.log("this is opacity screen type");
      //   this.Macularphoto.value = Macula_image;
      // }
      else if (
        image_object.screeningType == "Macula" &&
        image_object.eyeSection == "od"
      ) {
        let macula_image = this.MacularODphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.MacularODphoto.value = macula_image;
      } else if (
        image_object.screeningType == "Macula" &&
        image_object.eyeSection == "os"
      ) {
        let macula_image = this.MacularOSphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.MacularOSphoto.value = macula_image;
      }
      // else if (image_object.screeningType == "Cornea") {
      //   console.log("image_object", image_object);
      //   let cornea_image = this.corneaphoto.value.map((m) =>
      //     m.eyeSection == image_object.eyeSection ? image_object : m
      //   );
      //   // console.log("this is opacity screen type");
      //   this.corneaphoto.value = cornea_image;
      // }
      else if (
        image_object.screeningType == "Cornea" &&
        image_object.eyeSection == "od"
      ) {
        let cornea_image = this.corneaODphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.corneaODphoto.value = cornea_image;
      } else if (
        image_object.screeningType == "Cornea" &&
        image_object.eyeSection == "os"
      ) {
        let cornea_image = this.corneaOSphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.corneaOSphoto.value = cornea_image;
      } else if (
        image_object.screeningType == "Pachymetry" &&
        image_object.eyeSection == "od"
      ) {
        let pachymetry_image = this.AnteriorODphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.AnteriorODphoto.value = pachymetry_image;
      } else if (
        image_object.screeningType == "Pachymetry" &&
        image_object.eyeSection == "os"
      ) {
        let pachymetry_image = this.AnteriorOSphoto.value.map((m) =>
          m.eyeSection == image_object.eyeSection ? image_object : m
        );
        this.AnteriorOSphoto.value = pachymetry_image;
      } else {
        console.log();
      }
    },

    savePrescription() {
      let postData = this.prescriptionData.postData;
      postData.screeningId = this.$route.params.id;
      postData.orgID = this.orgID;
      this.postPrescription(postData);
    },
    isScreeningImage(image) {
      if (image && image.screeningID == this.$route.params.id) return true;
    },

    saveScreeningEvaluation(status) {
      this.navigationBlocker.val = true;
      this.progress.isSaving = true;
      let postData = this.screeningEvalData;
      postData.screeningID = this.$route.params.id;
      this.recommendedDoc.map((x) => postData.doctors.push(x.personEntryID));
      postData.selectedFindings = this.findings.selectedFindings;
      postData.selectedFindings = this.findings.selectedFindings;
      postData.diagnosis = this.diagnosisComments.value;
      postData.recommendation = this.recommendationComments.value;
      postData.screeningVision = this.screeningVision.value;
      postData.status = status;
      postData.screeningNotes = this.screeningNotes.value;

      for (let x = 0; x < this.findings.selectedFindings.length; x++) {
        if (this.findings.selectedFindings[x].isGlobal) {
          postData.selectedFindings[x].isGlobal = 1;
        } else {
          postData.selectedFindings[x].isGlobal = 0;
        }
      }

      if (this.manageScreening.page == "edit") {
        this.updateScreening(postData);
        this.progress.isSaving = false;
      } else {
        this.saveScreening(postData).catch((error) => {
          console.log(error);
        });
      }
      console.log(postData);
      if (
        this.prescriptionData.postData.sphereOD ||
        this.prescriptionData.postData.sphereOS ||
        this.prescriptionData.postData.cylinderOD ||
        this.prescriptionData.postData.cylinderOS ||
        this.prescriptionData.postData.axisOD ||
        this.prescriptionData.postData.axisOS ||
        this.prescriptionData.postData.powerOD ||
        this.prescriptionData.postData.powerOS ||
        this.prescriptionData.postData.distanceOD ||
        this.prescriptionData.postData.distanceOS
      ) {
        this.savePrescription();
      }
    },
    successfunction() {
      let screeningId = this.$route.params.id;
      this.updateScreeningEvaluationStatus(screeningId)
        .then(() => {
          let success = new Success();
          success.title = "Save";
          if (this.userClaims.includes(this.Permissions.IsTechnician)) {
            success.text = "Screening Notes Saved";
          } else {
            success.text = "Evaluation Saved";
          }
          success.confirmButtonClass = "md-button md-success";
          this.showSuccess(success);
          this.navigateTo("Screenings");
          this.refresh.value = true;
          this.progress.isSaving = true;
        })
        .catch(() => {
          let error = new Error();
          error.text = "Something went wrong";
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        });
    },
    ScreeningEvaluationsavefunction() {
      if (this.userClaims.includes(this.Permissions.IsTechnician)) {
        this.saveScreeningEvaluation("new");
      } else {
        this.saveScreeningEvaluation("hold");
      }
      this.successfunction();
    },
    getmethod() {
      this.selecteddiagnosis.value = this.rptdiagnosis;
      this.selectedrecommendation.value = this.rptrecommendations;
    },
    handlerequesttoreleasepro() {
      let screeningId = this.$route.params.id;
      let postformData = {};
      postformData.screeningId = screeningId;
      this.requestHold(postformData)
        .then(() => {
          console.log("release request");
          var success = new Success();
          success.text = "Screening requested successfully";
          this.showSuccess(success);
          this.navigateBack();
        })
        .catch((err) => console.log(err))
        .finally();
    },
    handlereleaseforreqpro() {
      let screeningId = this.$route.params.id;
      let postformData = {};
      postformData.id = screeningId;
      postformData.status = "released";
      this.updateHold(postformData)
        .then(() => {
          var success = new Success();
          success.text = "Screening released successfully";
          this.showSuccess(success);
          this.navigateTo("Screenings");
        })
        .catch((error) => this.showError(error))
        .finally();
    },
    beforeDestroy() {
      if (this.submitSubscription) this.submitSubscription.unsubscribe();
    },
    testcomp() {
      this.MacularOSphotochek = this.MacularOSphotos.value.filter(
        (x) => x.imgFileName === null
      );
      //console.log(this.MacularOSphotochek);
      this.MacularODphotochek = this.MacularODphotos.value.filter(
        (x) => x.imgFileName === null
      );
      this.NerveODphotocheck = this.NerveODphotos.value.filter(
        (x) => x.imgFileName === null
      );
      this.NerveOSphotochek = this.NerveOSphotos.value.filter(
        (x) => x.imgFileName === null
      );
      if (this.externalphoto.value.length > 0) {
        this.isCompleted.externalPhoto = true;
      } else {
        this.isCompleted.externalPhoto = false;
      }
      if (
        this.FundusODphoto.value.length > 0 ||
        this.FundusOSphoto.value.length > 0
      ) {
        this.isCompleted.fundusPhoto = true;
      } else {
        this.isCompleted.fundusPhoto = false;
      }
      if (
        this.AnteriorODphoto.value.length > 0 ||
        this.AnteriorOSphoto.value.length > 0
      ) {
        this.isCompleted.anteriorSegmentOCT = true;
      } else {
        this.isCompleted.anteriorSegmentOCT = false;
      }
      if (this.lensStatus.value.length > 0) {
        this.isCompleted.lensStatus = true;
      } else {
        this.isCompleted.lensStatus = false;
      }
      if (
        (this.MacularOSphotochek.length === 0 ||
          this.MacularODphotochek.length === 0) &&
        this.Macularphoto.value.length > 0
      ) {
        this.isCompleted.macularOCT = true;
      } else {
        this.isCompleted.macularOCT = false;
      }
      if (
        (this.NerveODphotocheck.length === 0 ||
          this.NerveOSphotochek.length === 0) &&
        this.Opacityphoto.value.length > 0
      ) {
        this.isCompleted.opticNerveOCT = true;
      } else {
        this.isCompleted.opticNerveOCT = false;
      }
      if (
        this.corneaODphoto.value.length > 0 ||
        this.corneaOSphoto.value.length > 0
      ) {
        this.isCompleted.cornealTopography = true;
      } else {
        this.isCompleted.cornealTopography = false;
      }
      if (this.screeningVision.value.length > 0) {
        this.isCompleted.vision = true;
      } else {
        this.isCompleted.vision = false;
      }
      if (
        this.screeningVision.value.va_od_dist !== null ||
        this.screeningVision.value.va_os_dist !== null ||
        this.screeningVision.value.va_od_near !== null ||
        this.screeningVision.value.va_os_near !== null ||
        this.screeningVision.value.va_od_color !== "" ||
        this.screeningVision.value.va_os_color !== "" ||
        this.screeningVision.value.va_od_stereo !== "" ||
        this.screeningVision.value.va_os_stereo !== ""
      ) {
        this.isCompleted.vision = true;
      } else {
        this.isCompleted.vision = false;
      }
      if (
        this.screeningVision.value.iop_od_correct !== null ||
        this.screeningVision.value.iop_os_correct !== null ||
        this.screeningVision.value.iop_os_eyepres !== null ||
        this.screeningVision.value.iop_os_eyepres !== null
      ) {
        this.isCompleted.IOP = true;
      } else {
        this.isCompleted.IOP = false;
      }
      if (
        this.screeningVision.value.ant_od_ang !== null ||
        this.screeningVision.value.ant_od_thick !== null ||
        this.screeningVision.value.ant_os_ang !== null ||
        (this.screeningVision.value.ant_os_thick !== null &&
          (this.screeningVision.value.ant_od_ang !== "None" ||
            this.screeningVision.value.ant_od_thick !== "None" ||
            this.screeningVision.value.ant_os_ang !== "None" ||
            this.screeningVision.value.ant_os_thick !== "None"))
      ) {
        this.isCompleted.anteroir = true;
      } else {
        this.isCompleted.anteroir = false;
      }
      if (
        this.patientFamilyHistory.value !== null &&
        this.patientHistory.value !== null
      ) {
        if (
          this.patientFamilyHistory.value.alzheimers !== "false" ||
          this.patientFamilyHistory.value.cancer !== "false" ||
          this.patientFamilyHistory.value.cholesterol !== "false" ||
          this.patientFamilyHistory.value.diabetes !== "false" ||
          this.patientFamilyHistory.value.glaucoma !== "false" ||
          this.patientFamilyHistory.value.heart !== "false" ||
          this.patientFamilyHistory.value.liver !== "false" ||
          this.patientFamilyHistory.value.macDegen !== "false" ||
          this.patientFamilyHistory.value.migraines !== "false" ||
          this.patientFamilyHistory.value.other !== "" ||
          this.patientHistory.value.surgery !== "" ||
          this.patientHistory.value.cancer !== "" ||
          this.patientHistory.value.diabeties !== "" ||
          this.patientHistory.value.others !== ""
        ) {
          this.isCompleted.hist = true;
        } else {
          this.isCompleted.hist = false;
        }
      } else {
        console.log("Else part");
        this.isCompleted.hist = false;
      }

      // console.log(this.patientFamilyHistory.value);
    },
  },
  watch: {
    "findings.selectedFindings": function () {
      this.getmethod();
    },
    "this.selecteddiagnosis": function () {
      this.getmethod();
    },
  },

  provide() {
    return {
      imgArr: this.imgArr,
      externalphotos: this.externalphotos,
      patientinfo: this.patientinfo,
      screeninginfo: this.screeninginfo,
      patientHistory: this.patientHistory,
      patientFamilyHistory: this.patientFamilyHistory,
      screeningVision: this.screeningVision,
      externalphoto: this.externalphoto,
      Fundusphoto: this.Fundusphoto,
      FundusODphoto: this.FundusODphoto,
      FundusOSphoto: this.FundusOSphoto,
      Opacityphoto: this.Opacityphoto,
      Nervephoto: this.Nervephoto,
      Macularphoto: this.Macularphoto,
      Corneaphoto: this.corneaphoto,
      lensStatus: this.lensStatus,
      calcODOS: this.calcODOS,
      handleChildDragstart: this.handleChildDragstart,
      handleDrop: this.handleDrop,
      imgURL: this.imgURL,
      prescriptionData: this.prescriptionData,
      findings: this.findings,
      previewReport: this.previewReport,
      getLableFindings: this.getLableFindings,
      resetSelectedFindings: this.resetSelectedFindings,
      AnteriorSegment: this.AnteriorSegment,
      imageView: this.imageView,
      NerveODphoto: this.NerveODphoto,
      NerveOSphoto: this.NerveOSphoto,
      NerveODphotos: this.NerveODphotos,
      NerveOSphotos: this.NerveOSphotos,
      corneaODphoto: this.corneaODphoto,
      corneaOSphoto: this.corneaOSphoto,
      corneaOSphotos: this.corneaOSphotos,
      corneaODphotos: this.corneaODphotos,
      MacularODphoto: this.MacularODphoto,
      MacularOSphoto: this.MacularOSphoto,
      MacularODphotos: this.MacularODphotos,
      MacularOSphotos: this.MacularOSphotos,
      AnteriorODphoto: this.AnteriorODphoto,
      AnteriorOSphoto: this.AnteriorOSphoto,
      AnteriorODphotos: this.AnteriorODphotos,
      AnteriorOSphotos: this.AnteriorOSphotos,
      referralDoctors: this.referralDoctors,
      progress: this.progress,
      isEvaluated: this.isEvaluated,
      manageScreening: this.manageScreening,
      saveScreeningEvaluation: this.saveScreeningEvaluation,
      ScreeningEvaluationsavefunction: this.ScreeningEvaluationsavefunction,
      selecteddiagnosis: this.selecteddiagnosis,
      selectedrecommendation: this.selectedrecommendation,
      screeningEvalData: this.screeningEvalData,
      fundusODphotos: this.fundusODphotos,
      fundusOSphotos: this.fundusOSphotos,
      lensStatusPhotos: this.lensStatusPhotos,
      isCompleted: this.isCompleted,
      visionixphotos: this.visionixphotos,
      diagnosisComments: this.diagnosisComments,
      recommendationComments: this.recommendationComments,
      isScreeningImage: this.isScreeningImage,
      screeningNotes: this.screeningNotes,
    };
  },
};
