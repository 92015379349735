import { withManagePatientHandler } from "../../handlers/module-handlers/patient/ManagePatientHandler";

export default [
  {
    path: "Patient",
    component: () => import("./PatientRoutePage.vue"),
    meta: { isPublic: true },
    children: [
      {
        path: "add",
        component: withManagePatientHandler(() =>
          import("./manage-patient/PatientPageManagement.vue")
        ),
        meta: { userType: "Patient" },
      },
      {
        path: "edit/:patientId",
        component: withManagePatientHandler(() =>
          import("./manage-patient/PatientPageManagement.vue")
        ),
        meta: { userType: "Patient" },
      },
    ],
  },
];
