import MysettingsService from "../../../services/module-services/Mysettings/MysettingsService";
const MySettingsHandler = {
  mixins: [MysettingsService],
  methods: {
    Notificationpreference() {
      this.Notificationpreferences();
    },
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      NotificationPerferenceReset: this.NotificationPerferenceReset,
      NotificationPerferenceResetConfirm: this.Notificationpreference,
    };
  },
};

export default MySettingsHandler;

export const withMySettingsHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<MySettingsHandler><WrappedComponent v-bind="$props"></WrappedComponent></MySettingsHandler>`,
    components: { MySettingsHandler, WrappedComponent },
  };
};
