import * as HttpService from "../services/common-services/HttpService";

export function getReports(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Reports/AllReports",
    QueryData,
    "reportsApi.getReports"
  );
}
export function getReportsCount(QueryData = {}) {
  return HttpService.getByQueryWithCancel(
    "api/v1/Reports/Reports/Count",
    QueryData,
    "reportsApi.getReportsCount"
  );
}

export function getReportsSortList() {
  return HttpService.getWithoutAuth("api/v1/Lookup/SortReports");
}

export function patientmsgnotification(postdata) {
  return HttpService.post(
    `api/v1/Messages/ReportNotification/${postdata.type}/${postdata.screeningID}`
  );
}

export function adminreportmsgnotification(screeningId) {
  return HttpService.post(`api/v1/Messages/ReportAdminDelivery/${screeningId}`);
}

export function adminreportstatusnotification(screeningId) {
  return HttpService.post(`api/v1/Messages/ReportAdminRead/${screeningId}`);
}

export function UpdateReportStatusPut(screeningId) {
  return HttpService.put(`api/v1/Reports/status/${screeningId}`);
}

export function patientemailnotification(postData) {
  return HttpService.post("api/v1/Reports/reportnotificationemail", postData);
}

export function reportgeneration(postData) {
  return HttpService.post("api/v1/Reports", postData);
}

export function updatereport(postData) {
  return HttpService.put("api/v1/Reports", postData);
}

export function reportpdjgeneration(postData) {
  return HttpService.postAndGetBlob("api/v1/GeneratePdf", postData);
}
