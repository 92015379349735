import reportsService from "../../../services/module-services/reports/reportsService";

const ReportsHandler = {
  mixins: [reportsService],
  methods: {},

  provide() {
    return {};
  },
  template: `<div><slot></slot></div>`,
  components: {},
};

export default ReportsHandler;

export const withReportsHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ReportsHandler><WrappedComponent v-bind="$props"></WrappedComponent></ReportsHandler>`,
    components: { ReportsHandler, WrappedComponent },
  };
};
