import screeningsService from "../../../services/module-services/screeningsAvailable/screeningsService";
import ListLayoutService from "../../../services/layout-services/ListLayoutService";

const ScreeningsHandler = {
  mixins: [screeningsService, ListLayoutService],
  methods: {},

  provide() {
    return {};
  },
  template: `<div><slot></slot></div>`,
};

export default ScreeningsHandler;

export const withScreeningsHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ScreeningsHandler><WrappedComponent v-bind="$props"></WrappedComponent></ScreeningsHandler>`,
    components: { ScreeningsHandler, WrappedComponent },
  };
};
