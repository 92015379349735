import { withOnholdHandler } from "../../handlers/module-handlers/screeningsOnhold/onholdHandler";
import { withOnholModuledHandler } from "../../handlers/module-handlers/screeningsOnhold/onholdModuleHandler";
import { withscreeningevalHandler } from "../../handlers/module-handlers/screeningevalhandler/screeningevalHandler";

export default [
  {
    path: "Onhold",
    name: "Onhold",
    component: withOnholModuledHandler(() =>
      import("../ScreeningsOnhold/OnholdRoutePage.vue")
    ),
    children: [
      {
        path: "",
        component: withOnholdHandler(() =>
          import("../ScreeningsOnhold/Onhold.vue")
        ),
        meta: { title: "Onhold" },
      },
      {
        path: "evaluation/:id",
        component: withscreeningevalHandler(() =>
          import("../Screening/Screenings.vue")
        ),
        meta: { title: "Onhold" },
      },
    ],
  },
];
