import { mapActions } from "vuex";
import Features from "../../../constants/Features";
import NavigationService from "../../../services/common-services/NavigationService";
export default {
  mixins: [NavigationService],
  inject: [],
  data() {
    return {
      listFilters: this.loadFilterList(),
    };
  },

  methods: {
    ...mapActions("reports", ["getReports", "getReportsCount", "getSortList"]),
    ...mapActions("screenings", ["getDurationList"]),
    loadFilterList() {
      this.getDurationList();
      this.getSortList();
    },
  },
  provide() {
    return {
      feature: Features.Reports,
    };
  },
};
