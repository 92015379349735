import { mapActions, mapState } from "vuex";
import { Notifications } from "../../../models/Person";
import { PatientRequest } from "../../../models/Patient";
export default {
  inject: ["app", "showErrorMsg", "showSuccessMsg"],
  data() {
    return {
      NotificationPerferenceReset: {
        postData: new Notifications(),
      },
      userID: this.app.userProfile.id,
      notificationget: "",
      progress: {
        isDetailsLoading: false,
      },
      patientprofile: {
        postData: new PatientRequest(),
        historyChecks: [],
        familyHistoryChecks: [],
      },
    };
  },
  created() {
    this.intialgetnotificationpreference();
  },
  computed: {
    ...mapState({
      userNotificationperference: (state) =>
        state.profile.userNotificationperference,
    }),
  },

  methods: {
    ...mapActions("profile", [
      "notificationpreference",
      "getNotificationpreferences",
    ]),

    Notificationpreferences() {
      let postData = {
        allowEmailNotification: Number(
          this.NotificationPerferenceReset.postData.allowEmailNotification
        ),
        allowPopUpNotification: Number(
          this.NotificationPerferenceReset.postData.allowPopUpNotification
        ),
      };
      let userID = this.userID;
      this.notificationpreference({
        profileID: userID,
        postData: postData,
      })
        .then(() => {
          this.showSuccessMsg("Notification Pereference changed Successful");
        })
        .catch(() => {
          this.showErrorMsg("Sorry Something went wrong");
        });
    },
    intialgetnotificationpreference() {
      this.progress.isDetailsLoading = true;
      this.getNotificationpreferences(this.userID)
        .then(() => {
          this.NotificationPerferenceReset.postData.allowEmailNotification =
            this.userNotificationperference.allowEmailNotification;
          this.NotificationPerferenceReset.postData.allowPopUpNotification =
            this.userNotificationperference.allowPopUpNotification;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.progress.isDetailsLoading = false));
    },
    savepatientprofile() {},
    loadpatientprofile() {},
  },
  provide() {
    return {
      progress: this.progress,
      patientprofile: this.patientprofile,
    };
  },
};
