import { withClientListHandler } from "../../handlers/module-handlers/Client/ClientListHandler";
import { withClientManageHandler } from "../../handlers/module-handlers/Client/ClientManageHandler";
import { withClientModuleHandler } from "../../handlers/module-handlers/Client/ClientModuleHandler";

export default [
  {
    path: "clients",
    component: withClientModuleHandler(() =>
      import("../Client/ClientRoutePage.vue")
    ),
    children: [
      {
        path: "",
        component: withClientListHandler(() =>
          import("../Client/ClientList.vue")
        ),
      },
      {
        path: "add",
        component: withClientManageHandler(() =>
          import("../Client/Client.vue")
        ),
      },
      {
        path: "edit/:slug",
        component: withClientManageHandler(() =>
          import("../Client/Client.vue")
        ),
      },
    ],
  },
];
