<template>
  <div>
    <md-autocomplete
      v-model="localValue"
      :md-options="items"
      @md-changed="getSearchItems"
      @md-opened="getSearchItems"
    >
      <label style="padding-left: 10px">{{ label }}</label>
      <template slot="md-autocomplete-item" slot-scope="{ item }">{{
        item.name
      }}</template>
    </md-autocomplete>
  </div>
</template>
<script>
export default {
  name: "SearchTextInput",
  props: {
    label: String,
    items: Array,
  },
  data: () => ({
    value: null,
  }),
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getSearchItems(searchTerm) {
      this.data = new Promise((resolve) => {
        window.setTimeout(() => {
          if (!searchTerm) {
            resolve(this.items);
          } else {
            const term = searchTerm.toLowerCase();

            resolve(
              this.items.filter(({ name }) => name.toLowerCase().includes(term))
            );
          }
        }, 2000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-autocomplete {
  width: auto;
}
</style>
