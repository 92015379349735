import {
  getSubmit,
  getCancel,
} from "../services/control-services/ConfirmationService";
export default {
  inject: ["confirmation", "navigationBlocker"],
  data() {
    return { popupInvoked: { val: false } };
  },
  beforeRouteLeave(to, from, next) {
    if (from.path.includes("Onhold")) this.navigationBlocker.val = true;
    if (!this.navigationBlocker.val) {
      this.popupInvoked.val = true;
      this.confirmation.title = "Leave Page ?";
      this.confirmation.description =
        "Do you really want to leave? you have unsaved changes!";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        next();
        this.navigationBlocker.val = true;
        this.popupInvoked.val = false;
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        next(false);
        this.popupInvoked.val = false;
        this.cancelSubscription.unsubscribe();
        this.submitSubscription.unsubscribe();
      });
    } else {
      next();
    }
  },
  provide() {
    return {
      navigationBlocker: this.navigationBlocker,
      popupInvoked: this.popupInvoked,
    };
  },
  methods: {
    beforeWindowUnload(e) {
      if (!this.navigationBlocker.val) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
};
