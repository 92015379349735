import ListHandler from "../../common-handlers/ListHandler";
import screeningsModuleService from "../../../services/module-services/screeningsAvailable/screeningsModuleService";
import Features from "../../../constants/Features";
const ScreeningsModuleHandler = {
  mixins: [screeningsModuleService],
  data() {
    return {
      refreshList: () => {},
      feature: Features.ScreeningsAvailable,
    };
  },
  provide() {
    return {
      feature: Features.ScreeningsAvailable,
      listFilters: this.getDurationList,
      getList: this.getScreeningsAvailable,
      getListCount: this.getScreeningsAvailableCount,
      sortScr: this.getSortList,
    };
  },
  template: `<ListHandler :preventDefaultRefresh="false" @getRefreshList="(fun) => (refreshList = fun)"><div><slot></slot></div></ListHandler>`,
  components: {
    ListHandler,
  },
};

export default ScreeningsModuleHandler;

export const withScreeningsModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ScreeningsModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></ScreeningsModuleHandler>`,
    components: { ScreeningsModuleHandler, WrappedComponent },
  };
};
