import * as api from "../../../api/adminApi";
import * as types from "./admin.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  updateAdmin({ commit }, request) {
    return api.updateAdmin(request);
  },
  // eslint-disable-next-line no-unused-vars
  getAdmin({ commit }, id) {
    return api.getAdmin(id);
  },
  // eslint-disable-next-line no-unused-vars
  updateAdminApproval({ commit }, request) {
    return api.updateAdminApproval(request);
  },
  // eslint-disable-next-line no-unused-vars
  RegisterAdmin({ commit }, request) {
    return api.RegisterAdmin(request);
  },
  // eslint-disable-next-line no-unused-vars
  createAdmin({ commit }, request) {
    return api.createAdmin(request);
  },

  // eslint-disable-next-line no-unused-vars
  deleteAdmin({ commit }, request) {
    return api.deleteAdmin(request);
  },

  // eslint-disable-next-line no-unused-vars
  admindeactive({ commit }, request) {
    return api.admindeactive(request);
  },

  // eslint-disable-next-line no-unused-vars
  getAdminUsers({ commit }, QueryData) {
    return api.getAdminUsers(QueryData).then((res) => {
      commit(types.GET_AdminUsers, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getAdminUsersCount({ commit }, QueryData) {
    return api.getAdminUsersCount(QueryData);
  },
};
