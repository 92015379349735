import * as api from "../../../api/profileApi";
import * as types from "./profile.action.types";

export default {
  getProfile({ commit }) {
    return api.getProfile().then((res) => {
      commit(types.GET_PROFILE, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  updateProfile({ commit }, profileData) {
    return api.updateProfile(profileData);
  },
  // eslint-disable-next-line no-unused-vars
  updateApiProfile({ commit }, profileData) {
    return api.updateApiProfile(profileData);
  },
  getProfileClaims({ commit }) {
    return api.getClaims().then((res) => {
      commit(types.GET_PROFILE_CLAIMS, res);
    });
  },

  // eslint-disable-next-line no-unused-vars
  getDoctorByUserId({ commit }, id) {
    return api
      .getDoctorByUserId(id)
      .then((res) => commit(types.GET_PROFILE_ATTRIBUTES, res));
  },

  setUserAttributesSuccess({ commit }, res) {
    commit(types.GET_PROFILE_ATTRIBUTES, res);
  },

  // eslint-disable-next-line no-unused-vars
  notificationpreference({ commit }, { profileID, postData }) {
    return api.notificationpreference(profileID, postData).then((res) => {
      commit(types.GET_NOTIFICATIONPREFERENCES, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getNotificationpreferences({ commit }, UserID) {
    return api.getNotificationpreferences(UserID).then((res) => {
      commit(types.GET_NOTIFICATIONPREFERENCES, res);
      return res;
    });
  },
};
