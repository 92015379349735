<template>
  <div>
    {{ this.getappInsights() }}
  </div>
</template>
<script>
import AppInsightservice from "../../services/common-services/AppInsightservice";
export default {
  mixins: [AppInsightservice],
};
</script>
