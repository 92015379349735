import MasterService from "../../../services/common-services/MasterService";
import ClientModuleService from "../../../services/module-services/Client/ClientModuleService";
import ListHandler from "../../common-handlers/ListHandler";
import QueryableListHandler from "../../common-handlers/QueryableListHandler";

const ClientModuleHandler = {
  mixins: [ClientModuleService, MasterService],
  provide() {
    return {
      queryableListFunc: this.getClientList,
    };
  },
  template: `<QueryableListHandler><ListHandler :isQueryable="true"><slot></slot></div></ListHandler></QueryableListHandler>`,
  components: {
    ListHandler,
    QueryableListHandler,
  },
};

export default ClientModuleHandler;

export const withClientModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ClientModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></ClientModuleHandler>`,
    components: { ClientModuleHandler, WrappedComponent },
  };
};
