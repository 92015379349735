import Features from "../../constants/Features";

export default {
  [Features.Users]: {},
  [Features.Package]: {
    title: "Screening Package Injest",
  },
  [Features.ScreeningsAvailable]: {
    title: "Screenings Available",
  },
  [Features.Reports]: {
    title: "Reports",
  },
  [Features.ScreeningsOnhold]: {
    title: "Screenings On-hold",
  },
  [Features.ReferralDoctor]: {
    title: "REFERRAL DOCTORS",
  },
  [Features.Devices]: {
    templateCardTitle: false,
    title: "Devices",
  },
  [Features.MasterData]: {
    templateCardTitle: false,
    title: "MasterData",
  },
  [Features.Clients]: {
    templateCardTitle: false,
    title: "CLIENT LIST",
  },
  [Features.Providers]: {
    title: "Doctors",
  },
};
