import state from "./profile.state";
import actions from "./profile.actions";
import mutations from "./profile.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
