import { withAuthHandler } from "../handlers/module-handlers/AuthHandler";
import { withResetPasswordHandler } from "../handlers/module-handlers/user/ResetPasswordHandler";
import { withResetPasswordConfirmHandler } from "../handlers/module-handlers/user/ResetPasswordConfirmHandler";
import RegisterRoute from "../views/register/RegisterRoute";

import { withSearchDoctorHandler } from "../handlers/module-handlers/doctor/SearchDoctorHandler";

export default [
  {
    path: "/",
    component: () => import("./../views/home/HomePage.vue"),
    meta: { isPublic: true },
    redirect: "/reading-center",
    children: [
      {
        path: "/login",
        name: "login",
        component: withAuthHandler(() =>
          import("./../views/login/LoginPage.vue")
        ),
        meta: { isPublic: true },
      },
      {
        path: "/reading-center",
        name: "Reading Center",
        component: () =>
          import("../views/reading-center/ReadingCenterPage.vue"),
        meta: { isPublic: true },
      },
      {
        path: "/reset-psw",
        name: "ResetPsw",
        component: withResetPasswordHandler(() =>
          import("./../views/reset-password/ResetPsw.vue")
        ),
        meta: { isPublic: true },
      },
      {
        path: "/reset-psw-action",
        name: "ResetPswAction",
        component: withResetPasswordConfirmHandler(() =>
          import("./../views/reset-password/ResetPswAction.vue")
        ),
        meta: { isPublic: true },
      },
      ...RegisterRoute,
      {
        path: "/doctor-search",
        name: "DoctorSearch",
        component: withSearchDoctorHandler(() =>
          import("./../views/home/doctor/Search.vue")
        ),
        meta: { isPublic: true },
      },
    ],
  },
  {
    path: "/unit-test",
    name: "Unit Testing Samples",
    component: () => import("../components/Arithmetic.vue"),
    meta: { isPublic: true },
  },
];
