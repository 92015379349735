import state from "./Client.state";
import actions from "./Client.action";
import mutations from "./Client.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
