import onholdService from "../../../services/module-services/screeningsOnhold/onholdService";
import { getSubmit } from "../../../services/control-services/SnackbarService";
import { Warning } from "../../../models/SwalMessages";
const OnholdHandler = {
  inject: ["confirmation", "showWarning", "snackbar"],
  mixins: [onholdService],
  methods: {
    ReleaseStatusUpdate(selectedItem, onSuccessFunc) {
      let warning = new Warning();
      warning.title = "Release Screening?";
      warning.text = "Are you sure you want to release this Screening?";
      warning.confirmButtonClass = "md-button md-info";
      warning.hasGoback = true;
      this.showWarning(warning);
      this.submitSubscription = getSubmit().subscribe(() => {
        this.snackbar.showWarning = false;
        let postformData = {};
        postformData.id = selectedItem.screeningID;
        postformData.status = "released";
        this.updateHoldStatus(postformData, onSuccessFunc);
        this.submitSubscription.unsubscribe();
      });
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
  },

  provide() {
    return {
      ReleaseStatusUpdate: this.ReleaseStatusUpdate,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default OnholdHandler;

export const withOnholdHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<OnholdHandler><WrappedComponent v-bind="$props"></WrappedComponent></OnholdHandler>`,
    components: { OnholdHandler, WrappedComponent },
  };
};
