export default {
  States: "States",
  AddressTypes: "AddressTypes",
  CountryCodes: "CountryCodes",
  Months: "Months",
  Specializations: "Specializations",
  Subspecializations: "Subspecializations",
  Specialities: "Specialities",
  EyeOrientations: "EyeOrientations",
  ScreeningTypes: "ScreeningTypes",
  Languages: "Languages",
  Qualifications: "Qualifications",
  Regions: "Regions",
  Insurance: "Insurance",
  MessageType: "MessageType",
  Surgery: "Surgery",
  Diabetes: "Diabetes",
  Cancer: "Cancer",
};
