import { mapActions } from "vuex";
export default {
  data() {
    return {
      currentlyselectedfinding: { value: {} },
      addditionalfinding: {},
      commonFindings: { list: [] },
      selectedCommonFindings: {
        left: [],
        right: [],
      },
      additionalFindings: { list: [] },
      selectedAdditionalFindings: {
        left: [],
        right: [],
      },
      findingsMaster: {
        page: "",
        masterID: 0,
        masterName: "",
        displayValue: "",
        showManageFindings: false,
        isFindingsDeleting: false,
        isNormalOUChecked: false,
      },
      progress: {
        isDetailsLoading: false,
        isFindingsLoading: false,
      },

      normalFindingsId: 0,
    };
  },

  methods: {
    ...mapActions("findings", ["getFindings", "deleteFindings"]),
    loadFindings(callback) {
      // console.log("this.findingsMaster", this.findingsMaster);
      // console.log("this.page is :", this.findingsMaster.page);
      this.progress.isFindingsLoading = true;
      this.getFindings({
        FindingsMasterName: this.masterName,
        //ScreeningID: this.findingsMaster.screeningId,
      })
        .then((res) => {
          this.progress.isFindingsLoading = true;
          // console.log("res.commonFindings", res.commonFindings);
          this.commonFindings.list = res.commonFindings.map((x) => ({
            ...x,
            findingMasterName: res.masterName,
          }));
          this.additionalFindings.list = res.additionalFindings.map((x) => ({
            ...x,
            findings: x.findings.map((y) => ({
              ...y,
              findingMasterName: res.masterName,
            })),
          }));

          if (this.findingsMaster.page == "screeningeval")
            this.commonFindings.list.splice(0, 0, {
              findingsID: 0,
              findingsName: "Normal",
              findingsMasterID: res.masterID,
              findingMasterName: res.masterName,
              findingsGroupID: 0,
              screeningID: null,
              type: null,
              eyeSection: "Both",
              isGlobal: false,
              parentFindingsID: 0,
              recommendation: "Normal",
              diagnosys: "Normal",
            });
          this.findingsMaster.masterID = res.masterID;
          this.findingsMaster.masterName = res.masterName;
          if (callback) callback();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.progress.isFindingsLoading = false;
        });
    },

    resetSelectedFindings() {
      this.selectedCommonFindings.left = [];
      this.selectedCommonFindings.right = [];
      this.selectedAdditionalFindings.left = [];
      this.selectedAdditionalFindings.right = [];
      this.currentlyselectedfinding = [];
      this.findingsMaster.isNormalOUChecked = false;
    },
    setToNormalOU() {
      this.selectedCommonFindings.left = [this.normalFindingsId];
      this.selectedCommonFindings.right = [this.normalFindingsId];
      this.selectedAdditionalFindings.left = [];
      this.selectedAdditionalFindings.right = [];
      this.findingsMaster.isNormalOUChecked = true;
    },
    // checkedValidation(control, field) {
    //   if (control.checked) {
    //     if (control.value === "Normal") {
    //       this.findings[field].common = ["Normal"];
    //     } else if (this.findings[field].common.includes("Normal")) {
    //       this.NormalOURadiobuttonChecked.value = false;
    //       this.findings[field].common = this.findings[field].common.filter(
    //         (x) => x !== "Normal"
    //       );
    //     }
    //   } else if (control.value === "Normal") {
    //     control.checked = true;
    //     this.findings[field].common.push("Normal");
    //   } else if (
    //     //Normal Checkbox donot uncheck condition
    //     control.value != "Normal" &&
    //     this.findings[field].common.length === 0
    //   ) {
    //     this.findings[field].common.push("Normal");
    //   } //both Radio button Enabled Condition
    //   if (
    //     this.findings.left.common == "Normal" &&
    //     this.findings.right.common == "Normal"
    //   ) {
    //     this.NormalOURadiobuttonChecked.value = true;
    //     this.ODList = ["Normal"];
    //     this.OSList = ["Normal"];
    //   }
    //   //this.ODOSListDatawithCheckbox(field, control.value);
    // },
    // RadiobuttonValidation() {
    //   this.findings.left.common = ["Normal"];
    //   this.findings.right.common = ["Normal"];
    //   this.findings.left.additional = [];
    //   this.findings.right.additional = [];
    //   this.ODList = ["Normal"];
    //   this.OSList = ["Normal"];
    // },
    // Resetclick() {
    //   this.findings.left.additional = [];
    //   this.findings.right.additional = [];
    //   this.findings.left.common = [];
    //   this.findings.right.common = [];
    //   this.NormalOURadiobuttonChecked.value = false;
    //   this.ODList = [];
    //   this.OSList = [];
    // },
    // AddFindingsDoneClick() {
    //   this.AddFindingDialogShow.value = false;
    //   if (this.AddFindingText.text === "") return;
    //   if (
    //     this.AddFindingDirectionModel.Direction.includes("OD") &&
    //     this.AddFindingDirectionModel.Direction.includes("OS")
    //   ) {
    //     this.findings.left.common.push(this.AddFindingText.text);
    //     this.findings.right.common.push(this.AddFindingText.text);
    //   } else if (this.AddFindingDirectionModel.Direction.includes("OD"))
    //     this.findings.left.common.push(this.AddFindingText.text);
    //   else if (this.AddFindingDirectionModel.Direction.includes("OS"))
    //     this.findings.right.common.push(this.AddFindingText.text);
    // },
    // AddFindingclick() {
    //   this.AddFindingDirectionModel.Direction = [];
    //   this.AddFindingDialogShow.value = true;
    //   this.AddFindingText.text = "";
    // },
    // AddFindingsListClick(field, option) {
    //   if (field === "left") {
    //     if (!this.ODList.includes(option)) {
    //       if (this.ODList.includes("Normal")) {
    //         this.ODList = this.ODList.filter((x) => x !== "Normal");
    //         this.findings[field].common = this.findings[field].common.filter(
    //           (x) => x !== "Normal"
    //         );
    //         this.NormalOURadiobuttonChecked.value = false;
    //       }
    //       this.ODList.push(option);
    //     } else {
    //       this.ODList = this.ODList.filter((x) => x !== option);
    //     }
    //     console.log("li", this.ODList);
    //   } else if (field === "right") {
    //     if (!this.OSList.includes(option)) {
    //       if (this.OSList.includes("Normal")) {
    //         this.OSList = this.OSList.filter((x) => x !== "Normal");
    //         this.findings[field].common = this.findings[field].common.filter(
    //           (x) => x !== "Normal"
    //         );
    //         this.NormalOURadiobuttonChecked.value = false;
    //       }
    //       this.OSList.push(option);
    //     } else {
    //       this.OSList = this.OSList.filter((x) => x !== option);
    //     }
    //   }
    // },
    // ODOSListDatawithCheckbox(field, option) {
    //   if (field === "left") {
    //     if (!this.ODList.includes(option)) {
    //       if (option == "Normal") {
    //         this.ODList = [];
    //       } else {
    //         if (this.ODList.includes("Normal")) {
    //           this.findings[field].common = this.findings[field].common.filter(
    //             (x) => x !== "Normal"
    //           );
    //         }
    //       }
    //       this.ODList.push(option);
    //       console.log("Add OD Checkbox", this.ODList);
    //     } else {
    //       this.ODList = this.ODList.filter((x) => x !== option);
    //       console.log("remove OD click", this.ODList);
    //     }
    //   } else if (field === "right") {
    //     if (!this.ODList.includes(option)) {
    //       if (option == "Normal") {
    //         this.ODList = [];
    //       } else {
    //         if (this.ODList.includes("Normal")) {
    //           this.findings[field].common = this.findings[field].common.filter(
    //             (x) => x !== "Normal"
    //           );
    //         }
    //       }
    //       this.ODList.push(option);
    //       console.log("Add OD Checkbox", this.ODList);
    //     } else {
    //       this.ODList = this.ODList.filter((x) => x !== option);
    //       console.log("remove OD click", this.ODList);
    //     }
    //   }
    // },
    processDeleteFindings(findingsId) {
      this.findingsMaster.isFindingsDeleting = true;
      return this.deleteFindings({
        findingsMasterName: this.masterName,
        id: findingsId,
      }).finally(() => (this.findingsMaster.isFindingsDeleting = false));
    },
  },

  provide() {
    return {
      commonFindings: this.commonFindings,
      selectedCommonFindings: this.selectedCommonFindings,
      additionalFindings: this.additionalFindings,
      selectedAdditionalFindings: this.selectedAdditionalFindings,
      findingsMaster: this.findingsMaster,
      currentlyselectedfinding: this.currentlyselectedfinding,
      masterName: this.findingsMaster.masterName,
      progress: this.progress,
    };
  },
};
