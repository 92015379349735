import { mapActions } from "vuex";

export default {
  data() {
    return {
      files: {
        list: [],
      },
    };
  },
  methods: {
    ...mapActions("files", ["getFiles"]),
    loadFiles() {
      this.getFiles(this.files.options).then((res) => {
        this.files.list = res.map((x) => ({ id: x.id, link: x.links[0].href }));
      });
    },
  },
};
