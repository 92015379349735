import axios from "axios";
import { handleResponse, handleError } from "../../api/apiUtils";
import { handleLoginResponse, logout } from "./SharedFunctionService";
var qs = require("qs");

// eslint-disable-next-line camelcase
const IDP = process.env.VUE_APP_IDP_URL;
const API = process.env.VUE_APP_API_URL;

const API_Url = { IDP, Default: API };
let cancelToken = {};

function loadTokenCancel(cancelTokenKey) {
  if (typeof cancelToken[cancelTokenKey] !== typeof undefined) {
    cancelToken[cancelTokenKey].cancel(
      "Operation canceled due to new request."
    );
  }
  cancelToken[cancelTokenKey] = axios.CancelToken.source();
}

export function checkAccessTokenExpire() {
  return new Promise((resolve, reject) => {
    if (
      localStorage.getItem("authorization") != null &&
      localStorage.getItem("refresh_token") != null
    ) {
      if (
        new Date().getTime() >
        new Date(localStorage.getItem("expires_in_date_time")).getTime()
      ) {
        postForm(
          "connect/token",
          {
            client_id: "GlobeCheck",
            client_secret: "secret",
            grant_Type: "refresh_token",
            refresh_token: localStorage.getItem("refresh_token"),
          },
          "IDP"
        )
          .then((res) => {
            handleLoginResponse(
              res,
              localStorage.getItem("keepMeSignedIn") != null
            );
            resolve();
          })
          .catch((error) => {
            logout();
            reject(error);
          });
      } else resolve();
    } else resolve();
  });
}

function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("authorization"),
    Org_Id: localStorage.getItem("Org_Id"),
  };
}

export function get(MethodURL, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .get(APIRoot + MethodURL, { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function getWithHeader(MethodURL, Header, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    headers.types = Header;
    return axios
      .get(APIRoot + MethodURL, { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function getWithoutAuth(MethodURL, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .get(APIRoot + MethodURL, { headers })
      .then(handleResponse)
      .catch((error) => {
        console.log(error);
      });
  });
}

export function getWithCancel(MethodURL, cancelTokenKey, UrlKey) {
  loadTokenCancel(cancelTokenKey);
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .get(APIRoot + MethodURL, {
        headers,
        cancelToken: cancelToken[cancelTokenKey].token,
      })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function getByQuery(MethodURL, QueryData, UrlKey) {
  Object.keys(QueryData).forEach(
    (k) => QueryData[k] == null && delete QueryData[k]
  );
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .get(APIRoot + MethodURL + "?" + qs.stringify(QueryData), { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function getByQueryWithoutAuth(MethodURL, QueryData, UrlKey) {
  Object.keys(QueryData).forEach(
    (k) => QueryData[k] == null && delete QueryData[k]
  );
  const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
  const headers = getHeaders();
  return axios
    .get(
      APIRoot +
        MethodURL +
        "?" +
        qs.stringify(QueryData, { arrayFormat: "repeat" }),
      { headers }
    )
    .then(handleResponse)
    .catch(handleError);
}

export function getByQueryWithCancel(
  MethodURL,
  QueryData,
  cancelTokenKey,
  UrlKey
) {
  loadTokenCancel(cancelTokenKey);
  Object.keys(QueryData).forEach(
    (k) => QueryData[k] == null && delete QueryData[k]
  );
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .get(APIRoot + MethodURL + "?" + qs.stringify(QueryData), {
        headers,
        cancelToken: cancelToken[cancelTokenKey].token,
      })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function del(MethodURL, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .delete(APIRoot + MethodURL, { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function post(MethodURL, PostData, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .post(APIRoot + MethodURL, JSON.stringify(PostData), { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function postWithoutAuth(MethodURL, PostData, UrlKey) {
  const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
  const headers = getHeaders();
  return axios
    .post(APIRoot + MethodURL, JSON.stringify(PostData), { headers })
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
    });
}

export function postAndGetBlob(path, payload, UrlKey) {
  return checkAccessTokenExpire().then(async () => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    const result = await fetch(`${APIRoot}${path}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: headers,
    }).then(async (v) => {
      if (v.ok) {
        const blob = await v.blob();
        return Promise.resolve({
          resp: v,
          blob,
        });
      }
      return Promise.reject(v.statusText);
    });
    return result;
  });
}

export function patch(MethodURL, PatchData, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .patch(APIRoot + MethodURL, JSON.stringify(PatchData), { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function put(MethodURL, PostData, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .put(APIRoot + MethodURL, JSON.stringify(PostData), { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function putstatus(MethodURL, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = getHeaders();
    return axios
      .put(APIRoot + MethodURL, { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function putFile(MethodURL, UploadedFile) {
  return checkAccessTokenExpire().then(() => {
    return fetch(UploadedFile.link)
      .then((res) => res.blob())
      .then((blob) => {
        return post(MethodURL, [
          {
            name: UploadedFile.name,
            contentType: UploadedFile.contentType,
            description: UploadedFile.description,
          },
        ])
          .then((res) => {
            if (res && res.length > 0 && res[0].link) {
              return uploadBlobToAzure(
                blob,
                res[0].link,
                UploadedFile.contentType
              );
            } else return "Something went wrong.";
          })
          .catch((error) => {
            throw error;
          });
      });
  });
}
export function putSingleFile(MethodURL, UploadedFile, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    return fetch(UploadedFile.link)
      .then((res) => res.blob())
      .then((blob) => {
        return post(
          MethodURL,
          {
            name: UploadedFile.name,
            contentType: UploadedFile.contentType,
            description: UploadedFile.description,
          },
          UrlKey
        )
          .then((res) => {
            if (res && res.link) {
              return uploadBlobToAzure(
                blob,
                res.link,
                UploadedFile.contentType
              );
            } else return "Something went wrong.";
          })
          .catch((error) => {
            throw error;
          });
      });
  });
}
export function uploadBlobToAzure(blob, link, contentType, uploadProgress) {
  const config = {
    method: "put",
    url: link,
    headers: {
      "x-ms-blob-type": "blockblob",
      "Content-Type": contentType,
    },
    data: blob,
    onUploadProgress: (ev) => {
      if (uploadProgress) {
        const progress = (ev.loaded / ev.total) * 100;
        uploadProgress.handleUploadProgress(
          uploadProgress.path,
          uploadProgress.fileId,
          Math.round(progress)
        );
      }
    },
  };
  return axios(config).then(handleResponse).catch(handleError);
}

export function postForm(MethodURL, PostData, UrlKey) {
  const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  return axios
    .post(APIRoot + MethodURL, qs.stringify(PostData), { headers })
    .then(handleResponse)
    .catch(handleError);
}

export function putForm(MethodURL, PostData, UrlKey) {
  const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  return axios
    .put(APIRoot + MethodURL, qs.stringify(PostData), { headers })
    .then(handleResponse)
    .catch(handleError);
}

export function postFormWithAuth(MethodURL, PostData, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = {
      ...getHeaders(),
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .post(APIRoot + MethodURL, qs.stringify(PostData), { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}

export function putFormWithAuth(MethodURL, PostData, UrlKey) {
  return checkAccessTokenExpire().then(() => {
    const APIRoot = UrlKey ? API_Url[UrlKey] : API_Url.Default;
    const headers = {
      ...getHeaders(),
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .put(APIRoot + MethodURL, qs.stringify(PostData), { headers })
      .then(handleResponse)
      .catch(handleError);
  });
}
