import * as HttpService from "../services/common-services/HttpService";

export function getAdminUsers(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Admins",
    QueryData,
    "userApi.getAdminUsers"
  );
}

export function getAdminUsersCount(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/User/Admin/Count",
    QueryData,
    "userApi.getAdminUsersCount"
  );
}

export function RegisterAdmin(request) {
  return HttpService.post("api/v1/Register/Admin", request);
}

export function getAdmin(adminId) {
  return HttpService.get("api/v1/Admin/" + adminId);
}

export function updateAdmin(request) {
  return HttpService.put("api/v1/Admin/" + request.id, request);
}

export function updateAdminApproval(request) {
  return HttpService.put("api/v1/Admin/" + request.id + "/Approvals", request);
}

export function createAdmin(request) {
  return HttpService.post("api/v1/Admin", request);
}

export function deleteAdmin(adminId) {
  return HttpService.del("api/v1/Admin/" + adminId);
}

export function admindeactive(request) {
  return HttpService.put("api/v1/Admin/" + request.id + "/Active", request);
}
