import { mapActions } from "vuex";
import { DoctorSearchParams } from "../../../models/Doctor";
export default {
  data() {
    return {
      doctorSearchForm: new DoctorSearchParams(),
    };
  },
  methods: {
    ...mapActions("doctor", ["searchDoctorsWithQueryData"]),
    searchDoctorWithParams(doctorSearchForm) {
      let searchParams = {
        pgNum: doctorSearchForm.pgNum,
        pgSze: doctorSearchForm.pgSze,
        name: doctorSearchForm.name,
        qualifictn: doctorSearchForm.qualifictn,
        speclztn: doctorSearchForm.speclztn,
        speclty: doctorSearchForm.speclty,
        subSpeclztn: doctorSearchForm.subSpeclztn,
        addr1: doctorSearchForm.addr1,
        cty: doctorSearchForm.cty,
        state: doctorSearchForm.state,
        country: doctorSearchForm.country,
        zip: doctorSearchForm.zip,
        ordBy: doctorSearchForm.ordBy,
      };
      this.searchDoctorsWithQueryData(searchParams);
    },
    clearDoctorSearchForm() {
      return new DoctorSearchParams();
    },
  },
  provide() {
    return {
      doctorSearchForm: this.doctorSearchForm,
    };
  },
};
