import ListLayoutFeatures from "./ListLayoutFeatures";
export default {
  inject: ["feature"],
  data() {
    return {
      featureDetails: ListLayoutFeatures[this.feature] || {},
    };
  },
  provide() {
    return {
      featureDetails: this.featureDetails,
    };
  },
};
