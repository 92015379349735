export default {
  methods: {
    PasswordEnforcementCheck(email, password) {
      let isValid = true;
      let passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_-])[A-Za-z\d@$!%*#?&_-]{10,50}$/;
      isValid = password.match(passwordRegex) && email !== password;
      return isValid;
    },
  },
};
