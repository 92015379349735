import * as api from "../../../api/technicianApi";
import * as types from "./technician.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  updateTechnician({ commit }, request) {
    return api.updateTechnician(request);
  },
  // eslint-disable-next-line no-unused-vars
  getTechnician({ commit }, id) {
    return api.getTechnician(id);
  },
  // eslint-disable-next-line no-unused-vars
  updateTechnicianApproval({ commit }, request) {
    return api.updateTechnicianApproval(request);
  },
  // eslint-disable-next-line no-unused-vars
  RegisterTechnician({ commit }, request) {
    return api.RegisterTechnician(request);
  },
  // eslint-disable-next-line no-unused-vars
  createTechnician({ commit }, request) {
    return api.createTechnician(request);
  },
  // eslint-disable-next-line no-unused-vars
  deleteTechnician({ commit }, request) {
    return api.deleteTechnician(request);
  },

  // eslint-disable-next-line no-unused-vars
  Techniciandeactive({ commit }, request) {
    return api.Techniciandeactive(request);
  },
  // eslint-disable-next-line no-unused-vars
  getTechnicianUsersCount({ commit }, QueryData) {
    return api.getTechnicianUsersCount(QueryData);
  },
  // eslint-disable-next-line no-unused-vars
  getTechnicianUsers({ commit }, QueryData) {
    return api.getTechnicianUsers(QueryData).then((res) => {
      commit(types.GET_TechnicianUsers, res);
      return res;
    });
  },
};
