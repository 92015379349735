<template>
  <md-field :class="error && 'md-invalid'" :md-clearable="clearable">
    <md-icon v-if="icon" class="input-icon">{{ icon }}</md-icon>
    <!-- <label>{{ label }}</label> -->

    <md-input
      v-model="localValue"
      autocomplete="off"
      v-bind="$props"
      @keyup.native="onKeyUp"
      :disabled="isdisabled"
      :readonly="readonly"
      :class="className"
    ></md-input>
    <md-tooltip v-if="tooltip" md-direction="right">{{ tooltip }}</md-tooltip>
    <span class="md-error">{{ error }}</span>
    <span>{{ sufix }}</span>
  </md-field>
</template>
<script>
export default {
  name: "TextInput",
  props: {
    label: String,
    type: String,
    placeholder: String,
    value: String,
    error: String,
    required: Boolean,
    autofocus: Boolean,
    clearable: Boolean,
    icon: String,
    tooltip: String,
    isdisabled: Boolean,
    sufix: String,
    readonly: Boolean,
    className: String,
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onKeyUp(event) {
      if (event.key === "Enter") {
        this.$emit("submit");
      } else {
        this.$emit("keyup");
      }
    },
  },
};
</script>
