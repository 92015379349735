import * as api from "../../../api/masterApi";
import MasterDataFeatures from "../../../constants/MasterDataFeatures";
import * as types from "./master.action.types";

export default {
  getRoleList({ commit }) {
    return api.getRoleList().then((res) => {
      commit(types.GET_ROLE_LIST, res);
      return res;
    });
  },

  getStateList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.States).then((res) => {
      commit(types.GET_STATE_LIST, res);
      return res;
    });
  },

  getAddressTypeList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.AddressTypes)
      .then((res) => {
        commit(types.GET_ADDRESSTYPE_LIST, res);
        return res;
      });
  },

  getCountryCodeList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.CountryCodes)
      .then((res) => {
        commit(types.GET_COUNTRYCODE_LIST, res);
        return res;
      });
  },

  getMonthList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Months).then((res) => {
      commit(types.GET_MONTH_LIST, res);
      return res;
    });
  },

  getSpecializationList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.Specializations)
      .then((res) => {
        commit(types.GET_SPECIALIZATION_LIST, res);
        return res;
      });
  },

  getSubspecializationList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.Subspecializations)
      .then((res) => {
        commit(types.GET_SUBSPECIALIZATION_LIST, res);
        return res;
      });
  },

  getSpecialitiesList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.Specialities)
      .then((res) => {
        commit(types.GET_SPECIALITIES_LIST, res);
        return res;
      });
  },

  getEyeOrientationList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.EyeOrientations)
      .then((res) => {
        commit(types.GET_EYEORIENTATION_LIST, res);
        return res;
      });
  },

  getScreeningTypeList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.ScreeningTypes)
      .then((res) => {
        commit(types.GET_SCREENINGTYPE_LIST, res);
        return res;
      });
  },
  getLanguagesList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Languages).then((res) => {
      commit(types.GET_LANGUAGES_LIST, res);
      return res;
    });
  },
  getInsuranceList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Insurance).then((res) => {
      commit(types.GET_INSURANCE_LIST, res);
      return res;
    });
  },
  getMessageTypeList({ commit }) {
    return api.getMessageType(MasterDataFeatures.MessageType).then((res) => {
      commit(types.GET_MESSAGE_TYPE, res);
      return res;
    });
  },
  getQualificationList({ commit }) {
    return api
      .getOpenLookupList(MasterDataFeatures.Qualifications)
      .then((res) => {
        commit(types.GET_QUALIFICATION_LIST, res);
        return res;
      });
  },
  getRegionList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Regions).then((res) => {
      commit(types.GET_REGION_LIST, res);
      return res;
    });
  },
  getOrganisationList({ commit }) {
    return api.getOrganisationList().then((res) => {
      commit(types.GET_ORGANISATION_LIST, res);
      return res;
    });
  },
  getSpherePowerList({ commit }) {
    return api.getSpherePowerList().then((res) => {
      commit(types.GET_SPHEREPOWER_LIST, res);
      return res;
    });
  },
  getCylindricalPowerList({ commit }) {
    return api.getCylindricalPowerList().then((res) => {
      commit(types.GET_CYLINDICALPOWER_LIST, res);
      return res;
    });
  },
  getAxisPowerList({ commit }) {
    return api.getAxisPowerList().then((res) => {
      commit(types.GET_AXISPOWER_LIST, res);
      return res;
    });
  },
  getAdditionalPowerList({ commit }) {
    return api.getAdditionalPowerList().then((res) => {
      commit(types.GET_ADDITIONALPOWER_LIST, res);
      return res;
    });
  },
  getDistancesList({ commit }) {
    return api.getDistancesPowerList().then((res) => {
      commit(types.GET_DISTANCES_LIST, res);
      return res;
    });
  },
  getDeviceStatus({ commit }) {
    return api.getDeviceStatus().then((res) => {
      commit(types.GET_DEVICE_STATUS, res);
      return res;
    });
  },
  getBusinessType({ commit }) {
    return api.getBusinessType().then((res) => {
      commit(types.GET_BUSINESS_TYPE, res);
      return res;
    });
  },
  getSurgeryList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Surgery).then((res) => {
      commit(types.GET_SURGERY, res);
      return res;
    });
  },
  getDiabetesList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Diabetes).then((res) => {
      commit(types.GET_DIABETES, res);
      return res;
    });
  },
  getCancerList({ commit }) {
    return api.getOpenLookupList(MasterDataFeatures.Cancer).then((res) => {
      commit(types.GET_CANCER, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getLookupValuesByType({ commit }, Type) {
    return api.GetLookupValuesByType(Type).then((res) => {
      // console.log(Type);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getLookupValueByID({ commit }, ID) {
    return api.GetLookupValueByID(ID).then((res) => {
      return res;
    });
  },
  //eslint-disable-next-line no-unused-vars
  GetAllMasterType({ commit }) {
    return api.GetAllMasterType();
  },

  //eslint-disable-next-line no-unused-vars
  deleteLookupValue({ commit, dispatch }, { id, masterName }) {
    return api.DeleteLookupValue(id).then((res) => {
      dispatch("clearLookupMaster", masterName);
      return res;
    });
  },
  clearLookupMaster({ commit }, masterName) {
    console.log(masterName);
    if (masterName == "Regions") commit(types.CLEAR_REGION_LIST);
    else if (masterName == "States") commit(types.CLEAR_STATE_LIST);
    else if (masterName == "CountryCodes") commit(types.CLEAR_COUNTRYCODE_LIST);
    else if (masterName == "Specializations")
      commit(types.CLEAR_SPECIALIZATIONS_LIST);
    else if (masterName == "Subspecializations")
      commit(types.CLEAR_SUBSPECIALIZATIONS_LIST);
    else if (masterName == "Specialities") commit(types.CLEAR_SPECIALITY_LIST);
    else if (masterName == "Languages") commit(types.CLEAR_LANGUAGES_LIST);
    else if (masterName == "Insurance") commit(types.CLEAR_INSURANCE_LIST);
    // if (masterName == "SpherePower") commit(types.CLEAR_SPHEREPOWER_LIST);
    else if (masterName == "CylinderPower")
      commit(types.CLEAR_CYLINDRERPOWER_LIST);
    else if (masterName == "Axis") commit(types.CLEAR_AXIS_LIST);
    else if (masterName == "AdditionalPower")
      commit(types.CLEAR_ADDITIONALPOWER_LIST);
    else if (masterName == "Distance") commit(types.CLEAR_DISTANCE_LIST);
    // if (masterName == "BusinessType") commit(types.CLEAR_BUSINESSTYPE_LIST);
    else if (masterName == "Surgery") commit(types.CLEAR_SURGERY_LIST);
    else if (masterName == "Diabetes") commit(types.CLEAR_DIABETS_LIST);
    else masterName == "Cancer", commit(types.CLEAR_CANCER_LIST);
  },

  //eslint-disable-next-line no-unused-vars
  addLookupValue({ commit, dispatch }, { request, masterName }) {
    return api.AddLookupValue(request).then((res) => {
      dispatch("clearLookupMaster", masterName);
      return res;
    });
  },

  //eslint-disable-next-line no-unused-vars
  updateLookupValue({ commit, dispatch }, { id, request, masterName }) {
    return api.UpdateLookupValue(id, request).then((res) => {
      // console.log("update action");
      dispatch("clearLookupMaster", masterName);
      return res;
    });
  },
};
