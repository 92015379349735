import * as types from "./screenings.action.types";

export default {
  [types.GET_SCREENINGS_AVAILABLE]: (state, res) => {
    state.screeningsAvailable = res;
  },
  [types.DELETE_PROFILE]: (state, res) => {
    state.screeningsAvailableDelete = res;
  },
  [types.GET_FILTER_LIST]: (state, res) => {
    state.screeningsFilter = res;
  },
  [types.GET_SCREENINGS_ONHOLD]: (state, res) => {
    state.screeningsONHOLD = res;
  },
  [types.UPDATE_HOLD]: (state, res) => {
    state.screeningsupdateHold = res;
  },
  [types.REQUEST_HOLD]: (state, res) => {
    state.screeningsRequestHold = res;
  },
  [types.GET_SORT_LIST]: (state, res) => {
    state.screeningsSort = res;
  },
  [types.GET_HOLDSORT_LIST]: (state, res) => {
    state.holdSort = res;
  },
};
