import { AddressDetails } from "./Address";
import { PersonDetails } from "./Person";

export class DoctorRequest {
  id = "";
  speciality = "";
  specialization = "";
  subspecialization = "";
  qualification = "";
  boardCertificate = "";
  caqh = "";
  npi = "";
  license = "";
  insurance = [];
  status = "";
  practice = "";
  webSite = "";
  certificationDate = null;
  businessPhoneNo = "";
  isReader = false;
  userType = null;
  userId = null;
  isApproved = false;
  regions = [];
  organisations = [];
  mailingAddress = new AddressDetails();
  businessAddress = new AddressDetails();
  person = new PersonDetails();
}

export class DoctorSearchParams {
  pgNum = 1;
  pgSze = 10;
  name = "";
  qualifictn = "";
  speclztn = "";
  speclty = "";
  subSpeclztn = "";
  addr1 = "";
  cty = "";
  state = "";
  country = "";
  zip = "";
  ordBy = "";
}
