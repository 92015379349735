import state from "./technician.state";
import actions from "./technician.actions";
import mutations from "./technician.mutation";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
