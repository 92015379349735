import { withManageDoctorHandler } from "../../handlers/module-handlers/doctor/ManageDoctorHandler";

export default [
  {
    path: "doctors",
    component: () => import("./DoctorRoutePage.vue"),
    children: [
      {
        path: ":doctorId/approvals",
        component: withManageDoctorHandler(() =>
          import("./manage-doctor/ManageDoctorPage.vue")
        ),
        meta: { isApproval: true, userType: "Doctor" },
      },
      {
        path: "add",
        component: withManageDoctorHandler(() =>
          import("./manage-doctor/ManageDoctorPage.vue")
        ),
        meta: { userType: "Doctor" },
      },
      {
        path: "edit/:doctorId",
        component: withManageDoctorHandler(() =>
          import("./manage-doctor/ManageDoctorPage.vue")
        ),
        meta: { userType: "Doctor" },
      },
    ],
  },
  {
    path: "readers",
    component: () => import("./DoctorRoutePage.vue"),
    children: [
      {
        path: ":doctorId/approvals",
        component: withManageDoctorHandler(() =>
          import("./manage-doctor/ManageDoctorPage.vue")
        ),
        meta: { isApproval: true, userType: "Reader" },
      },
      {
        path: "add",
        component: withManageDoctorHandler(() =>
          import("./manage-doctor/ManageDoctorPage.vue")
        ),
        meta: { userType: "Reader" },
      },
      {
        path: "edit/:doctorId",
        component: withManageDoctorHandler(() =>
          import("./manage-doctor/ManageDoctorPage.vue")
        ),
        meta: { userType: "Reader" },
      },
    ],
  },
];
