import * as api from "../../../api/findingsApi";

export default {
  // eslint-disable-next-line no-unused-vars
  addFindings({ commit }, request) {
    return api.createFindings(request);
  },
  // eslint-disable-next-line no-unused-vars
  updateFindings({ commit }, request) {
    return api.updateFindings(request);
  },
  // eslint-disable-next-line no-unused-vars
  getFindings({ commit }, QueryData) {
    return api.getFindings(QueryData);
  },
  // eslint-disable-next-line no-unused-vars
  deleteFindings({ commit }, { findingsMasterName, id }) {
    return api.deleteFindings(findingsMasterName, id);
  },
};
