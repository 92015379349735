import * as api from "../../../api/screeningsApi";
import * as types from "./screenings.action.types";

export default {
  getScreeningsAvailable({ commit }, QueryData) {
    return api.getScreeningsAvailable(QueryData).then((res) => {
      commit(types.GET_SCREENINGS_AVAILABLE, res);
      return res;
    });
  },
  deleteScreeningsAvailable({ commit }, id) {
    commit(types.DELETE_PROFILE);
    return api.deleteScreeningsAvailable(id).then((res) => {
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  getScreeningsAvailableCount({ commit }, QueryData) {
    return api.getScreeningsAvailableCount(QueryData);
  },
  getDurationList({ commit }) {
    return api.getDurationList().then((res) => {
      commit(types.GET_FILTER_LIST, res);
      return res;
    });
  },
  getSortList({ commit }) {
    return api.getScrSortList().then((res) => {
      commit(types.GET_SORT_LIST, res);
      return res;
    });
  },
  getHoldSortList({ commit }) {
    return api.getHoldSortList().then((res) => {
      commit(types.GET_HOLDSORT_LIST, res);
      return res;
    });
  },
  getScreeningsOnhold({ commit }, QueryData) {
    return api.getScreeningsOnhold(QueryData).then((res) => {
      commit(types.GET_SCREENINGS_ONHOLD, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  getScreeningsOnholdCount({ commit }, QueryData) {
    return api.getScreeningsOnholdCount(QueryData);
  },
  updateHold({ commit }, onholdscreeningData) {
    return api.updateHold(onholdscreeningData).then((res) => {
      commit(types.UPDATE_HOLD, res);
      return res;
    });
  },
  requestHold({ commit }, requestscreeningData) {
    return api.requestHold(requestscreeningData).then((res) => {
      commit(types.REQUEST_HOLD, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  deleteAllTestPatients({ commit }, orgId) {
    return api.deleteAllTestPatients(orgId).then((res) => {
      return res;
    });
  },
};
