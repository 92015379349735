import ProviderModuleService from "../../../services/module-services/providers/ProviderModuleService";
import ListHandler from "../../common-handlers/ListHandler";

const ProviderModuleHandler = {
  mixins: [ProviderModuleService],
  mounted() {
    this.listFilters.doctorType = "Doctor";
  },
  provide() {
    return {
      getList: this.getListFunc,
      getListCount: this.getListCountFunc,
    };
  },
  template: `<ListHandler><slot></slot></ListHandler>`,
  components: {
    ListHandler,
  },
};

export default ProviderModuleHandler;

export const withProviderModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ProviderModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></ProviderModuleHandler>`,
    components: { ProviderModuleHandler, WrappedComponent },
  };
};
