import store from "../../store";

export function handleLoginResponse(res, keepMeSignedIn) {
  if (keepMeSignedIn) {
    localStorage.setItem("access_token", res.access_token);
    localStorage.setItem(
      "authorization",
      res.token_type + " " + res.access_token
    );
    localStorage.setItem("expires_in", res.expires_in);
    let Current = new Date();
    Current.setSeconds(Current.getSeconds() + (res.expires_in - 60));
    localStorage.setItem("expires_in_date_time", Current);
    localStorage.setItem("refresh_token", res.refresh_token);
    localStorage.setItem("scope", res.scope);
    localStorage.setItem("token_type", res.token_type);
    localStorage.setItem("keepMeSignedIn", keepMeSignedIn);
  }
  sessionStorage.setItem("access_token", res.access_token);
  sessionStorage.setItem(
    "authorization",
    res.token_type + " " + res.access_token
  );
  sessionStorage.setItem("expires_in", res.expires_in);
  let Current = new Date();
  Current.setSeconds(Current.getSeconds() + (res.expires_in - 60));
  sessionStorage.setItem("expires_in_date_time", Current);
  sessionStorage.setItem("refresh_token", res.refresh_token);
  sessionStorage.setItem("scope", res.scope);
  sessionStorage.setItem("token_type", res.token_type);
}

export function logout() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.pathname = "/login";
}

export function downloadURI(url, fileName) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0;
    var v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function addDaysToDate(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const getThumbnailLink = (links) => {
  if (links && links.length > 0) {
    let SelectedObj =
      links.find((x) => x.rel === "Thumbnail") ||
      links.find((x) => x.rel === "Original");
    if (SelectedObj) return SelectedObj.href;
  }
};
export const getOriginalLink = (links) => {
  if (links && links.length > 0) {
    let SelectedObj =
      links.find((x) => x.rel === "Original") ||
      links.find((x) => x.rel === "Thumbnail");
    if (SelectedObj) return SelectedObj.href;
  }
};

export const getFileTypeByName = (fileName) => {
  let NameArr = String(fileName).split(".");
  if (NameArr.length > 0) return NameArr[NameArr.length - 1];
};

export const authorizePermission = (permission) => {
  if (permission) {
    const permissions = store.state.profile.userClaims
      ? JSON.parse(JSON.stringify(store.state.profile.userClaims))
      : [];
    if (permissions.length > 0) return permissions.includes(permission);
  }
  return true;
};

export function UTCTimetoLocalTime(date) {
  let timeZone =
    sessionStorage.getItem("timeZone") || "GMT-05:00 America/New_York";

  timeZone = timeZone.split(" ")[1];

  const currentDate = new Date(
    new Date(
      date.getTime() - new Date().getTimezoneOffset() * 60 * 1000
    ).toLocaleString("en-us", { timeZone })
  );

  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function getLatLngByZipcode(zipcode) {
  // eslint-disable-next-line no-undef
  var geocoder = new google.maps.Geocoder();
  var address = zipcode;
  var latitude;
  var longitude;
  return new Promise((res) => {
    geocoder.geocode(
      { address: "zipcode " + address },
      function (results, status) {
        // eslint-disable-next-line no-undef
        if (status == google.maps.GeocoderStatus.OK) {
          latitude = results[0].geometry.location.lat();
          longitude = results[0].geometry.location.lng();
          res([latitude, longitude]);
        } else res();
      }
    );
  });
}
