export class Success {
  title = "Success";
  text = "";
  type = "success";
  confirmButtonClass = "md-button md-info";
  buttonsStyling = false;
  timer = 3000;
}

export class Error {
  title = "Failed";
  text = "";
  type = "error";
  confirmButtonClass = "md-button md-info";
  buttonsStyling = false;
  timer = 3000;
}

export class Warning {
  title = "Warning";
  text = "";
  type = "warning";
  confirmButtonClass = "md-button md-info";
  buttonsStyling = false;
  timer = 3000;
  hasGoback = false;
}

export class Information {
  title = "Information";
  text = "";
  type = "infomation";
  confirmButtonClass = "md-button md-info";
  buttonsStyling = false;
  timer = 3000;
}
