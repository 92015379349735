import ListLayoutService from "../../../services/layout-services/ListLayoutService";
import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";
import MasterListService from "../../../services/module-services/MasterData/MasterListService";
const MasterDataListHandler = {
  inject: ["confirmation", "feature"],
  mixins: [ListLayoutService, MasterListService],
  data() {
    return {};
  },
  watch: {},
  methods: {
    handleDeleteClick(selectedItem) {
      this.confirmation.title = "Delete MasterData?";
      this.confirmation.description =
        "Are you sure you want to delete this MasterData?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.deleteSelectedMasterData(selectedItem.lookupId);
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  template: "<div><slot></slot></div>",
  provide() {
    return {
      handleDeleteClick: this.handleDeleteClick,
      handleEditClick: this.handleEditClick,
    };
  },
  components: {},
  created() {},
};

export default MasterDataListHandler;

export const withMasterDataListHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<MasterDataListHandler><WrappedComponent v-bind="$props"></WrappedComponent></MasterDataListHandler>`,
    components: { MasterDataListHandler, WrappedComponent },
  };
};
