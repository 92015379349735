import * as types from "./profile.action.types";

export default {
  [types.GET_PROFILE]: (state, res) => {
    state.userProfile = res;
  },
  [types.GET_PROFILE_CLAIMS]: (state, res) => {
    state.userClaims = res.permissions;
  },
  [types.GET_NOTIFICATIONPREFERENCES]: (state, res) => {
    state.userNotificationperference = res;
  },
  [types.GET_PROFILE_ATTRIBUTES]: (state, res) => {
    if (res) state.userAttributes = res;
    state.userAttributes.isLoaded = true;
  },
};
