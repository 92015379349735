<template>
  <ValidationListener
    @getIsValid="(isValid) => (profileForm.isValid = isValid)"
  >
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header>
              <div class="card-icon">
                <i class="fas far fa-user" />
              </div>
              <h4 class="title">MY PROFILE</h4>
            </md-card-header>

            <md-card-content>
              <div
                class="
                  d-flex
                  justify-content-center
                  align-item-center
                  mr-5
                  small-size-column
                "
              >
                <!-- Upload image -->
                <!-- <div class="mr-1">
                  <image-upload
                    class="profile-images"
                    @change.native="uploadFile($event)"
                    :uploadedImage="url"
                  ></image-upload>
                </div> -->
                <div v-show="isEnabled == true">
                  <file-view-handler
                    :options="{
                      feature: 'User',
                      featureId: app.userProfile.id,
                      category: 'PROFILE',
                    }"
                    v-slot="{ uploadedFiles }"
                  >
                    <file-upload-component-handler
                      ref="fileUpload"
                      :options="{
                        feature: 'User',
                        featureId: app.userProfile.id,
                        category: 'PROFILE',
                        type: 'Single',
                        uploadedFileId:
                          uploadedFiles &&
                          uploadedFiles.length > 0 &&
                          uploadedFiles[0].id,
                      }"
                      v-slot="{ uploadFiles, handleFilesSelected }"
                    >
                      <!-- v-if="uploadedFiles[0] != null" -->
                      <image-upload
                        v-if="uploadedFiles[0] != null"
                        class="profile-images"
                        defaultSrc="admin"
                        @change="handleFilesSelected"
                        @onClick="
                          handleShowImagePreview(
                            getImageFromFiles(uploadFiles, uploadedFiles)
                          )
                        "
                        :uploadedImage="
                          getImageFromFiles(uploadFiles, uploadedFiles)
                        "
                      ></image-upload>
                      <image-upload
                        v-else
                        class="profile-images"
                        defaultSrc="admin"
                        @change="handleFilesSelected"
                        src="@/assets/img/avatar_default.jpg"
                      ></image-upload>
                      <!-- <img
                        v-else
                        class="profile-images"
                        src="@/assets/img/avatar_default.jpg"
                      /> -->
                    </file-upload-component-handler>
                  </file-view-handler>
                </div>
                <div v-show="isEnabled == false">
                  <file-view-handler
                    :options="{
                      feature: 'User',
                      featureId: app.userProfile.id,
                      category: 'PROFILE',
                    }"
                    v-slot="{ uploadedFiles }"
                  >
                    <img
                      v-if="uploadedFiles[0] != null"
                      :src="uploadedFiles[0].link"
                      alt="avatar"
                      style="
                        border-radius: 100px;
                        width: 80px !important;
                        height: 80px !important;
                        border: 1px solid #ccc;
                        margin: 2em;
                      "
                      @error="handleImageError"
                      @click="handleShowImagePreview(uploadedFiles[0].link)"
                    />
                    <img
                      v-else
                      src="@/assets/img/avatar_default.jpg"
                      alt="avatar"
                      style="
                        border-radius: 100px;
                        width: 80px !important;
                        height: 80px !important;
                        border: 1px solid #ccc;
                        margin: 2em;
                      "
                    />
                  </file-view-handler>
                </div>
                <div class="text-field file-upload m-0" @click="edit_profile">
                  <i class="far fa-edit"></i>
                  <span class="label">Edit Profile </span>
                </div>
              </div>
              <div class="d-flex justify-content-center md-layout">
                <div
                  class="
                    d-flex
                    flex-column
                    md-layout-item
                    md-size-33
                    md-medium-size-60
                    md-small-size-100
                  "
                >
                  <ValidationHandler
                    :name="profileForm.firstName"
                    rules="required"
                    v-slot="{ error }"
                  >
                    <div>
                      <TextInput
                        type="text"
                        label="First Name *"
                        placeholder="First Name *"
                        icon="person_outline"
                        :required="true"
                        v-model="profileForm.firstName"
                        :error="error"
                        icons="person_outline"
                        :isdisabled="!isedit"
                      />
                    </div>
                  </ValidationHandler>
                  <ValidationHandler
                    :name="profileForm.firstName"
                    rules="required"
                    v-slot="{ error }"
                  >
                    <div>
                      <TextInput
                        type="text"
                        label="Last Name *"
                        placeholder="Last Name *"
                        icon="person_outline"
                        :required="true"
                        v-model="profileForm.lastName"
                        :error="error"
                        icons="person_outline"
                        :isdisabled="!isedit"
                      />
                    </div>
                  </ValidationHandler>
                  <ValidationHandler
                    :name="profileForm.email"
                    rules="required|email|space_exclude"
                    v-slot="{ error }"
                  >
                    <div :class="error ? 'error' : ''">
                      <TextInput
                        type="text"
                        label="Mail Address *"
                        placeholder="Mail Address *"
                        icon="mail"
                        :required="true"
                        v-model="profileForm.email"
                        :error="error"
                        icons="mail"
                        :isdisabled="!isedit"
                      />
                    </div>
                  </ValidationHandler>
                  <ValidationHandler
                    :name="profileForm.phone"
                    rules="required"
                    v-slot="{ error }"
                  >
                    <div :class="error ? 'error' : ''">
                      <TextInput
                        type="text"
                        label="Contact Number *"
                        placeholder="Contact Number *"
                        icon="call"
                        :required="true"
                        v-model="profileForm.phone"
                        v-mask="'(###) ###-########'"
                        :error="error"
                        icons="call"
                        :isdisabled="!isedit"
                      />
                    </div>
                  </ValidationHandler>
                  <div class="mb-4 text-center">
                    <md-button @click="cancelEdit">Cancel</md-button>
                    <md-button
                      v-if="isSaveEnabled"
                      class="md-primary"
                      @click="saveProfile"
                      >Save</md-button
                    >
                  </div>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </ValidationListener>
</template>

<script>
import { mapActions } from "vuex";
import { Success } from "../../models/SwalMessages";
import imageUpload from "../../components/controls/upload/imageUpload.vue";
import TextInput from "../../components/controls/TextInput.vue";
import FileUploadComponentHandler from "../../handlers/common-handlers/FileUploadComponentHandler";
import FileViewHandler from "../../handlers/common-handlers/FileViewHandler";
import NavigationBlocker from "../../components/NavigationBlocker";

export default {
  mixins: [NavigationBlocker],
  components: {
    imageUpload,
    TextInput,
    FileUploadComponentHandler,
    FileViewHandler,
  },

  inject: [
    "showError",
    "showSuccess",
    "app",
    "handleShowImagePreview",
    "handleImageError",
    "navigationBlocker",
  ],
  data() {
    return {
      profileForm: {
        email: "",
        phone: "",
        firstName: "",
        lastName: "",
      },
      url: "",
      profileid: "",
      isedit: false,
      base64: "",
      newimage: "",
      updated: "",
      profileDetails: "",
      isEnabled: false,
      isSaveEnabled: false,
      avatar: "./img/avatar_default.jpg",
    };
  },
  watch: {
    "profileForm.firstName": function () {
      this.save_enable();
    },
    "profileForm.lastName": function () {
      this.save_enable();
    },
    "profileForm.email": function () {
      this.save_enable();
    },
    "profileForm.phone": function () {
      this.save_enable();
    },
  },
  created() {
    this.title();
    this.navigationBlocker.val = true;
  },
  methods: {
    getImageFromFiles(uploadFiles, uploadedFiles) {
      if (uploadFiles && uploadFiles.length > 0) {
        return uploadFiles[0].link;
      } else if (uploadedFiles && uploadedFiles.length > 0) {
        return uploadedFiles[0].link;
      }
    },
    save_enable() {
      if (
        (this.profileForm.firstName != this.app.userProfile.firstName &&
          this.profileForm.firstName != "") ||
        (this.profileForm.lastName != this.app.userProfile.lastName &&
          this.profileForm.lastName != "") ||
        (this.profileForm.email != this.app.userProfile.email &&
          this.profileForm.email != "") ||
        (this.profileForm.phone != this.app.userProfile.phone &&
          this.profileForm.phone != "")
      ) {
        this.isSaveEnabled = true;
      } else {
        this.isSaveEnabled = false;
      }
    },
    edit_profile() {
      if (this.isedit == false) {
        this.isedit = true;
        this.isEnabled = true;
        this.$refs.typeBox.focus();
      } else {
        this.isedit = false;
        this.isEnabled = false;
      }
    },
    ...mapActions("profile", [
      "updateProfile",
      "updateApiProfile",
      "getProfile",
    ]),
    async saveProfile() {
      if (!(await this.profileForm.isValid())) return;
      let postformData = {};
      postformData.email = this.profileForm.email;
      postformData.phone = this.profileForm.phone;
      postformData.firstName = this.profileForm.firstName;
      postformData.lastName = this.profileForm.lastName;
      postformData.Id = this.profileid;
      let postData = JSON.parse(JSON.stringify({ postformData }));
      //let postData = postformData;
      this.updateApiProfile(postData)
        .then(() => {
          this.updateProfile(postData)
            .then(() => {
              var success = new Success();
              success.text = "Profile updated successfully";
              this.showSuccess(success);
              this.getProfile().then((res) => {
                let profileDetails = { ...res };
                this.profileDetails = profileDetails;
                this.isedit = false;
                this.isEnabled = false;
                this.isSaveEnabled = false;
                this.navigationBlocker.val = this.isSaveEnabled;
              });
            })
            .catch((error) => this.showError(error))
            .finally(() => (this.updated = true));
        })
        .catch((error) => this.showError(error))
        .finally(() => (this.updated = true));
    },
    // uploadFile(evt) {
    //   var f = evt.target.files[0];
    //   var reader = new FileReader();
    //   reader.onload = (function () {
    //     return function (e) {
    //       var binaryData = e.target.result;
    //       this.base64 = window.btoa(binaryData);
    //       this.base64 = "data:image/jpeg;base64," + this.base64;
    //       window.localStorage.setItem("base64", this.base64);
    //     };
    //   })(f);
    //   reader.readAsBinaryString(f);
    //   const file = evt.target.files[0];
    //   this.url = URL.createObjectURL(file);
    // },
    title() {
      this.profileForm.firstName = this.app.userProfile.firstName;
      this.profileForm.lastName = this.app.userProfile.lastName;
      this.profileForm.email = this.app.userProfile.email;
      this.profileForm.phone = this.app.userProfile.phone;
      this.profileid = this.app.userProfile.id;
      //this.url = this.app.userProfile.profileImage;
    },
    cancelEdit() {
      if (this.isSaveEnabled) {
        this.navigationBlocker.val = false;
      } else {
        this.navigationBlocker.val = true;
      }
      // this.profileForm.firstName = "";
      // this.profileForm.lastName = "";
      // this.profileForm.email = "";
      // this.profileForm.phone = "";
      // this.title();
      this.$router.push("/globe-chek/dashboard");
    },
  },
};
</script>
