import ListLayoutService from "../../../services/layout-services/ListLayoutService";

const ProviderListHandler = {
  inject: ["listInput", "refreshList"],
  mixins: [ListLayoutService],
  created() {
    this.featureDetails.filterList = [
      { value: "", displayValue: "Filter" },
      { value: "doctor", displayValue: "Name" },
      { value: "speciality", displayValue: "Speciality" },
      { value: "specialization", displayValue: "Specialization" },
      { value: "city", displayValue: "City" },
      { value: "state", displayValue: "State" },
      { value: "zip", displayValue: "Zip code" },
    ];
  },
  template: `<div><slot></slot></div>`,
};

export default ProviderListHandler;

export const withProviderListHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ProviderListHandler><WrappedComponent v-bind="$props"></WrappedComponent></ProviderListHandler>`,
    components: { ProviderListHandler, WrappedComponent },
  };
};
