import { mapActions, mapState } from "vuex";
import { handleLoginResponse } from "./SharedFunctionService";
import { Error } from "../../models/SwalMessages";
export default {
  inject: ["showError"],
  data() {
    return {
      auth: {
        postData: {
          userName: "",
          password: "",
        },
        authenticating: false,
      },
    };
  },
  computed: {
    ...mapState({
      currentOrg: (state) => state.Client.currentOrg,
    }),
  },
  methods: {
    ...mapActions("auth", ["login"]),
    processLogin() {
      let postData = { ...this.auth.postData };
      postData.orgId = this.currentOrg.clientID;
      let formData = {
        ...postData,
        client_id: "GlobeCheck",
        client_secret: "secret",
        grant_Type: "password",
      };
      this.auth.authenticating = true;
      this.login(formData)
        .then((res) => {
          handleLoginResponse(res, true);
          window.location.pathname = "";
        })
        .catch((err) => {
          let error = new Error();
          error.title = "";
          if (err == "invalid_username_or_password") {
            error.text = "Invalid Credentials";
          } else {
            error.text = err;
          }
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        })
        .finally(() => (this.auth.authenticating = false));
    },
  },
  provide() {
    return {
      auth: this.auth,
    };
  },
};

export const getToken = () => {
  return localStorage.getItem("access_token");
};
