import state from "./patient.state";
import actions from "./patient.actions";
import mutations from "./patient.mutation";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
