import * as types from "./master.action.types";

export default {
  [types.GET_ROLE_LIST]: (state, res) => {
    state.roleList = res;
    state.isRoleLoaded = true;
  },

  [types.GET_STATE_LIST]: (state, res) => {
    state.stateList = res;
    state.isStateLoaded = true;
  },

  [types.GET_ADDRESSTYPE_LIST]: (state, res) => {
    state.addressTypeList = res;
    state.isAddressTypeLoaded = true;
  },

  [types.GET_COUNTRYCODE_LIST]: (state, res) => {
    state.countryCodeList = res;
    state.isCountryLoaded = true;
  },

  [types.GET_MONTH_LIST]: (state, res) => {
    state.months = res;
    state.isMonthsLoaded = true;
  },

  [types.GET_SPECIALIZATION_LIST]: (state, res) => {
    state.specializations = res;
    state.isSpecializationsLoaded = true;
  },

  [types.GET_SUBSPECIALIZATION_LIST]: (state, res) => {
    state.subspecializations = res;
    state.isSubspecializationsLoaded = true;
  },

  [types.GET_SPECIALITIES_LIST]: (state, res) => {
    state.specialities = res;
    state.isSpecialitiesLoaded = true;
  },

  [types.GET_EYEORIENTATION_LIST]: (state, res) => {
    state.eyeOrientations = res;
    state.isEyeOrientationsLoaded = true;
  },

  [types.GET_SCREENINGTYPE_LIST]: (state, res) => {
    state.screeningTypes = res;
    state.isScreeningTypesLoaded = true;
  },

  [types.GET_LANGUAGES_LIST]: (state, res) => {
    state.languages = res;
    state.languages.push({
      displayValue: "Others",
      id: 345,
      name: "Others",
    });
    state.isLanguagesLoaded = true;
  },

  [types.GET_INSURANCE_LIST]: (state, res) => {
    state.insurance = res;
    state.insurance.push({
      displayValue: "Others",
      id: 600,
      name: "Others",
    });
    state.isInsuranceLoaded = true;
  },

  [types.GET_QUALIFICATION_LIST]: (state, res) => {
    state.qualification = res;
    state.isQualificationLoaded = true;
  },
  [types.GET_REGION_LIST]: (state, res) => {
    state.regionList = res;
    state.isRegionLoaded = true;
  },
  [types.GET_ORGANISATION_LIST]: (state, res) => {
    state.organisationList = res;
    state.isOrganisationLoaded = true;
  },
  [types.GET_SPHEREPOWER_LIST]: (state, res) => {
    state.spherePowerList = res;
  },

  [types.GET_CYLINDICALPOWER_LIST]: (state, res) => {
    state.cylindricalPowerList = res;
    state.isQualificationLoaded = true;
  },

  [types.GET_AXISPOWER_LIST]: (state, res) => {
    state.axisPowerList = res;
    state.isQualificationLoaded = true;
  },

  [types.GET_ADDITIONALPOWER_LIST]: (state, res) => {
    state.additionalPowerList = res;
    state.isQualificationLoaded = true;
  },

  [types.GET_DISTANCES_LIST]: (state, res) => {
    state.distancesList = res;
    state.isQualificationLoaded = true;
  },
  [types.GET_MESSAGE_TYPE]: (state, res) => {
    state.messageType = res;
  },
  [types.GET_DEVICE_STATUS]: (state, res) => {
    state.deviceStatus = res;
  },
  [types.GET_BUSINESS_TYPE]: (state, res) => {
    state.businessType = res;
  },
  [types.GET_SURGERY]: (state, res) => {
    state.surgeryList = res;
    state.isSurgeryLoaded = true;
  },
  [types.GET_DIABETES]: (state, res) => {
    state.diabetesList = res;
    state.isDiabetesLoaded = true;
  },
  [types.GET_CANCER]: (state, res) => {
    state.cancerList = res;
    state.isCancerLoaded = true;
  },
  [types.CLEAR_REGION_LIST]: (state) => {
    state.regionList = [];
    state.isRegionLoaded = false;
  },
  [types.CLEAR_STATE_LIST]: (state) => {
    state.stateList = [];
    state.isStateLoaded = false;
  },
  [types.CLEAR_COUNTRYCODE_LIST]: (state) => {
    state.countryCodeList = [];
    state.isCountryLoaded = false;
  },
  [types.CLEAR_SPECIALIZATIONS_LIST]: (state) => {
    state.specializations = [];
    state.isSpecializationsLoaded = false;
  },
  [types.CLEAR_SUBSPECIALIZATIONS_LIST]: (state) => {
    state.subspecializations = [];
    state.isSubspecializationsLoaded = false;
  },
  [types.CLEAR_SPECIALITY_LIST]: (state) => {
    state.specialities = [];
    state.isSpecialitiesLoaded = false;
  },
  [types.CLEAR_LANGUAGES_LIST]: (state) => {
    state.languages = [];
    state.isLanguagesLoaded = false;
  },
  [types.CLEAR_INSURANCE_LIST]: (state) => {
    state.insurance = [];
    state.isInsuranceLoaded = false;
  },

  [types.CLEAR_CYLINDRERPOWER_LIST]: (state) => {
    state.cylindricalPowerList = [];
    state.isQualificationLoaded = false;
  },

  [types.CLEAR_AXIS_LIST]: (state) => {
    state.axisPowerList = [];
    state.isQualificationLoaded = false;
  },
  [types.CLEAR_ADDITIONALPOWER_LIST]: (state) => {
    state.additionalPowerList = [];
    state.isQualificationLoaded = false;
  },
  [types.CLEAR_DISTANCE_LIST]: (state) => {
    state.distancesList = [];
    state.isQualificationLoaded = false;
  },

  [types.CLEAR_SURGERY_LIST]: (state) => {
    state.surgeryList = [];
    state.isSurgeryLoaded = false;
  },
  [types.CLEAR_DIABETS_LIST]: (state) => {
    state.diabetesList = [];
    state.isDiabetesLoaded = false;
  },
  [types.CLEAR_CANCER_LIST]: (state) => {
    state.cancerList = [];
    state.isCancerLoaded = false;
  },
};
