import FileUploadService from "../../services/common-services/FileUploadService";

export default {
  mixins: [FileUploadService],
  provide() {
    return {
      handleFilesSelected: this.processSelectedFiles,
      handleFilesSend: this.sendFiles,
    };
  },
  template: `<div><slot></slot></div>`,
};
