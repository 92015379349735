import ListService from "../../services/common-services/ListService";

const ListHandler = {
  props: [
    "preventDefaultRefresh",
    "getRefreshList",
    "isQueryable",
    "canRefreshOnFilter",
    "listInputSource",
  ],
  mixins: [ListService],
  created() {
    this.list.isQueryable = this.isQueryable;
    if (!this.preventDefaultRefresh) this.refreshList();
    else this.$emit("getRefreshList", this.refreshList);
  },
  watch: {
    "listInput.searchText": function () {
      this.refreshList();
    },
    "listInput.filterVal": function () {
      this.refreshList();
    },
    "listInput.sortText": function () {
      this.refreshList();
    },
    "listInput.sortOrder": function () {
      this.refreshList();
    },
    "listInput.filterText": function () {
      this.refreshList();
    },
    "listInput.page": function () {
      this.loadList(this.listInput.page);
    },
    "listInput.size": function () {
      this.refreshListInput();
      this.loadList();
    },
    "listInput.filter": function () {
      if (this.canRefreshOnFilter) this.refreshList();
    },
    canRefreshList: function () {
      this.refreshList();
      console.log("canRefreshList called");
    },
    "listInputSource.value": function () {
      this.listInput.filter = this.listInputSource.value.filter;
      this.listInput.searchText = this.listInputSource.value.searchText;
    },
  },
  provide() {
    return {
      refreshList: this.refreshList,
      reloadList: this.reloadList,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ListHandler;
