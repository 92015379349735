import { mapActions } from "vuex";
import { UserRequest } from "../../../models/User";
import { Success, Error } from "../../../models/SwalMessages";

export default {
  inject: ["showError", "showSuccess", "refreshList", "reloadList"],
  data() {
    return {
      userForm: {
        postData: new UserRequest(),
      },
      manageUser: {
        isSaving: false,
      },
    };
  },
  methods: {
    ...mapActions("user", ["addUser", "updateUser", "getUser"]),
    loadUser() {
      this.getUser(this.userForm.postData.id).then((res) => {
        this.userForm.postData.firstName = res.firstName;
        this.userForm.postData.lastName = res.lastName;
        this.userForm.postData.email = res.email;
        this.userForm.postData.phone = res.phone;
        this.userForm.roleId = res.roles[0].id;
      });
    },
    saveUser(onSuccess) {
      this.manageUser.isSaving = true;
      let postData = JSON.parse(JSON.stringify({ ...this.userForm.postData }));
      postData.roles.push(this.userForm.roleId);
      if (postData.id)
        this.updateUser(postData)
          .then(() => {
            var success = new Success();
            success.text = "User updated successfully";
            this.showSuccess(success);
            this.reloadList();
            if (onSuccess) onSuccess();
          })
          .catch((error) => this.showError(error))
          .finally(() => (this.manageUser.isSaving = false));
      else
        this.addUser(postData)
          .then(() => {
            var success = new Success();
            success.text = "User created successfully";
            this.showSuccess(success);
            this.showSuccess("User created successfully");
            this.refreshList();
            if (onSuccess) onSuccess();
          })
          .catch((err) => {
            var error = new Error();
            error.text = err;
            this.showError(error);
          })
          .finally(() => (this.manageUser.isSaving = false));
    },
  },
  provide() {
    return {
      userForm: this.userForm,
      manageUser: this.manageUser,
    };
  },
};
