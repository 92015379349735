import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      master: (state) => state.master,
    }),
  },
  methods: {
    ...mapActions("master", [
      "getRoleList",
      "getStateList",
      "getAddressTypeList",
      "getCountryCodeList",
      "getMonthList",
      "getSpecializationList",
      "getSubspecializationList",
      "getSpecialitiesList",
      "getEyeOrientationList",
      "getScreeningTypeList",
      "getLanguagesList",
      "getInsuranceList",
      "getQualificationList",
      "getRegionList",
      "getOrganisationList",
      "getSpherePowerList",
      "getCylindricalPowerList",
      "getAxisPowerList",
      "getAdditionalPowerList",
      "getDistancesList",
      "getMessageTypeList",
      "getDeviceStatus",
      "getBusinessType",
      "getSurgeryList",
      "getDiabetesList",
      "getCancerList",
    ]),
    loadMasterList(masterFields) {
      if (masterFields) {
        for (let field of masterFields) {
          if (field == "roleList" && !this.master.isRoleLoaded)
            this.getRoleList();
          else if (field == "stateList" && !this.master.isStateLoaded)
            this.getStateList();
          else if (field == "countryCodeList" && !this.master.isCountryLoaded)
            this.getCountryCodeList();
          else if (
            field == "addressTypeList" &&
            !this.master.isAddressTypeLoaded
          )
            this.getAddressTypeList();
          else if (
            field == "eyeOrientations" &&
            !this.master.isEyeOrientationsLoaded
          )
            this.getEyeOrientationList();
          else if (
            field == "screeningTypes" &&
            !this.master.isScreeningTypesLoaded
          )
            this.getScreeningTypeList();
          else if (field == "months" && !this.master.isMonthsLoaded)
            this.getMonthList();
          else if (
            field == "specializations" &&
            !this.master.isSpecializationsLoaded
          )
            this.getSpecializationList();
          else if (
            field == "subspecializations" &&
            !this.master.isSubspecializationsLoaded
          )
            this.getSubspecializationList();
          else if (field == "specialities" && !this.master.isSpecialitiesLoaded)
            this.getSpecialitiesList();
          else if (field == "languages" && !this.master.isLanguagesLoaded)
            this.getLanguagesList();
          else if (field == "insurance" && !this.master.isInsuranceLoaded)
            this.getInsuranceList();
          else if (
            field == "qualification" &&
            !this.master.isQualificationLoaded
          )
            this.getQualificationList();
          else if (field == "regionList" && !this.master.isRegionLoaded)
            this.getRegionList();
          else if (field == "deviceStatus" && !this.master.isDeviceStatusLoaded)
            this.getDeviceStatus();
          else if (field == "businessType" && !this.master.isBusinessTypeLoaded)
            this.getBusinessType();
          else if (
            field == "organisationList" &&
            !this.master.isOrganisationLoaded
          )
            this.getOrganisationList();
          else if (field == "surgeryList" && !this.master.isSurgeryLoaded)
            this.getSurgeryList();
          else if (field == "diabetesList" && !this.master.isDiabetesLoaded)
            this.getDiabetesList();
          else if (field == "cancerList" && !this.master.isCancerLoaded)
            this.getCancerList();
        }
      }
    },
    loadPrescriptionReadings() {
      this.getAdditionalPowerList();
      this.getAxisPowerList();
      this.getCylindricalPowerList();
      this.getDistancesList();
      this.getSpherePowerList();
    },
    loadMessageType() {
      this.getMessageTypeList();
    },
  },
  provide() {
    return {
      master: this.master,
    };
  },
};
