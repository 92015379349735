export default {
  roleList: [],
  isRoleLoaded: false,
  stateList: [],
  isStateLoaded: false,
  countryCodeList: [],
  isCountryLoaded: false,
  addressTypeList: [],
  isAddressTypeLoaded: false,
  eyeOrientations: [],
  isEyeOrientationsLoaded: false,
  screeningTypes: [],
  isScreeningTypesLoaded: false,
  months: [],
  isMonthsLoaded: false,
  specializations: [],
  isSpecializationsLoaded: false,
  subspecializations: [],
  isSubspecializationsLoaded: false,
  specialities: [],
  isSpecialitiesLoaded: false,
  languages: [],
  isLanguagesLoaded: false,
  insurance: [],
  isInsuranceLoaded: false,
  qualification: [],
  isQualificationLoaded: false,
  deviceStatus: [],
  isDeviceStatusLoaded: false,
  businessType: [],
  isBusinessTypeLoaded: false,
  genderList: [
    { value: "male", displayValue: "Male" },
    { value: "female", displayValue: "Female" },
  ],
  regionList: [],
  isRegionLoaded: false,
  organisationList: [],
  isOrganisationLoaded: false,
  spherePowerList: [],
  cylindricalPowerList: [],
  axisPowerList: [],
  additionalPowerList: [],
  distancesList: [],
  messageType: [],
  surgeryList: [],
  isSurgeryLoaded: false,
  diabetesList: [],
  isDiabetesLoaded: false,
  cancerList: [],
  isCancerLoaded: false,
};
