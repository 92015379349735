<template>
  <md-button class="btn" @click="$emit('click')" v-bind="$props">
    <template v-if="!disabled">
      <slot></slot>
    </template>
    <template v-else> Processing... </template>
  </md-button>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    className: String,
    click: Function,
  },
};
</script>
<style>
.btn {
  border-radius: 25px;
  border-block-color: gray;
}
</style>
