import { extend } from "vee-validate";
import {
  required,
  email,
  alpha,
  alpha_spaces,
  alpha_dash,
  digits,
  numeric,
  alpha_num,
  length,
} from "vee-validate/dist/rules";

// Add the required rule
extend("required", {
  ...required,
  message: "This field is required",
});

// Add the email rule
extend("email", {
  ...email,
  validate: (value) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(value);
  },
  message: "This field must be a valid email",
});

extend("alpha", {
  ...alpha,
  message: "This field should contains only alphabets",
});

extend("length", {
  ...length,
  message: "Please enter valid value",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "This field should contains only alphabets",
});

extend("alpha_dash", {
  ...alpha_dash,
  message: "Enter a value without space (add _ or -)",
});

extend("digits", {
  ...digits,
  message: "This field should be digits",
});
extend("digitsno", {
  ...digits,
  message: "This field should be digits",
});

extend("min", {
  params: ["min"],
  validate: (value, args) => String(value).length >= args.min,
  message: "Enter atleast {min} characters",
});

extend("max", {
  params: ["max"],
  validate: (value, args) => String(value).length <= args.max,
  message: "This field should not be above {max} characters",
});
extend("numeric", {
  ...numeric,
  message: "This field must be numeric",
});

extend("alpha_num", {
  ...alpha_num,
  message: "Please enter valid Name",
});
extend("phone", {
  validate: (value) => {
    const regEx = /^\+[1-9]\d{10,14}$/;
    return regEx.test(value);
  },
  message: "Valid format is +14155552671",
});
extend("space_exclude", {
  validate: (value) => {
    const space = / /;
    if (space.test(value)) {
      return false;
    } else {
      return true;
    }
  },
  message: "Enter Valid password",
});

extend("url", {
  validate(value) {
    if (value) {
      const regEx =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]/;
      // +([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$
      return regEx.test(value);
    }
    return false;
  },
  message: "Please enter valid Domain",
});
