<template>
  <md-dialog
    :md-active.sync="confirmation.show"
    :md-fullscreen="false"
    class="alert-dialog"
  >
    <div class="alert-message danger">
      <!-- <div class="alert-header">
        <div @click="confirmation.show = false">
          <md-icon>close</md-icon>
        </div>
      </div> -->
      <div class="alert-body">
        <h1><i class="fa fa-times-circle"></i> {{ confirmation.title }}</h1>
        <p>{{ confirmation.description }}</p>
      </div>
      <div class="button-container">
        <md-button @click="onCancel()">No</md-button>
        <md-button class="md-danger" @click="onSubmit()">Yes</md-button>
      </div>
    </div>
  </md-dialog>
</template>
<script>
//import Button from "./button/DefaultButton.vue";
import {
  onSubmit,
  onCancel,
} from "../../services/control-services/ConfirmationService";
export default {
  inject: ["confirmation"],
  //components: { Button },
  methods: {
    onSubmit,
    onCancel,
  },
};
</script>
