import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  data() {
    return {
      listFilters: this.loadFilterList(),
      listPage: {},
      refresh: { value: false },
    };
  },
  methods: {
    ...mapActions("screenings", [
      "getScreeningsAvailable",
      "getScreeningsAvailableCount",
      "getDurationList",
      "getSortList",
    ]),
    loadFilterList() {
      this.getDurationList();
      this.getSortList();
    },
  },
  provide() {
    return {
      feature: Features.ScreeningsAvailable,
      listPage: this.listPage,
      listFilters: this.listFilters,
      refreshGrid: this.refreshGrid,
      refresh: this.refresh,
    };
  },
};
