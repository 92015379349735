import ReferraldoctorsService from "../../../services/module-services/referraldoctors/ReferraldoctorsService";
import ListLayoutHandler from "../../common-handlers/ListLayoutHandler";
import ListHandler from "../../common-handlers/ListHandler";
import Features from "../../../constants/Features";

const ReferralDoctorHandler = {
  mixins: [ReferraldoctorsService],

  provide() {
    return {
      feature: Features.ReferralDoctor,
      getList: this.getReferralDoctor,
      getListCount: this.referralDoctorsCount,
      listFilters: this.listFilters,
    };
  },
  template: `<ListHandler><ListLayoutHandler><slot></slot></ListLayoutHandler></ListHandler>`,
  components: {
    ListHandler,
    ListLayoutHandler,
  },
};
export default ReferralDoctorHandler;

export const withReferralDoctorHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ReferralDoctorHandler><WrappedComponent v-bind="$props"></WrappedComponent></ReferralDoctorHandler>`,
    components: { ReferralDoctorHandler, WrappedComponent },
  };
};
