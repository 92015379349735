import FileUploadComponentService from "../../services/common-services/FileUploadComponentService";

export default {
  props: ["options"],
  mixins: [FileUploadComponentService],
  created() {
    this.files.options = this.options;
  },
  methods: {
    handleSendNewFiles(featureId) {
      this.files.options = { ...this.files.options, featureId };
      this.sendFiles();
    },
  },
  watch: {
    options() {
      this.files.options = this.options;
    },
  },
  template: `<div><slot :handleFilesSelected="processSelectedFiles" :uploadFiles="files.list"></slot></div>`,
};
