import MasterService from "../../../services/common-services/MasterService";
import SearchDoctorService from "../../../services/module-services/doctor/SearchDoctorService";
import NavigationService from "../../../services/common-services/NavigationService";

const SearchDoctorHandler = {
  mixins: [MasterService, SearchDoctorService, NavigationService],
  created() {
    this.loadMasterList([
      "stateList",
      "countryCodeList",
      "specializations",
      "qualification",
    ]);
  },
  methods: {
    navigateToHomePage() {
      this.navigateTo("home");
    },
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      navigateToBack: this.navigateBack,
      navigateToHomePage: this.navigateToHomePage,
      searchDoctorWithParams: this.searchDoctorWithParams,
      clearDoctorSearchForm: this.clearDoctorSearchForm,
      progress: this.progress,
    };
  },
};

export default SearchDoctorHandler;

export const withSearchDoctorHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<SearchDoctorHandler><WrappedComponent v-bind="$props"></WrappedComponent></SearchDoctorHandler>`,
    components: { SearchDoctorHandler, WrappedComponent },
  };
};
