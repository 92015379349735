import * as HttpService from "../services/common-services/HttpService";

export function postPrescription(postData) {
  return HttpService.post("api/v1/Prescription", postData);
}

export function getLookupValues() {
  return HttpService.getWithHeader(
    "api/v1/Lookup/GetBulkLookup",
    "spherePower,CylinderPower,Axis,AdditionalPower,Distance"
  );
}

export function getScreeningsAvailable(screeningID) {
  // console.log("api call function");
  return HttpService.get(`api/v1/screenings/` + screeningID);
}

export function postScreeningEvaluation(postData) {
  return HttpService.post("api/v1/screenings/", postData);
}

export function updateScreeningEvaluation(postData) {
  return HttpService.post("api/v1/screenings/update", postData);
}

export function getSavedScreeningInfo(screeningID) {
  return HttpService.get(`api/v1/screenings/reopen/` + screeningID);
}

export function updateScreeningEvaluationStatus(screeningId) {
  return HttpService.put(`api/v1/screenings/UpdateStatus/` + screeningId);
}

export function getPrescription(screeningID) {
  return HttpService.get(`api/v1/Prescription/` + screeningID);
}

export function getScrReferralDoctor(screeningID) {
  return HttpService.get(`api/v1/Screenings/ScrReferralDoc/` + screeningID);
}
