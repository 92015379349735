import screeningevalService from "../../../services/module-services/screeningeval/ScreeningevalService";
import MasterService from "../../../services/common-services/MasterService";
import FindingService from "../../../services/module-services/findings/findingService";
import { getSubmit } from "../../../services/control-services/SnackbarService";
import { Warning } from "../../../models/SwalMessages";
import NavigationBlocker from "../../../components/NavigationBlocker";

const ScreeningHandler = {
  mixins: [screeningevalService, MasterService, FindingService],
  inject: ["confirmation", "showWarning", "snackbar", "navigationBlocker"],
  data() {
    return {};
  },
  created() {
    this.navigationBlocker.val = true;
    this.getScreening(this.$route.params);
    this.loadPrescriptionReadings();
    if (location.pathname.includes("/Edit/")) {
      this.manageScreening.page = "edit";
    }
    if (location.pathname.includes("/Onhold/Evaluation/")) {
      this.navigationBlocker.val = true;
    }
    this.manageScreening.screeningId = this.$route.params.id;
  },
  computed: {
    description() {
      var value = "<b>ARE YOU SURE YOU WANT TO SAVE? </b><br/>";
      if (
        !this.isEvaluated.externalPhoto ||
        !this.isEvaluated.fundusPhoto ||
        !this.isEvaluated.anteriorSegmentOCT ||
        !this.isEvaluated.opticNerveOCT ||
        !this.isEvaluated.macularOCT ||
        !this.isEvaluated.cornealTopography ||
        !this.isEvaluated.lensStatus
      ) {
        value += "<b>YOUR REPORT IS NOT COMPLETED OR SENT</b><br/> ";
        if (!this.isEvaluated.externalPhoto) {
          value += "";
        }
        if (!this.isEvaluated.fundusPhoto) {
          value += "";
        }
        if (!this.isEvaluated.anteriorSegmentOCT) {
          value += "";
        }
        if (!this.isEvaluated.opticNerveOCT) {
          value += "";
        }
        if (!this.isEvaluated.macularOCT) {
          value += "";
        }
        if (!this.isEvaluated.cornealTopography) {
          value += "";
        }
        if (!this.isEvaluated.lensStatus) {
          value += "";
        }
      }
      return value;
    },
  },

  methods: {
    showpreviewClick(val) {
      this.previewReport.show = val;
    },
    imagescreening() {
      this.calcODOS();
      this.handleDrop();
      this.imgURL();
      // this.displayModal();
    },
    handleDoctorClick(id) {
      if (!this.referralDoctors.list.includes(id)) {
        this.referralDoctors.list.push(id);
      }
    },
    handleReferralClick(item) {
      this.check(
        this.$store.state.screeningeval.ReferralDoctors,
        item.personEntryID,
        item
      );
    },
    check(arr, refid, item) {
      const found = arr.some((el) => el.personEntryID === refid);
      if (found) {
        this.removeref(item.personEntryID);
      } else {
        this.$store.state.screeningeval.ReferralDoctors.push(item);
      }
    },
    removeref(refid) {
      for (
        var i = 0;
        i < this.$store.state.screeningeval.ReferralDoctors.length;
        i++
      ) {
        if (
          this.$store.state.screeningeval.ReferralDoctors[i].personEntryID ==
          refid
        ) {
          this.$store.state.screeningeval.ReferralDoctors.splice(i, 1);
          break;
        }
      }
    },
    handleImageClick(image, type) {
      if (type != "LensStatus") {
        this.imageView.show = true;
        this.imageView.image = image;
      } else {
        this.imageView.show = false;
      }
    },
    handleCloseImageView() {
      this.imageView.show = false;
    },
    handleSaveScreening() {
      this.navigationBlocker.val = true;
      let warning = new Warning();
      warning.title = "Warning";
      warning.text = this.description;
      warning.confirmButtonClass = "md-button md-info";
      warning.hasGoback = true;
      this.showWarning(warning);
      this.submitSubscription = getSubmit().subscribe(() => {
        this.snackbar.showWarning = false;
        this.ScreeningEvaluationsavefunction();
        this.submitSubscription.unsubscribe();
      });
    },
    handlerequesttorelease() {
      let warning = new Warning();
      warning.title = "Request Screening?";
      warning.text = "Are you sure you want to request this Screening?";
      warning.confirmButtonClass = "md-button md-info";
      warning.hasGoback = true;
      this.showWarning(warning);
      this.submitSubscription = getSubmit().subscribe(() => {
        console.log("request to release");
        this.snackbar.showWarning = false;
        this.handlerequesttoreleasepro();
        this.submitSubscription.unsubscribe();
      });
    },
    handlereleaseforreq() {
      let warning = new Warning();
      warning.title = "Release Screening?";
      warning.text = "Are you sure you want to release this Screening?";
      warning.confirmButtonClass = "md-button md-info";
      warning.hasGoback = true;
      this.showWarning(warning);
      this.submitSubscription = getSubmit().subscribe(() => {
        this.snackbar.showWarning = false;
        this.handlereleaseforreqpro();
        this.submitSubscription.unsubscribe();
      });
    },
  },
  provide() {
    return {
      imagescreening: this.imagescreening,
      handleSavePrescription: this.savePrescription,
      handleSaveScreening: this.handleSaveScreening,
      handleDoctorClick: this.handleDoctorClick,
      showpreviewClick: this.showpreviewClick,
      handleImageClick: this.handleImageClick,
      handleCloseImageView: this.handleCloseImageView,
      handleReferralClick: this.handleReferralClick,
      handlerequesttorelease: this.handlerequesttorelease,
      handlereleaseforreq: this.handlereleaseforreq,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ScreeningHandler;

export const withscreeningevalHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    mixins: [NavigationBlocker],
    template: `<ScreeningHandler><WrappedComponent v-bind="$props"></WrappedComponent></ScreeningHandler>`,
    components: { ScreeningHandler, WrappedComponent },
  };
};
