import UserListService from "../../../services/module-services/user/UserListService";
import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";
import ListLayoutService from "../../../services/layout-services/ListLayoutService";

const UserListHandler = {
  mixins: [UserListService, ListLayoutService],
  inject: [
    "confirmation",
    "listPage",
    "listInput",
    "master",
    "reloadList",
    "refreshList",
    "listFilters",
    "Status",
  ],
  created() {
    this.loadFilterList();
    if (
      this.listPage.module &&
      this.listPage.module != "" &&
      !this.list.pages[this.listInput.page]
    )
      this.listPage.refreshList();
  },
  watch: {
    "listPage.module": function () {
      this.listFilters.onlyReader = false;
      this.loadFilterList();
      if (this.listInput.filter != "") this.listInput.filter = "";
      else this.listPage.refreshList();
    },
    "listFilters.onlyReader": function () {
      this.refreshList();
    },
  },
  methods: {
    handleDeleteClick(selectedItem) {
      this.confirmation.title = "Delete User ?";
      this.confirmation.description =
        "Are you sure you want to delete this User ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.deleteSelectedUser(selectedItem);
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
    handleActiveClick(activeData) {
      this.confirmation.title =
        (activeData.isActive ? "Activate" : "Deactivate") + " User ?";
      this.confirmation.description =
        "Are you sure you want to " +
        (activeData.isActive ? "Activate" : "Deactivate") +
        " this User ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.activeSelectedUser(activeData);
        this.submitSubscription.unsubscribe();
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
    loadFilterList() {
      if (this.listPage.module == "doctors")
        this.featureDetails.filterList = this.listPage.placeHolderFilterList
          .concat(this.listPage.doctorFilterList)
          .concat(this.listPage.commonFilterList);
      else if (this.listPage.module == "Patient") {
        this.featureDetails.filterList = [];
      } else
        this.featureDetails.filterList =
          this.listPage.placeHolderFilterList.concat(
            this.listPage.commonFilterList
          );
    },
    enableChangePassword(user) {
      this.changePasswordForm.userId = user.userID;
      this.changePasswordForm.userName = user.name;
      this.changePasswordForm.userEmail = user.email;
      this.changePasswordForm.showChangePassword = true;
      this.changePasswordForm.processing = false;
      this.changePasswordForm.password = "";
      this.changePasswordForm.confirmPassword = "";
    },
    async submitChangePassword() {
      if (!(await this.changePasswordForm.isValid())) return;
      this.processChangePassword();
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  provide() {
    return {
      handleDeleteClick: this.handleDeleteClick,
      handleActiveClick: this.handleActiveClick,
      enableChangePassword: this.enableChangePassword,
      submitChangePassword: this.submitChangePassword,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default UserListHandler;

export const withUserListHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<UserListHandler><WrappedComponent v-bind="$props"></WrappedComponent></UserListHandler>`,
    components: { UserListHandler, WrappedComponent },
  };
};
