import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  data() {
    return {
      listPage: {},
      listFilters: {},
    };
  },
  methods: {
    ...mapActions("Client", ["getClientList"]),
  },
  provide() {
    return {
      feature: Features.Clients,
      listPage: this.listPage,
      listFilters: this.listFilters,
    };
  },
};
