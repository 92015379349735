import { AddressDetails } from "./Address";
import { PersonDetails } from "./Person";

export default class User {
  constructor(username, email, password) {
    this.username = username;
    this.email = email;
    this.password = password;
  }
}

export class UserRequest {
  id = "";
  firstName = "";
  lastName = "";
  email = "";
  phone = "";
  password = "";
  roles = [];
}

export class UserRegstartionRequest {
  id = "";
  isApproved = false;
  person = new PersonDetails();
  address = new AddressDetails();
  userType = "";
  orgID = "";
}
