export default {
  methods: {
    log(val) {
      console.log(val);
    },
    logError(val) {
      console.error(val);
    },
  },
  provide: function () {
    return {
      log: this.log,
      logError: this.logError,
    };
  },
};
