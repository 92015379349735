import { mapActions, mapState } from "vuex";
import Features from "../../../constants/Features";

import NavigationService from "../../../services/common-services/NavigationService";
export default {
  mixins: [NavigationService],
  inject: [
    "app",
    "showpreviewClick",
    "showSuccessMsg",
    "showErrorMsg",
    "confirmation",
    "Permissions",
    "saveScreeningEvaluation",
    "refreshList",
  ],
  data() {
    return {
      listFilters: this.loadFilterList(),
      userId: this.app.userProfile.id,
      generated: false,
      //userType: this.app.userProfile.roles[0].name,
      scrId: this.$route.params.id,
      orgID: this.app.userProfile.organisations[0].id,
      manageReport: {
        page: "",
      },
      reprtgn: false,
    };
  },
  computed: {
    ...mapState({
      reports: (state) => state.reports,
      userClaims: (state) => state.profile.userClaims,
    }),
  },
  created() {
    if (location.pathname.includes("Reports/Edit/")) {
      this.manageReport.page = "edit";
    }
  },
  methods: {
    ...mapActions("reports", [
      "getReports",
      "getReportsCount",
      "getSortList",
      "reportgeneration",
      "patientemailnotification",
      "patientmsgnotification",
      "UpdateReportStatusPut",
      "adminreportmsgnotification",
      "adminreportstatusnotification",
      "updatereport",
    ]),
    ...mapActions("screenings", ["getDurationList"]),
    loadFilterList() {
      this.getDurationList();
      this.getSortList();
    },
    reportgenprocess() {
      let postdataform = {};
      postdataform.screeningID = this.scrId;
      postdataform.orgID = this.orgID;
      postdataform.rptType = "PDF";
      postdataform.generatedby = this.userId;
      if (this.manageReport.page == "edit") {
        this.saveScreeningEvaluation("closed");
        let type = "update";
        this.updatereport(postdataform).then(() => {
          this.generated = true;
          this.showSuccessMsg(" Report Generated successfully");
          this.notification(type);
          this.navigateTo("Screenings");
          this.refreshList();
        });
      } else {
        this.saveScreeningEvaluation("closed");
        let type = "new";
        this.reportgeneration(postdataform)
          .then(() => {
            this.generated = true;
            this.showSuccessMsg(" Report Generated successfully");
            this.notification(type);
            this.navigateTo("Screenings");
            this.refreshList();
          })
          .catch(() => {
            this.showErrorMsg("Sorry Something went wrong");
          });
      }
    },
    UpdateReportStatus(selectedItem) {
      if (this.userClaims.includes(this.Permissions.IsPatient)) {
        this.UpdateReportStatusPut(selectedItem);
        this.adminreportstatusnotification(selectedItem);
      }
    },
    ResendReport(selectedItem) {
      let type = "update";
      let reportNotification = {};
      reportNotification.screeningID = selectedItem.screeningID;
      reportNotification.type = type;
      this.patientmsgnotification(reportNotification) &&
        this.adminreportmsgnotification(selectedItem.screeningID) &&
        this.patientemailnotification(reportNotification)
          .then(() => {
            this.showSuccessMsg(" Notification sent to Patient successfully");
          })
          .catch(() => {
            this.showErrorMsg("Sorry Something went wrong");
          });
    },
    notification(type) {
      if (this.generated) {
        let reportNotification = {};
        reportNotification.screeningID = this.scrId;
        reportNotification.type = type;
        this.patientmsgnotification(reportNotification) &&
          this.adminreportmsgnotification(this.scrId) &&
          this.patientemailnotification(reportNotification);
      }
    },
  },
  provide() {
    return {
      feature: Features.Reports,
      manageReport: this.manageReport,
    };
  },
};
