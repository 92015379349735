import ListHandler from "../../common-handlers/ListHandler";
import MasterDataModuleService from "../../../services/module-services/MasterData/MasterDataModuleService";
import Features from "../../../constants/Features";
const MasterDataModuleHandler = {
  mixins: [MasterDataModuleService],
  provide() {
    return {
      feature: Features.MasterData,
      listFilters: null,
      getList: null,
      getListCount: null,
    };
  },
  template: `<ListHandler><div><slot></slot></div></ListHandler>`,
  components: {
    ListHandler,
  },
};

export default MasterDataModuleHandler;

export const withMasterDataModuleHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<MasterDataModuleHandler><WrappedComponent v-bind="$props"></WrappedComponent></MasterDataModuleHandler>`,
    components: { MasterDataModuleHandler, WrappedComponent },
  };
};
