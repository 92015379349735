import { withRegisterSelectionHandler } from "../../handlers/module-handlers/register/RegisterSelectionHandler";

export default [
  {
    path: "register",
    component: () => import("./RegisterRoutePage.vue"),
    meta: { isPublic: true },
    children: [
      {
        path: "",
        component: withRegisterSelectionHandler(() =>
          import("./RegisterSelectionPage.vue")
        ),
        meta: { isPublic: true },
      },
      {
        path: "success/:userType",
        component: () => import("./RegisterSuccessPage.vue"),
        meta: { isPublic: true },
      },
      {
        path: ":userType",
        component: () => import("./RegisterPage.vue"),
        meta: { isPublic: true },
      },
    ],
  },
];
