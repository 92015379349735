import * as api from "../../../api/filesApi";
import * as types from "./files.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  uploadFile({ commit }, { file, uploadProgress }) {
    commit(types.ADD_UPLOADING_FILE, { id: file.id, category: file.category });
    return api.uploadFile(file, uploadProgress).finally(() => {
      commit(types.REMOVE_UPLOADING_FILE, file.id);
    });
  },
  // eslint-disable-next-line no-unused-vars
  postFiles({ commit }, { options, files }) {
    return api.postFiles(options, files);
  },
  // eslint-disable-next-line no-unused-vars
  getFiles({ commit }, options) {
    return api.getFiles(options);
  },
  // eslint-disable-next-line no-unused-vars
  getFilesbycategory({ commit }, options) {
    return api.getFilesbycategory(options);
  },
  // eslint-disable-next-line no-unused-vars
  deleteFile({ commit }, options) {
    return api.deleteFile(options);
  },
};
