import * as types from "./doctor.action.types";

export default {
  [types.SEARCH_DOCTORS]: (state, res) => {
    state.searchResults = [];
    state.searchResults = res.data.map((re) => {
      return re;
    });
  },
};
