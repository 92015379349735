import state from "./user.state";
import actions from "./user.actions";
import mutations from "./user.mutation";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
