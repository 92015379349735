import state from "./master.state";
import actions from "./master.actions";
import mutations from "./master.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
