import { mapActions } from "vuex";
import { Success, Error } from "../../../models/SwalMessages";

export default {
  inject: ["showSuccess", "showError", "type", "getLookupList"],
  methods: {
    ...mapActions("master", ["deleteLookupValue"]),
    deleteSelectedMasterData(id) {
      this.deleteLookupValue({
        id,
        masterName: this.type.value,
      })
        .then(() => {
          var success = new Success();
          success.text = "Deleted successfully";
          this.showSuccess(success);
          this.getLookupList();
        })
        .catch((error) => {
          var errorMsg = new Error();
          errorMsg.text = error;
          this.showError(errorMsg);
        });
    },
  },
};
