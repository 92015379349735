import MasterService from "../../../services/common-services/MasterService";
import ManageDoctorService from "../../../services/module-services/doctor/ManageDoctorService";
import NavigationService from "../../../services/common-services/NavigationService";
import NavigationBlocker from "../../../components/NavigationBlocker";

const ManageDoctorHandler = {
  mixins: [ManageDoctorService, MasterService, NavigationService],
  inject: ["popupInvoked"],
  created() {
    let masterList = [
      "stateList",
      "countryCodeList",
      "addressTypeList",
      "eyeOrientations",
      "screeningTypes",
      "months",
      "specializations",
      "subspecializations",
      "specialities",
      "languages",
      "insurance",
      "qualification",
      "roleList",
    ];

    if (!location.pathname.includes("/register/")) {
      masterList = masterList.concat(["regionList", "organisationList"]);
      this.getRegionClients()
        .then((res) => {
          this.manageDoctor.regionClientMapping = res;
        })
        .finally(() => {
          this.manageDoctor.isRegionClientMappingLoaded = true;
          this.loadRegionClients();
        });
    }
    if (location.pathname.endsWith("/approvals")) {
      this.manageDoctor.page = "Approval";
    } else if (location.pathname.includes("/register/")) {
      this.manageDoctor.page = "Register";
      if (String(this.$route.params.userType).toLowerCase() == "doctor")
        this.manageDoctor.showConfidentialConfirmation = true;
    } else this.manageDoctor.page = "manage";
    this.loadMasterList(masterList);
    if (this.$route.params.doctorId) {
      this.doctorForm.postData.id = this.$route.params.doctorId;
      this.manageDoctor.id = this.$route.params.doctorId;
      this.loadDoctor();
    }
    this.loadSelectedRole();
  },
  watch: {
    "popupInvoked.val"() {
      this.manageDoctor.showConfidentialConfirmation = false;
    },
    "master.roleList": function () {
      this.loadSelectedRole();
    },
    "master.regionList": function () {
      this.loadRegionClients();
    },
    "master.organisationList": function () {
      this.loadRegionClients();
    },
  },
  methods: {
    loadSelectedRole() {
      let userType;
      if (
        this.$router.currentRoute.meta &&
        this.$router.currentRoute.meta.userType
      )
        userType = this.$router.currentRoute.meta.userType;
      else if (this.$route.params.userType)
        userType = this.$route.params.userType;
      if (userType && this.master.roleList.length > 0) {
        let selecetedRole = this.master.roleList.find(
          (x) => String(x.name).toLowerCase() == String(userType).toLowerCase()
        );
        if (!selecetedRole) this.$router.back();
        else {
          this.manageDoctor.role = selecetedRole;
          this.manageDoctor.userType = userType;
        }
      }
    },
    async checkFormValid(formStep) {
      if (formStep == "first" && (await this.doctorForm.isPersonalDataValid()))
        return true;
      else if (
        formStep == "second" &&
        (await this.doctorForm.isBusinessDataValid())
      )
        return true;
      else if (
        formStep == "third" &&
        (await this.doctorForm.isQualificationDataValid())
      )
        return true;
    },
    handleDoctorApprove(isApproved) {
      this.doctorForm.postData.isApproved = isApproved;
      this.saveDoctor();
    },
    loadRegionClients() {
      if (
        this.master.isRegionLoaded &&
        this.master.isOrganisationLoaded &&
        this.manageDoctor.isRegionClientMappingLoaded
      ) {
        for (let region of this.master.regionList) {
          let mappedClients = this.manageDoctor.regionClientMapping.filter(
            (x) => x.regionId == region.id
          );
          let regionClients = [];
          for (let mapping of mappedClients) {
            let selectedClient = this.master.organisationList.find(
              (x) => x.clientID == mapping.clientId
            );
            if (selectedClient)
              regionClients.push({
                id: selectedClient.clientID,
                displayValue: selectedClient.clientName,
              });
          }
          this.manageDoctor.regionClients.push({
            id: region.id,
            displayValue: region.displayValue,
            selectedClients: [],
            clients: regionClients,
          });
        }
        let unmappedClients = [];
        for (let client of this.master.organisationList) {
          if (
            !this.manageDoctor.regionClientMapping.find(
              (x) => x.clientId == client.clientID
            )
          )
            unmappedClients.push({
              id: client.clientID,
              displayValue: client.clientName,
            });
        }
        if (unmappedClients.length > 0)
          this.manageDoctor.regionClients.push({
            id: 0,
            displayValue: "Others",
            selectedClients: [],
            clients: unmappedClients,
          });
        this.manageDoctor.isRegionClientsLoaded = true;
        this.loadSelectedRegionClients();
      }
    },
    handleClientChecked(region, client) {
      let isChecked = region.selectedClients.includes(client.value);
      if (isChecked) {
        for (let x of this.manageDoctor.regionClients)
          if (
            x.id != region.id &&
            x.clients.map((a) => a.id).includes(client.value)
          )
            x.selectedClients.push(client.value);
      } else {
        for (let x of this.manageDoctor.regionClients)
          if (
            x.id != region.id &&
            x.clients.map((a) => a.id).includes(client.value)
          )
            x.selectedClients = x.selectedClients.filter(
              (id) => client.value != id
            );
      }
    },
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      checkFormValid: this.checkFormValid,
      handleDoctorLoad: this.loadDoctor,
      handleDoctorSave: this.saveDoctor,
      handleDoctorApprove: this.handleDoctorApprove,
      handleClientChecked: this.handleClientChecked,
    };
  },
};

export default ManageDoctorHandler;

export const withManageDoctorHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    mixins: [NavigationBlocker],
    template: `<ManageDoctorHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManageDoctorHandler>`,
    components: { ManageDoctorHandler, WrappedComponent },
  };
};
