import { mapActions } from "vuex";
import { PasswordResetRequest } from "../../../models/ResetPassword";
import { Success, Error } from "../../../models/SwalMessages";
import PasswordEnformentService from "../../common-services/PasswordEnformentService";
import NavigationService from "../../../services/common-services/NavigationService";
import { logout } from "../../common-services/SharedFunctionService";

export default {
  inject: ["showError", "showSuccess", "app", "showSuccessMsg", "showErrorMsg"],
  mixins: [PasswordEnformentService, NavigationService],
  data() {
    return {
      passwordReset: {
        postData: new PasswordResetRequest(),
        authenticating: false,
      },
    };
  },
  methods: {
    ...mapActions("user", [
      "ResetPasswordPost",
      "ResetPasswordConfirmPost",
      "ChangePasswordConfirmPost",
    ]),
    UserResetPassword() {
      let postData = {
        Email: this.passwordReset.postData.email,
        ConfirmEmail: this.passwordReset.postData.confirmEmail,
      };
      this.passwordReset.authenticating = true;
      this.passwordReset.isFormValid().then(() => {
        if (postData.Email === postData.ConfirmEmail) {
          this.ResetPasswordPost(postData)
            .then(() => {
              let success = new Success();
              success.title = "Reset request sent.";
              success.text = "Please Check Your Email for Instructions.";
              success.confirmButtonClass = "md-button md-success";
              this.showSuccess(success);
            })
            .catch(() => {
              let error = new Error();
              error.title = "Member not found";
              error.text = "Please re-enter your email address";
              error.confirmButtonClass = "md-button md-info";
              this.showError(error);
            })
            .finally(() => {
              this.passwordReset.authenticating = false;
            });
        } else {
          let error = new Error();
          error.title = "Email are not matching";
          error.text = "Please re-enter your email again";
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        }
      });
    },
    UserResetPasswordConfirm() {
      let postData = {
        Email: this.passwordReset.postData.email,
        Token: this.passwordReset.postData.token,
        Password: this.passwordReset.postData.password,
        confirmPassword: this.passwordReset.postData.confirmPassword,
      };
      if (postData.confirmPassword === postData.Password) {
        if (this.PasswordEnforcementCheck(postData.Email, postData.Password)) {
          this.ResetPasswordConfirmPost(postData)
            .then(() => {
              let success = new Success();
              success.title = "Password Reset Successful";
              success.text =
                "Your password reset successfully.Please try logging in again.";
              success.confirmButtonClass = "md-button md-success";
              this.showSuccess(success);
              this.navigateTo("login");
            })
            .catch(() => {
              let error = new Error();
              error.title = "Member not found";
              error.text = "Please re-enter your email address";
              error.confirmButtonClass = "md-button md-info";
              this.showError(error);
            })
            .finally(() => {});
        } else {
          let error = new Error();
          error.title = "Password not matching the requirements";
          error.text =
            "Please enter a password based on the following criteria:Password must be at least 10 characters long and atmost of 50 characters,Password must have at least one alphabet, one numerical, and one special character,Username and password must not be the same";
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        }
      } else {
        let error = new Error();
        error.title = "Password not matching";
        error.text = "Please re-enter your password again";
        error.confirmButtonClass = "md-button md-info";
        this.showError(error);
      }
    },
    VoluntaryPasswordChange() {
      let postData = {
        Email: this.passwordReset.postData.email,
        CurrentPassword: this.passwordReset.postData.currentPassword,
        Password: this.passwordReset.postData.password,
        confirmPassword: this.passwordReset.postData.confirmPassword,
        IsTempPassword: this.app.userProfile.isTempPassword,
      };
      if (postData.confirmPassword == postData.Password) {
        if (
          postData.Password != postData.CurrentPassword &&
          this.PasswordEnforcementCheck(postData.Email, postData.Password)
        ) {
          this.ChangePasswordConfirmPost(postData)
            .then(() => {
              let success = new Success();
              success.title = "Password Reset Successful";
              success.text =
                "Your password reset successfully.Please try logging in again.";
              success.confirmButtonClass = "md-button md-success";
              this.showSuccess(success);
              if (postData.IsTempPassword) this.$router.back();
            })
            .catch(() => {
              this.showErrorMsg("Sorry Something went wrong");
            })
            .finally(() => {
              logout();
            });
        } else {
          let error = new Error();
          error.title = "Password not matching the requirements";
          error.text =
            "Please enter a password based on the following criteria:Password must be at least 10 characters long and atmost of 50 characters,Password must have at least one alphabet, one numerical, and one special character,Username and password must not be the same";
          error.confirmButtonClass = "md-button md-info";
          this.showError(error);
        }
      } else {
        let error = new Error();
        error.title = "Password not matching";
        error.text = "Please re-enter your password again";
        error.confirmButtonClass = "md-button md-info";
        this.showError(error);
      }
    },

    SetPasswordResetToken() {
      this.passwordReset.postData.token = this.$route.query.token.replaceAll(
        " ",
        "+"
      );
      //console.log(this.passwordReset.postData.token);
    },
    SetPasswordResetEmail() {
      this.passwordReset.postData.email = this.$route.query.email.replaceAll(
        " ",
        "+"
      );
      //console.log(this.passwordReset.postData.token);
    },
  },
};
