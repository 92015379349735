<template>
  <md-dialog
    :md-active.sync="snackbar.showSuccess"
    :md-fullscreen="false"
    class="alert-dialog"
  >
    <div class="alert-message success">
      <div class="alert-body">
        <h1>
          <i class="far fa-check-circle"></i>
          {{ snackbar.title }}!
          <!-- SUCCESS -->
        </h1>
        <p>{{ snackbar.description }}</p>
      </div>
      <div>
        <md-button @click="snackbar.showSuccess = false" class="md-success"
          >OK</md-button
        >
      </div>
    </div>
  </md-dialog>
</template>
<script>
export default {
  inject: ["snackbar"],
};
</script>
