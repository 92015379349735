import * as HttpService from "../services/common-services/HttpService";

export function getScreeningsAvailableCount() {
  return HttpService.get("api/v1/Screenings/ScreeningListCount");
}

export function getScreeningsOnholdCount() {
  return HttpService.get("api/v1/Screenings/OnHoldScreeningListCount");
}

export function getSidebarDate(userID) {
  return HttpService.get(`api/v1/screenings/available/${userID}`);
}

export function getScreeningsOnholdByReaderID(readerID) {
  return HttpService.get(`api/v1/screenings/onhold/${readerID}`);
}

export function getReportsCount() {
  return HttpService.get("api/v1/Reports/Reports/Count");
}

export function getReportsCountByReaderId(readerID) {
  return HttpService.get(`api/v1/reports/reader/${readerID}`);
}

export function getReportsCountByMemberId(userID) {
  return HttpService.get(`api/v1/Reports/patient/${userID}`);
}

export function getReferralDoctorsCount() {
  return HttpService.get("api/v1/referraldoctors");
}

export function getGlobeActivity(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/v1/globeactivity/activity",
    QueryData,
    "dashboardApi.getGlobeActivity"
  );
}

export function getGlobeActivityCount() {
  return HttpService.get("api/v1/GlobeActivity/count");
}

export function getMessagesCount(userID) {
  return HttpService.get(`api/v1/Messages/count/${userID}`);
}
