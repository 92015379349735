import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  inject: [],
  data() {
    return {
      listFilters: this.loadFilterList(),
    };
  },
  methods: {
    ...mapActions("screenings", [
      "getScreeningsOnhold",
      "getScreeningsOnholdCount",
      "getDurationList",
      "getHoldSortList",
    ]),
    loadFilterList() {
      this.getDurationList();
      this.getHoldSortList();
    },
  },
  provide() {
    return {
      feature: Features.ScreeningsOnhold,
      screenings: this.screenings,
    };
  },
};
