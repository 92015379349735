import MasterService from "../../../services/common-services/MasterService";
import ManageUserService from "../../../services/module-services/user/ManageUserService";

const ManageUserHandler = {
  mixins: [ManageUserService, MasterService],
  created() {
    this.loadMasterList(["roleList"]);
    if (this.$route.params.slug) {
      this.userForm.postData.id = this.$route.params.slug;
      this.manageUser.userId = this.$route.params.slug;
      this.loadUser();
    }
  },
  methods: {
    async submit() {
      if (!(await this.userForm.isValid())) return;
      this.saveUser(this.navigateToBack);
    },
    navigateToBack() {
      this.$router.back();
    },
  },
  provide() {
    return {
      handleSave: this.submit,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ManageUserHandler;

export const withManageUserHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ManageUserHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManageUserHandler>`,
    components: { ManageUserHandler, WrappedComponent },
  };
};
