import * as types from "./organisations.action.types";

export default {
  [types.SEARCH_ORGANISATIONS]: (state, res) => {
    state.searchResults = [];
    state.searchResults = res.map((re) => {
      return { id: re.id, name: re.companyName, code: re.code };
    });
  },
};
