import state from "./screeningeval.state";
import actions from "./screeningeval.action";
import mutations from "./screeningeval.mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
