import * as HttpService from "../services/common-services/HttpService";

export function getProfile() {
  return HttpService.get("api/Profile", "IDP");
}

export function getClaims() {
  return HttpService.get("api/profile/claims", "IDP");
}

export function getDoctorByUserId(userId) {
  return HttpService.get("api/v1/Doctors/" + userId + "?isUserId=true");
}

export function updateApiProfile(profileData) {
  return HttpService.put(
    "api/v1/Profile/" + profileData.postformData.Id,
    profileData.postformData,
    "Default"
  );
}

export function updateProfile(profileData) {
  return HttpService.put(
    "api/Profile/" + profileData.postformData.Id,
    profileData.postformData,
    "IDP"
  );
}

export function notificationpreference(profileID, postData) {
  return HttpService.put(
    `api/v1/Profile/NotificationsPreference/${profileID}`,
    postData,
    "Default"
  );
}

export function getNotificationpreferences(UserID) {
  return HttpService.get(`api/v1/Profile/NotificationsPreference/${UserID}`);
}
