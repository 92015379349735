<template>
  <md-dialog :md-active.sync="snackbar.showWarning" class="alert-dialog">
    <div class="alert-message warning">
      <div class="alert-body">
        <h1>
          <i class="fas fa-exclamation-triangle"></i>
          {{ snackbar.title }}
        </h1>
        <p v-html="snackbar.description"></p>
      </div>
      <div v-if="snackbar.hasGoback">
        <md-button
          @click="snackbar.showWarning = false"
          class="md-danger-outline"
          >Go back</md-button
        >
        <md-button @click="onSubmit()" class="md-danger-outline">OK</md-button>
      </div>
      <div v-else>
        <md-button
          @click="snackbar.showWarning = false"
          class="md-danger-outline"
          >OK</md-button
        >
      </div>
    </div>
  </md-dialog>
</template>
<script>
import { onSubmit } from "../../services/control-services/SnackbarService";
export default {
  inject: ["snackbar"],
  methods: {
    onSubmit,
  },
};
</script>
