import { withManageAdminHandler } from "../../handlers/module-handlers/admin/ManageAdminHandler";

export default [
  {
    path: "Admin",
    component: () => import("./AdminRoutePage.vue"),
    meta: { isPublic: true },
    children: [
      {
        path: ":adminId/approvals",
        component: withManageAdminHandler(() =>
          import("./manage-Admin/ManagementAdminPage.vue")
        ),
        meta: { isApproval: true },
      },
      {
        path: "add",
        component: withManageAdminHandler(() =>
          import("./manage-Admin/ManagementAdminPage.vue")
        ),
        meta: { userType: "Admin" },
      },
      {
        path: "edit/:adminId",
        component: withManageAdminHandler(() =>
          import("./manage-Admin/ManagementAdminPage.vue")
        ),
      },
    ],
  },
];
