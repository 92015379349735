import ResetPasswordService from "../../../services/module-services/user/ResetPasswordService";
import NavigationService from "../../../services/common-services/NavigationService";
const ResetPasswordHandler = {
  mixins: [ResetPasswordService, NavigationService],
  methods: {
    ResetPassword() {
      this.UserResetPassword();
    },
    navigateToLoginPage() {
      this.navigateTo("login");
    },
  },
  template: `<div><slot></slot></div>`,
  provide() {
    return {
      passwordReset: this.passwordReset,
      validateMember: this.ResetPassword,
      cancelReset: this.navigateToLoginPage,
    };
  },
};

export default ResetPasswordHandler;

export const withResetPasswordHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ResetPasswordHandler><WrappedComponent v-bind="$props"></WrappedComponent></ResetPasswordHandler>`,
    components: { ResetPasswordHandler, WrappedComponent },
  };
};
