import * as HttpService from "../services/common-services/HttpService";

export function searchDevices(deviceSearchParams) {
  return HttpService.getByQuery(
    "api/v1/Device/search",
    deviceSearchParams,
    "Default"
  );
}

export function getDeviceById(deviceId) {
  return HttpService.get(`api/v1/Device/${deviceId}`);
}

export function RegisterDevice(data) {
  return HttpService.post(`api/v1/Device`, data);
}

export function UpdateDevice(deviceId, data) {
  return HttpService.put(`api/v1/Device/${deviceId}`, data);
}

export function DeleteDevice(deviceId) {
  return HttpService.del(`api/v1/Device/${deviceId}`);
}
