import MessageService from "../../../services/module-services/message/messageService";
import MasterService from "../../../services/common-services/MasterService";
import {
  getSubmit,
  getCancel,
} from "../../../services/control-services/ConfirmationService";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);
import { computed } from "@vue/composition-api";

const MessageHandler = {
  mixins: [MessageService, MasterService],
  inject: ["confirmation"],
  created() {
    this.loadMessageType();
    this.searchtheuser();
  },
  data() {
    return {
      searchUserResults: [],
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "messages/GET_TO_USERS") {
        if (state.messages) {
          this.searchUserResults = state["messages"].searchUserResults;
        }
      }
    });
  },

  methods: {
    searchtheuser() {
      this.searchUserwithParams(this.UserSearchForm);
    },

    getFilter() {
      this.loadMessageType();
    },
    getMsg() {
      this.getmessages();
    },
    getSentMsg() {
      this.getsentmessages();
    },
    getSelectedMsg(msgID, type) {
      this.getmessageByID(msgID, type);
    },
    getmessageThread(msgID, type) {
      this.getmessageThreadByID(msgID, type);
    },
    getAttachments(msgID) {
      this.getattachments(msgID);
    },
    Messagepost() {
      this.postmessages();
    },
    UpdateMsgStatus(msgID) {
      this.UpdateStatus(msgID);
    },

    DeleteMsg(msgID) {
      this.confirmation.title = "Delete Message?";
      this.confirmation.description =
        "Are you sure you want to delete this particular Message ?";
      this.confirmation.show = true;
      this.submitSubscription = getSubmit().subscribe(() => {
        this.confirmation.show = false;
        this.DeleteMessageByID(msgID);
      });
      this.cancelSubscription = getCancel().subscribe(() => {
        this.confirmation.show = false;
        this.cancelSubscription.unsubscribe();
      });
    },
  },
  beforeDestroy() {
    if (this.submitSubscription) this.submitSubscription.unsubscribe();
    if (this.cancelSubscription) this.cancelSubscription.unsubscribe();
  },
  provide() {
    return {
      getMessage: this.getMsg,
      getSentMessage: this.getSentMsg,
      getSelectedMessage: this.getSelectedMsg,
      getMessageThread: this.getmessageThread,
      getAttachments: this.getAttachments,
      Message1: this.Message1,
      AddAttachment: this.AddAttachment,
      SendMessage: this.Messagepost,
      GetFilter: this.getFilter,
      UpdateMessage: this.UpdateMsgStatus,
      searchUserResults: computed(() => this.searchUserResults),
      UserSearchForm: this.UserSearchForm,
      DeleteMessage: this.DeleteMsg,
    };
  },
  template: `<div><slot></slot></div>`,
};
export default MessageHandler;
export const withMessageHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<MessageHandler><WrappedComponent v-bind="$props"></WrappedComponent></MessageHandler>`,
    components: { MessageHandler, WrappedComponent },
  };
};
