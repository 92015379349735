import * as HttpService from "../services/common-services/HttpService";

export function getMessages() {
  return HttpService.get(`api/v1/Messages`);
}
export function getSentMessages() {
  return HttpService.get(`api/v1/Messages/Sent`);
}
export function getMessageByID(msgID, type) {
  return HttpService.get(`api/v1/Messages/${msgID}/${type}`);
}

export function getThread(msgID, type) {
  return HttpService.get(`api/v1/Messages/thread/${msgID}/${type}`);
}

export function getAttachments(msgID) {
  return HttpService.get(`api/v1/Messages/${msgID}/files`);
}

export function SendMessagePost(postData) {
  return HttpService.post("api/v1/Messages", postData);
}

export function SendAttachmentPost(featureID, postData) {
  return HttpService.putFile(`api/v1/Messages/${featureID}/files`, postData);
}

export function UpdateStatusPut(messageID) {
  return HttpService.put(`api/v1/Messages/status/${messageID}`);
}

export function DeleteMessage(messageID) {
  return HttpService.put(`api/v1/Messages/delete/${messageID}`);
}

export function getUsersForToSearch(QueryData) {
  return HttpService.getByQueryWithCancel(
    "api/Users/UserSearch",
    QueryData,
    "messageApi.getUsersForToSearch",
    "IDP"
  );
}
