<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex md-size-50">
      <img
        v-if="uploadedImage != '' && uploadedImage != undefined"
        class="profile-img"
        id="imagePreview"
        :src="uploadedImage"
        @error="handleImageError"
        @click="handleImageClick()"
      />

      <img v-else class="profile-img" id="imagePreview" :src="defaultImg" />
    </div>
    <div class="d-flex align-item-center pt-1">
      <div class="text-field file-upload m-0">
        <i class="fa fa-upload" style="color: grey"></i>
        <a class="label" style="color: black !important">Upload Photo </a>
        <input
          type="file"
          id="imageUpload"
          accept=".png,
      .jpg, .jpeg"
          @change="onChange"
        />
      </div>

      <div class="avatar-edit"></div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["handleImageError"],
  props: {
    change: Function,
    uploadedImage: String,
    defaultSrc: String,
    onClick: Function,
  },
  data() {
    return {
      defaultImg: require("@/assets/img/avatar_default.jpg"),
    };
  },
  methods: {
    onChange(event) {
      this.$emit("change", event.target.files);
    },
    handleImageClick() {
      this.$emit("onClick");
    },
  },
  created() {
    if (this.defaultSrc == "doctor") {
      this.defaultImg = require("@/assets/img/doctor-avatar3.png");
    }
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .img-container {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
</style>
