import AuthService from "../../services/common-services/AuthService";
import NavigationService from "../../services/common-services/NavigationService";

const AuthHandler = {
  mixins: [AuthService, NavigationService],
  methods: {
    async submit() {
      if (!(await this.auth.isFormValid())) return;
      this.processLogin();
    },
    navigateToHomePage() {
      this.navigateTo("home");
    },
    navigateToForgotPwdPage() {
      this.navigateTo("forgotpwd");
    },
  },
  provide() {
    return {
      handleLogin: this.submit,
      cancelLogin: this.navigateToHomePage,
      forgotPwd: this.navigateToForgotPwdPage,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default AuthHandler;

export const withAuthHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<AuthHandler><WrappedComponent v-bind="$props"></WrappedComponent></AuthHandler>`,
    components: { AuthHandler, WrappedComponent },
  };
};
