import { mapActions } from "vuex";
import Features from "../../../constants/Features";

export default {
  data() {
    return {
      MasterTypeList: { value: [] },
      lookupValues: { value: [] },
      type: { value: "Regions" },
      lookupMasterID: { value: 0 },
      value: "",
    };
  },
  watch: {
    "type.value": function () {
      this.getLookupList();
    },
  },
  methods: {
    ...mapActions("master", ["getLookupValuesByType", "GetAllMasterType"]),
    getLookupList() {
      let value = this.type.value.replace(/\s/g, "").toLowerCase();
      this.getLookupValuesByType(value).then((res) => {
        this.lookupValues.value = res;
        this.lookupMasterID.value = res[0].lookupMasterId;
      });
    },
    getMasterType() {
      this.GetAllMasterType().then((res) => {
        this.MasterTypeList.value = res;
      });
    },
    checkName(item) {
      let value = false;
      if (item.displayValue.toLowerCase() === this.value.toLowerCase()) {
        value = true;
      } else if (item.displayValue === this.value) {
        value = true;
      } else {
        value = false;
      }
      return value;
    },
    isDuplicated(item) {
      this.value = item;
      return this.lookupValues.value.find(this.checkName);
    },
  },
  created() {
    this.getMasterType();
    this.getLookupList();
  },

  provide() {
    return {
      feature: Features.MasterData,
      lookupValues: this.lookupValues,
      MasterTypeList: this.MasterTypeList,
      type: this.type,
      lookupMasterID: this.lookupMasterID,
      getLookupList: this.getLookupList,
      isDuplicated: this.isDuplicated,
    };
  },
};
