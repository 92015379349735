import onholdModuleService from "../../../services/module-services/screeningsOnhold/onholdModuleService";
import ListLayoutHandler from "../../common-handlers/ListLayoutHandler";
import ListHandler from "../../common-handlers/ListHandler";

const OnholModuledHandler = {
  mixins: [onholdModuleService],

  provide() {
    return {
      listFilters: this.getDurationList,
      getList: this.getScreeningsOnhold,
      getListCount: this.getScreeningsOnholdCount,
      HoldSortList: this.getHoldSortList,
    };
  },
  template: `<ListHandler><ListLayoutHandler><slot></slot></ListLayoutHandler></ListHandler>`,
  components: {
    ListHandler,
    ListLayoutHandler,
  },
};

export default OnholModuledHandler;

export const withOnholModuledHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<OnholModuledHandler><WrappedComponent v-bind="$props"></WrappedComponent></OnholModuledHandler>`,
    components: { OnholModuledHandler, WrappedComponent },
  };
};
