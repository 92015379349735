import * as api from "../../../api/ClientApi";
import * as types from "./Client.action.types";

export default {
  // eslint-disable-next-line no-unused-vars
  OrganisationPost({ commit }, postData) {
    return api.OrganisationPost(postData);
  },
  // eslint-disable-next-line no-unused-vars
  updateOrganisation({ commit }, userData) {
    return api.updateOrganisation(userData);
  },
  // eslint-disable-next-line no-unused-vars
  DeleteOrganisation({ commit }, id) {
    return api.DeleteOrganisation(id);
  },
  // eslint-disable-next-line no-unused-vars
  getOrganisation({ commit }, id) {
    return api.getOrganisation(id);
  },
  // eslint-disable-next-line no-unused-vars
  DeviceLocationPost({ commit }, { id, postData }) {
    return api.DeviceLocationPost(id, postData);
  },
  // eslint-disable-next-line no-unused-vars
  updateDeviceLocation({ commit }, { id, postData }) {
    return api.updateDeviceLocation(id, postData);
  },
  // eslint-disable-next-line no-unused-vars
  DeleteDeviceLocation({ commit }, id) {
    return api.DeleteDeviceLocation(id);
  },
  // eslint-disable-next-line no-unused-vars
  getDeviceLocation({ commit }, id) {
    return api.getDeviceLocation(id);
  },

  // eslint-disable-next-line no-unused-vars
  getClientList({ commit }, QueryData) {
    return api.getClientList(QueryData);
  },
  // eslint-disable-next-line no-unused-vars
  getOrganisationByDomain({ commit }) {
    return api.getOrganisationByDomain().then((res) => {
      commit(types.GET_CURRENT_ORG, res);
      return res;
    });
  },
  // eslint-disable-next-line no-unused-vars
  getDeviceList({ commit }) {
    return api.getDeviceList().then((res) => {
      commit(types.GET_DEVICE_LIST, res);
      return res;
    });
  },

  // eslint-disable-next-line no-unused-vars
  getAllClientsList({ commit }) {
    return api.getAllClients();
  },
  // eslint-disable-next-line no-unused-vars
  getRegionClients({ commit }) {
    return api.getRegionClients().then((res) => {
      return res;
    });
  },
};
