<template>
  <md-dialog :md-active.sync="snackbar.showError" class="alert-dialog">
    <div class="alert-message danger">
      <div class="alert-body">
        <h1>
          <i class="fa fa-times-circle"></i>
          {{ snackbar.title }}
        </h1>
        <p>{{ snackbar.description }}</p>
      </div>
      <div>
        <md-button @click="snackbar.showError = false">OK</md-button>
      </div>
    </div>
  </md-dialog>
</template>
<script>
//import Button from "./button/DefaultButton.vue";
//import { onerror } from "../../services/control-services/SnackbarErrorService";
export default {
  inject: ["snackbar"],
  //components: { Button },
  // methods: {
  //   onerror,
  // },
};
</script>
