import { mapActions, mapState } from "vuex";
import { AddressDetails } from "../../../models/Address";
import { DoctorRequest } from "../../../models/Doctor";
import { getLatLngByZipcode } from "../../common-services/SharedFunctionService";

export default {
  inject: [
    "showSuccessMsg",
    "showErrorMsg",
    "showError",
    "refreshList",
    "Permissions",
    "checkPermission",
    "navigationBlocker",
  ],
  data() {
    return {
      doctorForm: { postData: new DoctorRequest() },
      progress: {
        isApproval: false,
        isDetailsLoading: false,
        isSaving: false,
        isApprovalProcessing: false,
      },
      manageDoctor: {
        page: "",
        role: {},
        isRegionClientMappingLoaded: false,
        regionClientMapping: [],
        regionClients: [],
        userType: "",
        showConfidentialConfirmation: false,
      },
    };
  },
  computed: {
    ...mapState({
      currentOrg: (state) => state.Client.currentOrg,
    }),
  },
  created() {
    this.navigationBlocker.val = true;
  },
  methods: {
    ...mapActions("doctor", [
      "updateDoctor",
      "getDoctor",
      "updateDoctorApproval",
      "RegisterDoctor",
      "createDoctor",
      "deleteDoctor",
      "doctordeactive",
    ]),
    ...mapActions("user", [
      "addUser",
      "updateUserAccountCreated",
      "updateUserActive",
      "updateUser",
    ]),
    ...mapActions("Client", ["getRegionClients"]),
    isAdminEntryValid() {
      let isValid = false;
      for (let region of this.manageDoctor.regionClients) {
        if (region.isChecked) isValid = true;
        if (region.selectedClients.length > 0) isValid = true;
        if (isValid) break;
      }
      return isValid;
    },
    async saveDoctor() {
      this.navigationBlocker.val = true;
      if (
        this.checkPermission(this.Permissions.SuperAdmin) &&
        this.doctorForm.postData.id &&
        !this.isAdminEntryValid() &&
        this.doctorForm.postData.isApproved != false
      ) {
        this.showErrorMsg("Please select atleast one");
        return;
      }
      this.progress.isSaving = true;
      this.doctorForm.postData.organisations = [];
      this.doctorForm.postData.regions = [];
      if (
        this.manageDoctor.page == "Register" ||
        !this.checkPermission(this.Permissions.SuperAdmin)
      )
        this.doctorForm.postData.organisations = [
          sessionStorage.getItem("Org_Id") != null
            ? sessionStorage.getItem("Org_Id")
            : this.currentOrg.clientID,
        ];
      else {
        for (let x of this.manageDoctor.regionClients) {
          if (x.isChecked) this.doctorForm.postData.regions.push(x.id);
          for (let y of x.selectedClients)
            if (!this.doctorForm.postData.organisations.includes(y))
              this.doctorForm.postData.organisations.push(y);
        }
      }
      let postData = JSON.parse(JSON.stringify(this.doctorForm.postData));
      var dateParts =
        postData.certificationDate != null
          ? postData.certificationDate.split(".")
          : null;
      if (dateParts && dateParts.length == 3)
        postData.certificationDate =
          dateParts[2] + "-" + dateParts[1] + "-" + +dateParts[0];
      postData.person.languages = String(postData.person.languages);
      postData.insurance = String(postData.insurance);
      postData.userType = this.manageDoctor.role.name;
      if (postData.mailingAddress.zipCode) {
        let latlang = await getLatLngByZipcode(postData.mailingAddress.zipCode);
        if (latlang && latlang.length == 2) {
          postData.mailingAddress.latitude = latlang[0];
          postData.mailingAddress.longitute = latlang[1];
        }
      }
      if (postData.businessAddress.zipCode) {
        let latlang = await getLatLngByZipcode(
          postData.businessAddress.zipCode
        );
        if (latlang && latlang.length == 2) {
          postData.businessAddress.latitude = latlang[0];
          postData.businessAddress.longitute = latlang[1];
        }
      }
      if (postData.id) {
        if (this.manageDoctor.page == "Approval")
          this.updateDoctorApproval(postData)
            .then(() => {
              if (
                this.doctorForm.postData.isApproved &&
                (this.doctorForm.postData.isReader ||
                  this.manageDoctor.role.name == "Reader")
              )
                this.createUser("Approved successfully");
              else {
                this.refreshList();
                this.showSuccessMsg(
                  (this.doctorForm.postData.isApproved
                    ? "Approved"
                    : "Rejected") + " successfully"
                );
                this.$router.back();
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
        else
          this.updateDoctor(postData)
            .then(() => {
              if (
                this.manageDoctor.role.name == "Doctor" &&
                this.doctorForm.postData.isReader &&
                !this.doctorForm.postData.isAccountCreated
              )
                this.createUser("Updated successfully");
              else if (this.doctorForm.postData.isAccountCreated)
                this.updateUserDetails()
                  .then(() => {
                    if (
                      this.manageDoctor.role.name == "Doctor" &&
                      !this.doctorForm.postData.isReader
                    )
                      this.updateUserActive({
                        id: this.doctorForm.postData.userID,
                        isActive: false,
                      })
                        .then(() => {
                          this.refreshList();
                          this.showSuccessMsg("Updated successfully");
                          this.$router.back();
                        })
                        .catch((err) => {
                          this.showErrorMsg(err);
                        })
                        .finally(() => (this.progress.isSaving = false));
                    else if (
                      this.manageDoctor.role.name == "Doctor" &&
                      this.doctorForm.postData.isReader
                    )
                      this.updateUserActive({
                        id: this.doctorForm.postData.userID,
                        isActive: true,
                      })
                        .then(() => {
                          this.refreshList();
                          this.showSuccessMsg("Updated successfully");
                          this.$router.back();
                        })
                        .catch((err) => {
                          this.showErrorMsg(err);
                        })
                        .finally(() => (this.progress.isSaving = false));
                    else {
                      this.progress.isSaving = false;
                      this.refreshList();
                      this.showSuccessMsg("Updated successfully");
                      this.$router.back();
                    }
                  })
                  .catch((err) => {
                    this.showErrorMsg(err);
                    this.progress.isSaving = false;
                  });
              else {
                this.progress.isSaving = false;
                this.refreshList();
                this.showSuccessMsg("Updated successfully");
                this.$router.back();
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
      } else {
        if (this.manageDoctor.page == "Register")
          this.RegisterDoctor(postData)
            .then((res) => {
              if (res.userID) {
                this.doctorForm.fileUploadRef.handleSendNewFiles(res.userID);
                this.$router.push(
                  "/register/success/" + this.manageDoctor.role.name
                );
              } else this.showErrorMsg("Something went wrong");
            })
            .catch((err) => {
              let error = new Error();
              error.title = "Error";
              error.text = err;
              error.confirmButtonClass = "md-button md-info";
              this.showError(error);
            })
            .finally(() => (this.progress.isSaving = false));
        else {
          this.createDoctor(postData)
            .then((res) => {
              if (res.userID) {
                this.doctorForm.fileUploadRef.handleSendNewFiles(res.userID);
                this.doctorForm.postData.userID = res.userID;
                if (
                  this.doctorForm.postData.isReader ||
                  this.manageDoctor.role.name == "Reader"
                )
                  this.createUser("Created Successfully");
                else {
                  this.refreshList();
                  this.showSuccessMsg("Created successfully");
                  this.$router.back();
                }
              } else {
                this.showErrorMsg("Something went wrong");
                this.progress.isSaving = false;
              }
            })
            .catch((err) => {
              this.showErrorMsg(err);
              this.progress.isSaving = false;
            });
        }
      }
    },
    createUser(successMsg) {
      let userPostData = {
        id: this.doctorForm.postData.userID,
        firstName: this.doctorForm.postData.person.firstName,
        lastName: this.doctorForm.postData.person.lastName,
        email: this.doctorForm.postData.person.email,
        phone: this.doctorForm.postData.person.cellNumber,
        role: this.manageDoctor.role.id,
        organisations: this.doctorForm.postData.organisations,
      };
      this.addUser(userPostData)
        .then(() => {
          this.updateUserAccountCreated(userPostData.id)
            .then(() => {
              this.refreshList();
              this.showSuccessMsg(successMsg);
              this.$router.back();
            })
            .catch((err) => {
              this.showErrorMsg(err);
            })
            .finally(() => (this.progress.isSaving = false));
        })
        .catch((err) => {
          this.showErrorMsg(err);
          this.progress.isSaving = false;
        });
    },
    updateUserDetails() {
      let userPostData = {
        id: this.doctorForm.postData.userID,
        firstName: this.doctorForm.postData.person.firstName,
        lastName: this.doctorForm.postData.person.lastName,
        email: this.doctorForm.postData.person.email,
        phone: this.doctorForm.postData.cellNumber,
        role: this.manageDoctor.role.id,
        organisations: this.doctorForm.postData.organisations,
      };
      return this.updateUser(userPostData);
    },
    loadDoctor() {
      this.progress.isDetailsLoading = true;
      this.getDoctor(this.doctorForm.postData.id)
        .then((res) => {
          if (!res.businessAddress) res.businessAddress = new AddressDetails();
          if (!res.mailingAddress) res.mailingAddress = new AddressDetails();
          if (res.certificationDate) {
            let date = new Date(res.certificationDate);
            let dateArr = [
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate(),
            ];
            this.doctorForm.canSetDOB = true;
            this.doctorForm.dobObj = {
              day: dateArr[2],
              month: dateArr[1] - 1,
              year: dateArr[0],
            };
            res.certificationDate =
              dateArr[2] + "." + dateArr[1] + "." + dateArr[0];
          }
          this.doctorForm.postData = JSON.parse(JSON.stringify(res));
          this.manageDoctor.isGetDoctorLoaded = true;
          this.loadSelectedRegionClients();
        })
        .finally(() => (this.progress.isDetailsLoading = false));
    },
    loadSelectedRegionClients() {
      if (
        this.manageDoctor.isGetDoctorLoaded &&
        this.manageDoctor.isRegionClientsLoaded
      ) {
        let regionClients = JSON.parse(
          JSON.stringify(this.manageDoctor.regionClients)
        );
        for (let region of regionClients) {
          if (this.doctorForm.postData.regions.includes(region.id))
            region.isChecked = true;
          for (let client of region.clients)
            if (this.doctorForm.postData.organisations.includes(client.id))
              region.selectedClients.push(client.id);
        }
        this.manageDoctor.regionClients = regionClients;
      }
    },
  },
  provide() {
    return {
      doctorForm: this.doctorForm,
      manageDoctor: this.manageDoctor,
      progress: this.progress,
      boardCerifications: [
        { value: "yes", displayValue: "Yes" },
        { value: "no", displayValue: "No" },
        { value: "pending", displayValue: "Pending" },
      ],
    };
  },
};
